<template>
  <FlameNavBar/>
  <div class="balance_body public_bgc section">
    <div class="balance_main public_card_color public_black">
      <div class="balance_head doNotSelect">
        <div class="balance_head_num f-l">余额：{{ this.balanceShow }}&nbsp;&nbsp;<span class="f-15 public_Flame_themeColor">CNY</span></div>
        <div class="input_tips public_tips_backgroundColor" @click="BalanceShoworHide">
          <svg t="1673700471827" class="input_tips_icon public_tips_iconFill" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
               p-id="4734" width="21" height="21" v-if="balanceShowOrHideFlag">
            <path
                d="M511.466 269.569c169.452 0 315.308 99.39 383.565 242.84h66.514c-72.09-177.91-246.293-303.527-450.08-303.527-203.754 0-377.957 125.617-450.046 303.526h66.515c68.255-143.449 214.111-242.84 383.532-242.84z"
                p-id="4735"></path>
            <path
                d="M511.466 755.248c-169.42 0-315.277-99.39-383.532-242.84H61.419C133.507 690.35 307.71 815.966 511.466 815.966c203.786 0 377.99-125.617 450.079-303.558H895.03c-68.257 143.45-214.112 242.84-383.565 242.84z"
                p-id="4736"></path>
            <path
                d="M349.594 512.424c0 89.412 72.474 161.886 161.885 161.886 89.414 0 161.886-72.474 161.886-161.886 0-89.414-72.472-161.886-161.886-161.886-89.412 0-161.885 72.472-161.885 161.886z m263.084 0c0 55.88-45.32 101.197-101.199 101.197s-101.166-45.318-101.166-101.197c0-55.88 45.287-101.2 101.166-101.2s101.199 45.32 101.199 101.2z"
                p-id="4737"></path>
          </svg>
          <svg t="1672201563313" class="input_tips_icon public_tips_iconFill" viewBox="0 0 1024 1024" version="1.1"
               xmlns="http://www.w3.org/2000/svg"
               p-id="3728" width="21" height="21" v-if="!balanceShowOrHideFlag">
            <path
                d="M983.04 512c0 153.31328-228.10624 307.2-471.04 307.2-90.3168 0-178.56512-21.25824-253.80864-55.86944l129.49504-129.47456a174.08 174.08 0 1 0 246.1696-246.1696l128.57344-128.57344C891.392 317.44 983.04 414.8224 983.04 512zM512 204.8c65.00352 0 128.96256 11.01824 187.76064 30.1056l-117.69856 117.69856a174.08 174.08 0 0 0-229.4784 229.4784l-151.18336 151.1424C105.02144 674.67264 40.96 593.26464 40.96 512c0-153.31328 228.10624-307.2 471.04-307.2z m112.64 307.2a112.64 112.64 0 0 1-193.49504 78.41792l159.27296-159.27296c21.0944 20.48 34.22208 49.13152 34.22208 80.85504zM512 399.36c7.2704 0 14.37696 0.69632 21.27872 2.00704l-131.91168 131.8912A112.64 112.64 0 0 1 512 399.36z"
                p-id="3729"></path>
            <path
                d="M872.796352 148.719008m21.72232 21.72232l0 0q21.72232 21.72232 0 43.444641l-680.632703 680.632703q-21.72232 21.72232-43.444641 0l0 0q-21.72232-21.72232 0-43.444641l680.632703-680.632703q21.72232-21.72232 43.444641 0Z"
                p-id="3730"></path>
          </svg>
        </div>
      </div>

      <div class="balance_body_body">
        <div v-for="item in this.rechargeList" :key="item.id" @click="recharge(item.price)" class="balance_each_card store_art_card_color pointer">
          <div class="recharge_num f-b">{{item.price}}<span class="public_Flame_themeColor f-10 f-l">CNY</span></div>
        </div>

      </div>


    </div>

    <successRedirect :sucRedirectURL="'refresh'" ref="successRedirect"/><FlameFooter/>
  </div>
</template>

<script>
import router from "@/router";
import * as $ from "jquery";
import FlameNavBar from '@/components/Flame-NavBar'
import successRedirect from "@/components/successredirect";
import request from "@/utils/request";
import FlameFooter from "@/components/Flame-Footer";

const numRenderReg = /(\d)(?=(?:\d{3})+$)/g;
export default {
  name: "balance",
  components: {
    FlameFooter,
    FlameNavBar,
    successRedirect,
  },
  data() {
    return {
      balanceShowOrHideFlag: false,
      balanceShow: '******',
      userBalance: 0,

      rechargeList:[{
        id:1,
        price:98
      },{
        id:2,
        price:648
      },{
        id:3,
        price:1599
      },{
        id:4,
        price:5998
      },{
        id:5,
        price:9899
      },{
        id:6,
        price:19998
      },{
        id:7,
        price:59899
      },{
        id:8,
        price:99899
      }]
    }
  },
  mounted() {
    if (localStorage.getItem('flame_user_uuid') === null || localStorage.getItem('flame_user_uuid') === "") {
      window.open('/#/user/login?redirectUrl=' + encodeURIComponent(window.location.origin) + encodeURIComponent(window.location.pathname), '_self');
    } else {
      this.getUserBalance()
    }
  },
  methods: {
    getUserBalance() {

      request.get('user/balance',
          {
            params: {
              uuid: localStorage.getItem('flame_user_uuid')
            }
          }
      ).then(res => {
        this.userBalance = res
      })
    },
    recharge(price){
      request.get('user/recharge',
          {
            params: {
              useruuid: localStorage.getItem('flame_user_uuid'),
              price:price
            }
          }
      ).then(res => {
        this.$refs.successRedirect.successRedirectTable('充值成功')
      })
    },
    BalanceShoworHide() {
      if (this.balanceShowOrHideFlag) {
        this.balanceShow = "******"
      } else {
        // console.log(this.userBalance)
        this.balanceShow = this.userBalance.toString().replace(numRenderReg, '$1, ');
      }
      // console.log(this.balanceShow)
      this.balanceShowOrHideFlag = !this.balanceShowOrHideFlag
    }
  }
}
</script>

<style src="@/style/FlameTheme.css" scoped/>
<style src="@/style/FlameDarkTheme.css" scoped/>
<style src="@/style/PublicStyle.css" scoped/>
<style scoped>
@media screen and (orientation: landscape) {
  .balance_body {
    position: relative;
    overflow-x: hidden;
    width: 100%;
    height: 100%;
  }

  .balance_main {
    overflow: hidden;
    position: relative;
    border-radius: 10px;
    margin: 92px auto;
    width: 1170px;
    height: 650px;
  }

  .balance_head {
    position: relative;
    width: 100%;
    height: 100px;
    margin-top: 50px;
    margin-left: 50px;
  }

  .balance_head_num {
    position: relative;
    float: left;
    height: 100%;
    width: auto;
    font-size: 30px;
    margin-left: 70px;
  }

  .input_tips {
    position: absolute;
    border-radius: 5px;
    float: left;
    width: 30px;
    height: 30px;
    left: 450px;
    margin-top: 5px;
  }

  .input_tips_icon {
    position: relative;
    left: 5px;
    top: 5px;
  }

  .balance_body_body{
    position: relative;
    width: 90%;
    height: 450px;
    margin: 0 auto;
  }
  .balance_each_card{
    display: flex;
    align-items: center;
    position: relative;
    float: left;
    border-radius: 10px;
    width: 180px;
    height: 180px;
    margin-left: 65px;
    margin-top: 30px;
  }
  .recharge_num{
    position: relative;
    font-size: 50px;
    margin: 0 auto;
  }
}
@media screen and (orientation: portrait) {
  .balance_body {
    position: relative;
    overflow-x: hidden;
    width: 100%;
    height: 100%;
  }

  .balance_main {
    overflow: hidden;
    position: relative;
    border-radius: 10px;
    margin: 92px auto;
    width: 90%;
    height: auto;
  }

  .balance_head {
    position: relative;
    width: 100%;
    height: 100px;
    margin-top: 50px;
    margin-left: 10%;
  }

  .balance_head_num {
    position: relative;
    float: left;
    height: 100%;
    width: auto;
    font-size: 30px;
    margin-left: 5%;
  }

  .input_tips {
    position: absolute;
    border-radius: 5px;
    float: left;
    width: 30px;
    height: 30px;
    left: 5%;
    margin-top: 50px;
  }

  .input_tips_icon {
    position: relative;
    left: 5px;
    top: 5px;
  }

  .balance_body_body{
    position: relative;
    width: 90%;
    height: 450px;
    margin: 0 auto;
  }
  .balance_each_card{
    display: flex;
    align-items: center;
    position: relative;
    float: left;
    border-radius: 10px;
    width: 40%;
    height: 70px;
    margin-left: 7%;
    margin-top: 30px;
  }
  .recharge_num{
    position: relative;
    font-size: 20px;
    margin: 0 auto;
  }
}















</style>