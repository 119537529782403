import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

const app = createApp(App);
app.use(router).use(ElementPlus).mount('#app')

app.config.globalProperties.$globalVar = 'https://elysova.com:9091';
// app.config.globalProperties.$globalVar = 'http://localhost:9090';
