<template>
  <FlameNavBar/>
  <div id="commodity_father" class="public_body public_bgc">
    <div class="commodity_body">
      <div class="cb_main">
        <div class="cbm_img_border commodity_MAXImg_bgcolor">
          <div class="cbmib_main_border commodity_MAXImg_bgcolor">
            <div class="cbmib_main_img_border commodity_MAXImgBg_bgcolor">
              <div class="cbmib_main_img" @mouseover="glassAnim(true)" @mouseleave="glassAnim(false)">
                <div class="cbmibmi_glass"></div>
                <img :src="this.staticFileURL+'artworkImage/'+this.maxShowImgUrl" alt="">
              </div>
            </div>
          </div>

          <div class="cbmib_min_border">
            <div class="cbmib_min_img" v-for="item in this.artworkMsg.imgurl.split('%2C')">
              <img @click="changeMaxImage(item)" class="pointer" :src="this.staticFileURL+'artworkImage/'+item" alt="">
            </div>
          </div>
        </div>

        <div class="cbm_detailMsg_body commodity_MAXImg_bgcolor public_black">
          <div class="cbmdb_head public_card_color">
            <div class="cbmdbh_title">
              <div class="cbmdbh_title_colorBorder"/>
              <span class="p-r l-5">{{ this.artworkMsg.namecn }}</span>
            </div>
            <div class="cbmdbh_line"/>
            <div class="cbmdbh_price">{{ this.artworkMsg.price }}&nbsp;
              <span class="f-20 f-l public_Flame_themeColor doNotSelect">CNY</span>
            </div>
            <div class="cbmdbh_artwork_include doNotSelect">

              <div v-show="this.artworkMsg.virtualentity">
                <div class="cbmdbhai_each">
                  <svg v-show="this.artworkMsg.isoriginal" t="1672989023466" class="icon" viewBox="0 0 1024 1024"
                       version="1.1"
                       xmlns="http://www.w3.org/2000/svg" p-id="1592" width="20" height="20">
                    <path
                        d="M512 85.333333C277.333333 85.333333 85.333333 277.333333 85.333333 512s192 426.666667 426.666667 426.666667 426.666667-192 426.666667-426.666667S746.666667 85.333333 512 85.333333z m198.4 352l-211.2 211.2c-8.533333 8.533333-19.2 12.8-29.866667 12.8s-21.333333-4.266667-29.866666-12.8l-106.666667-106.666666c-17.066667-17.066667-17.066667-42.666667 0-59.733334s42.666667-17.066667 59.733333 0l76.8 76.8 181.333334-181.333333c17.066667-17.066667 42.666667-17.066667 59.733333 0s17.066667 42.666667 0 59.733333z"
                        fill="#52961C" p-id="1593"></path>
                  </svg>
                  <svg v-show="!this.artworkMsg.isoriginal" t="1672989134416" class="icon" viewBox="0 0 1024 1024"
                       version="1.1"
                       xmlns="http://www.w3.org/2000/svg" p-id="1750" width="20" height="20">
                    <path
                        d="M512 85.333333C277.333333 85.333333 85.333333 277.333333 85.333333 512s192 426.666667 426.666667 426.666667 426.666667-192 426.666667-426.666667S746.666667 85.333333 512 85.333333z m151.466667 516.266667c17.066667 17.066667 17.066667 42.666667 0 59.733333-8.533333 8.533333-19.2 12.8-29.866667 12.8s-21.333333-4.266667-29.866667-12.8L512 571.733333 422.4 661.333333c-8.533333 8.533333-19.2 12.8-29.866667 12.8s-21.333333-4.266667-29.866666-12.8c-17.066667-17.066667-17.066667-42.666667 0-59.733333l89.6-89.6-89.6-89.6c-17.066667-17.066667-17.066667-42.666667 0-59.733333s42.666667-17.066667 59.733333 0l89.6 89.6 89.6-89.6c17.066667-17.066667 42.666667-17.066667 59.733333 0s17.066667 42.666667 0 59.733333L571.733333 512l91.733334 89.6z"
                        fill="#c40000" p-id="1751"></path>
                  </svg>
                  <div>&nbsp;原创</div>
                </div>
                <div class="cbmdbhai_each">
                  <svg t="1672989023466" class="icon" viewBox="0 0 1024 1024" version="1.1"
                       xmlns="http://www.w3.org/2000/svg" p-id="1592" width="20" height="20">
                    <path
                        d="M512 85.333333C277.333333 85.333333 85.333333 277.333333 85.333333 512s192 426.666667 426.666667 426.666667 426.666667-192 426.666667-426.666667S746.666667 85.333333 512 85.333333z m198.4 352l-211.2 211.2c-8.533333 8.533333-19.2 12.8-29.866667 12.8s-21.333333-4.266667-29.866666-12.8l-106.666667-106.666666c-17.066667-17.066667-17.066667-42.666667 0-59.733334s42.666667-17.066667 59.733333 0l76.8 76.8 181.333334-181.333333c17.066667-17.066667 42.666667-17.066667 59.733333 0s17.066667 42.666667 0 59.733333z"
                        fill="#52961C" p-id="1593"></path>
                  </svg>
                  <div>&nbsp;虚拟艺术品</div>
                </div>
                <div class="cbmdbhai_each">
                  <svg v-show="this.artworkMsg.isthreed" t="1672989023466" class="icon" viewBox="0 0 1024 1024"
                       version="1.1"
                       xmlns="http://www.w3.org/2000/svg" p-id="1592" width="20" height="20">
                    <path
                        d="M512 85.333333C277.333333 85.333333 85.333333 277.333333 85.333333 512s192 426.666667 426.666667 426.666667 426.666667-192 426.666667-426.666667S746.666667 85.333333 512 85.333333z m198.4 352l-211.2 211.2c-8.533333 8.533333-19.2 12.8-29.866667 12.8s-21.333333-4.266667-29.866666-12.8l-106.666667-106.666666c-17.066667-17.066667-17.066667-42.666667 0-59.733334s42.666667-17.066667 59.733333 0l76.8 76.8 181.333334-181.333333c17.066667-17.066667 42.666667-17.066667 59.733333 0s17.066667 42.666667 0 59.733333z"
                        fill="#52961C" p-id="1593"></path>
                  </svg>
                  <svg v-show="!this.artworkMsg.isthreed" t="1672989134416" class="icon" viewBox="0 0 1024 1024"
                       version="1.1"
                       xmlns="http://www.w3.org/2000/svg" p-id="1750" width="20" height="20">
                    <path
                        d="M512 85.333333C277.333333 85.333333 85.333333 277.333333 85.333333 512s192 426.666667 426.666667 426.666667 426.666667-192 426.666667-426.666667S746.666667 85.333333 512 85.333333z m151.466667 516.266667c17.066667 17.066667 17.066667 42.666667 0 59.733333-8.533333 8.533333-19.2 12.8-29.866667 12.8s-21.333333-4.266667-29.866667-12.8L512 571.733333 422.4 661.333333c-8.533333 8.533333-19.2 12.8-29.866667 12.8s-21.333333-4.266667-29.866666-12.8c-17.066667-17.066667-17.066667-42.666667 0-59.733333l89.6-89.6-89.6-89.6c-17.066667-17.066667-17.066667-42.666667 0-59.733333s42.666667-17.066667 59.733333 0l89.6 89.6 89.6-89.6c17.066667-17.066667 42.666667-17.066667 59.733333 0s17.066667 42.666667 0 59.733333L571.733333 512l91.733334 89.6z"
                        fill="#c40000" p-id="1751"></path>
                  </svg>
                  <div>&nbsp;3D预览</div>
                </div>
                <div class="cbmdbhai_each">
                  <svg v-show="this.artworkMsg.isunique" t="1672989023466" class="icon" viewBox="0 0 1024 1024"
                       version="1.1"
                       xmlns="http://www.w3.org/2000/svg" p-id="1592" width="20" height="20">
                    <path
                        d="M512 85.333333C277.333333 85.333333 85.333333 277.333333 85.333333 512s192 426.666667 426.666667 426.666667 426.666667-192 426.666667-426.666667S746.666667 85.333333 512 85.333333z m198.4 352l-211.2 211.2c-8.533333 8.533333-19.2 12.8-29.866667 12.8s-21.333333-4.266667-29.866666-12.8l-106.666667-106.666666c-17.066667-17.066667-17.066667-42.666667 0-59.733334s42.666667-17.066667 59.733333 0l76.8 76.8 181.333334-181.333333c17.066667-17.066667 42.666667-17.066667 59.733333 0s17.066667 42.666667 0 59.733333z"
                        fill="#52961C" p-id="1593"></path>
                  </svg>
                  <svg v-show="!this.artworkMsg.isunique" t="1672989134416" class="icon" viewBox="0 0 1024 1024"
                       version="1.1"
                       xmlns="http://www.w3.org/2000/svg" p-id="1750" width="20" height="20">
                    <path
                        d="M512 85.333333C277.333333 85.333333 85.333333 277.333333 85.333333 512s192 426.666667 426.666667 426.666667 426.666667-192 426.666667-426.666667S746.666667 85.333333 512 85.333333z m151.466667 516.266667c17.066667 17.066667 17.066667 42.666667 0 59.733333-8.533333 8.533333-19.2 12.8-29.866667 12.8s-21.333333-4.266667-29.866667-12.8L512 571.733333 422.4 661.333333c-8.533333 8.533333-19.2 12.8-29.866667 12.8s-21.333333-4.266667-29.866666-12.8c-17.066667-17.066667-17.066667-42.666667 0-59.733333l89.6-89.6-89.6-89.6c-17.066667-17.066667-17.066667-42.666667 0-59.733333s42.666667-17.066667 59.733333 0l89.6 89.6 89.6-89.6c17.066667-17.066667 42.666667-17.066667 59.733333 0s17.066667 42.666667 0 59.733333L571.733333 512l91.733334 89.6z"
                        fill="#c40000" p-id="1751"></path>
                  </svg>
                  <div>&nbsp;独版</div>
                </div>
              </div>


              <div v-show="!this.artworkMsg.virtualentity">
                <div class="cbmdbhai_each">
                  <svg t="1672989023466" class="icon" viewBox="0 0 1024 1024" version="1.1"
                       xmlns="http://www.w3.org/2000/svg" p-id="1592" width="20" height="20">
                    <path
                        d="M512 85.333333C277.333333 85.333333 85.333333 277.333333 85.333333 512s192 426.666667 426.666667 426.666667 426.666667-192 426.666667-426.666667S746.666667 85.333333 512 85.333333z m198.4 352l-211.2 211.2c-8.533333 8.533333-19.2 12.8-29.866667 12.8s-21.333333-4.266667-29.866666-12.8l-106.666667-106.666666c-17.066667-17.066667-17.066667-42.666667 0-59.733334s42.666667-17.066667 59.733333 0l76.8 76.8 181.333334-181.333333c17.066667-17.066667 42.666667-17.066667 59.733333 0s17.066667 42.666667 0 59.733333z"
                        fill="#52961C" p-id="1593"></path>
                  </svg>
                  <div>&nbsp;实体艺术品</div>
                </div>
                <div class="cbmdbhai_each">
                  <svg v-show="this.artworkMsg.isoriginal" t="1672989023466" class="icon" viewBox="0 0 1024 1024"
                       version="1.1"
                       xmlns="http://www.w3.org/2000/svg" p-id="1592" width="20" height="20">
                    <path
                        d="M512 85.333333C277.333333 85.333333 85.333333 277.333333 85.333333 512s192 426.666667 426.666667 426.666667 426.666667-192 426.666667-426.666667S746.666667 85.333333 512 85.333333z m198.4 352l-211.2 211.2c-8.533333 8.533333-19.2 12.8-29.866667 12.8s-21.333333-4.266667-29.866666-12.8l-106.666667-106.666666c-17.066667-17.066667-17.066667-42.666667 0-59.733334s42.666667-17.066667 59.733333 0l76.8 76.8 181.333334-181.333333c17.066667-17.066667 42.666667-17.066667 59.733333 0s17.066667 42.666667 0 59.733333z"
                        fill="#52961C" p-id="1593"></path>
                  </svg>
                  <svg v-show="!this.artworkMsg.isoriginal" t="1672989134416" class="icon" viewBox="0 0 1024 1024"
                       version="1.1"
                       xmlns="http://www.w3.org/2000/svg" p-id="1750" width="20" height="20">
                    <path
                        d="M512 85.333333C277.333333 85.333333 85.333333 277.333333 85.333333 512s192 426.666667 426.666667 426.666667 426.666667-192 426.666667-426.666667S746.666667 85.333333 512 85.333333z m151.466667 516.266667c17.066667 17.066667 17.066667 42.666667 0 59.733333-8.533333 8.533333-19.2 12.8-29.866667 12.8s-21.333333-4.266667-29.866667-12.8L512 571.733333 422.4 661.333333c-8.533333 8.533333-19.2 12.8-29.866667 12.8s-21.333333-4.266667-29.866666-12.8c-17.066667-17.066667-17.066667-42.666667 0-59.733333l89.6-89.6-89.6-89.6c-17.066667-17.066667-17.066667-42.666667 0-59.733333s42.666667-17.066667 59.733333 0l89.6 89.6 89.6-89.6c17.066667-17.066667 42.666667-17.066667 59.733333 0s17.066667 42.666667 0 59.733333L571.733333 512l91.733334 89.6z"
                        fill="#c40000" p-id="1751"></path>
                  </svg>
                  <div>&nbsp;原创</div>
                </div>
                <div class="cbmdbhai_each">
                  <svg v-show="this.artworkMsg.ismount" t="1672989023466" class="icon" viewBox="0 0 1024 1024"
                       version="1.1"
                       xmlns="http://www.w3.org/2000/svg" p-id="1592" width="20" height="20">
                    <path
                        d="M512 85.333333C277.333333 85.333333 85.333333 277.333333 85.333333 512s192 426.666667 426.666667 426.666667 426.666667-192 426.666667-426.666667S746.666667 85.333333 512 85.333333z m198.4 352l-211.2 211.2c-8.533333 8.533333-19.2 12.8-29.866667 12.8s-21.333333-4.266667-29.866666-12.8l-106.666667-106.666666c-17.066667-17.066667-17.066667-42.666667 0-59.733334s42.666667-17.066667 59.733333 0l76.8 76.8 181.333334-181.333333c17.066667-17.066667 42.666667-17.066667 59.733333 0s17.066667 42.666667 0 59.733333z"
                        fill="#52961C" p-id="1593"></path>
                  </svg>
                  <svg v-show="!this.artworkMsg.ismount" t="1672989134416" class="icon" viewBox="0 0 1024 1024"
                       version="1.1"
                       xmlns="http://www.w3.org/2000/svg" p-id="1750" width="20" height="20">
                    <path
                        d="M512 85.333333C277.333333 85.333333 85.333333 277.333333 85.333333 512s192 426.666667 426.666667 426.666667 426.666667-192 426.666667-426.666667S746.666667 85.333333 512 85.333333z m151.466667 516.266667c17.066667 17.066667 17.066667 42.666667 0 59.733333-8.533333 8.533333-19.2 12.8-29.866667 12.8s-21.333333-4.266667-29.866667-12.8L512 571.733333 422.4 661.333333c-8.533333 8.533333-19.2 12.8-29.866667 12.8s-21.333333-4.266667-29.866666-12.8c-17.066667-17.066667-17.066667-42.666667 0-59.733333l89.6-89.6-89.6-89.6c-17.066667-17.066667-17.066667-42.666667 0-59.733333s42.666667-17.066667 59.733333 0l89.6 89.6 89.6-89.6c17.066667-17.066667 42.666667-17.066667 59.733333 0s17.066667 42.666667 0 59.733333L571.733333 512l91.733334 89.6z"
                        fill="#c40000" p-id="1751"></path>
                  </svg>
                  <div>&nbsp;装裱</div>
                </div>
                <div class="cbmdbhai_each">
                  <svg v-show="this.artworkMsg.issignature" t="1672989023466" class="icon" viewBox="0 0 1024 1024"
                       version="1.1"
                       xmlns="http://www.w3.org/2000/svg" p-id="1592" width="20" height="20">
                    <path
                        d="M512 85.333333C277.333333 85.333333 85.333333 277.333333 85.333333 512s192 426.666667 426.666667 426.666667 426.666667-192 426.666667-426.666667S746.666667 85.333333 512 85.333333z m198.4 352l-211.2 211.2c-8.533333 8.533333-19.2 12.8-29.866667 12.8s-21.333333-4.266667-29.866666-12.8l-106.666667-106.666666c-17.066667-17.066667-17.066667-42.666667 0-59.733334s42.666667-17.066667 59.733333 0l76.8 76.8 181.333334-181.333333c17.066667-17.066667 42.666667-17.066667 59.733333 0s17.066667 42.666667 0 59.733333z"
                        fill="#52961C" p-id="1593"></path>
                  </svg>
                  <svg v-show="!this.artworkMsg.issignature" t="1672989134416" class="icon" viewBox="0 0 1024 1024"
                       version="1.1"
                       xmlns="http://www.w3.org/2000/svg" p-id="1750" width="20" height="20">
                    <path
                        d="M512 85.333333C277.333333 85.333333 85.333333 277.333333 85.333333 512s192 426.666667 426.666667 426.666667 426.666667-192 426.666667-426.666667S746.666667 85.333333 512 85.333333z m151.466667 516.266667c17.066667 17.066667 17.066667 42.666667 0 59.733333-8.533333 8.533333-19.2 12.8-29.866667 12.8s-21.333333-4.266667-29.866667-12.8L512 571.733333 422.4 661.333333c-8.533333 8.533333-19.2 12.8-29.866667 12.8s-21.333333-4.266667-29.866666-12.8c-17.066667-17.066667-17.066667-42.666667 0-59.733333l89.6-89.6-89.6-89.6c-17.066667-17.066667-17.066667-42.666667 0-59.733333s42.666667-17.066667 59.733333 0l89.6 89.6 89.6-89.6c17.066667-17.066667 42.666667-17.066667 59.733333 0s17.066667 42.666667 0 59.733333L571.733333 512l91.733334 89.6z"
                        fill="#c40000" p-id="1751"></path>
                  </svg>
                  <div>&nbsp;艺术家签名</div>
                </div>
                <div class="cbmdbhai_each">
                  <svg v-show="this.artworkMsg.isunique" t="1672989023466" class="icon" viewBox="0 0 1024 1024"
                       version="1.1"
                       xmlns="http://www.w3.org/2000/svg" p-id="1592" width="20" height="20">
                    <path
                        d="M512 85.333333C277.333333 85.333333 85.333333 277.333333 85.333333 512s192 426.666667 426.666667 426.666667 426.666667-192 426.666667-426.666667S746.666667 85.333333 512 85.333333z m198.4 352l-211.2 211.2c-8.533333 8.533333-19.2 12.8-29.866667 12.8s-21.333333-4.266667-29.866666-12.8l-106.666667-106.666666c-17.066667-17.066667-17.066667-42.666667 0-59.733334s42.666667-17.066667 59.733333 0l76.8 76.8 181.333334-181.333333c17.066667-17.066667 42.666667-17.066667 59.733333 0s17.066667 42.666667 0 59.733333z"
                        fill="#52961C" p-id="1593"></path>
                  </svg>
                  <svg v-show="!this.artworkMsg.isunique" t="1672989134416" class="icon" viewBox="0 0 1024 1024"
                       version="1.1"
                       xmlns="http://www.w3.org/2000/svg" p-id="1750" width="20" height="20">
                    <path
                        d="M512 85.333333C277.333333 85.333333 85.333333 277.333333 85.333333 512s192 426.666667 426.666667 426.666667 426.666667-192 426.666667-426.666667S746.666667 85.333333 512 85.333333z m151.466667 516.266667c17.066667 17.066667 17.066667 42.666667 0 59.733333-8.533333 8.533333-19.2 12.8-29.866667 12.8s-21.333333-4.266667-29.866667-12.8L512 571.733333 422.4 661.333333c-8.533333 8.533333-19.2 12.8-29.866667 12.8s-21.333333-4.266667-29.866666-12.8c-17.066667-17.066667-17.066667-42.666667 0-59.733333l89.6-89.6-89.6-89.6c-17.066667-17.066667-17.066667-42.666667 0-59.733333s42.666667-17.066667 59.733333 0l89.6 89.6 89.6-89.6c17.066667-17.066667 42.666667-17.066667 59.733333 0s17.066667 42.666667 0 59.733333L571.733333 512l91.733334 89.6z"
                        fill="#c40000" p-id="1751"></path>
                  </svg>
                  <div>&nbsp;独版</div>
                </div>
                <div class="cbmdbhai_each">
                  <svg v-show="this.artworkMsg.isthreed" t="1672989023466" class="icon" viewBox="0 0 1024 1024"
                       version="1.1"
                       xmlns="http://www.w3.org/2000/svg" p-id="1592" width="20" height="20">
                    <path
                        d="M512 85.333333C277.333333 85.333333 85.333333 277.333333 85.333333 512s192 426.666667 426.666667 426.666667 426.666667-192 426.666667-426.666667S746.666667 85.333333 512 85.333333z m198.4 352l-211.2 211.2c-8.533333 8.533333-19.2 12.8-29.866667 12.8s-21.333333-4.266667-29.866666-12.8l-106.666667-106.666666c-17.066667-17.066667-17.066667-42.666667 0-59.733334s42.666667-17.066667 59.733333 0l76.8 76.8 181.333334-181.333333c17.066667-17.066667 42.666667-17.066667 59.733333 0s17.066667 42.666667 0 59.733333z"
                        fill="#52961C" p-id="1593"></path>
                  </svg>
                  <svg v-show="!this.artworkMsg.isthreed" t="1672989134416" class="icon" viewBox="0 0 1024 1024"
                       version="1.1"
                       xmlns="http://www.w3.org/2000/svg" p-id="1750" width="20" height="20">
                    <path
                        d="M512 85.333333C277.333333 85.333333 85.333333 277.333333 85.333333 512s192 426.666667 426.666667 426.666667 426.666667-192 426.666667-426.666667S746.666667 85.333333 512 85.333333z m151.466667 516.266667c17.066667 17.066667 17.066667 42.666667 0 59.733333-8.533333 8.533333-19.2 12.8-29.866667 12.8s-21.333333-4.266667-29.866667-12.8L512 571.733333 422.4 661.333333c-8.533333 8.533333-19.2 12.8-29.866667 12.8s-21.333333-4.266667-29.866666-12.8c-17.066667-17.066667-17.066667-42.666667 0-59.733333l89.6-89.6-89.6-89.6c-17.066667-17.066667-17.066667-42.666667 0-59.733333s42.666667-17.066667 59.733333 0l89.6 89.6 89.6-89.6c17.066667-17.066667 42.666667-17.066667 59.733333 0s17.066667 42.666667 0 59.733333L571.733333 512l91.733334 89.6z"
                        fill="#c40000" p-id="1751"></path>
                  </svg>
                  <div>&nbsp;3D预览</div>
                </div>
              </div>

            </div>
            <div class="cbmdbh_line"/>

            <div class="cbmdbh_artist public_black f-l">艺术家：
              <a class="public_themeColor_link f-b" :href="this.artworkDetailUrl + this.artworkMsg.ezUser.nickname">
                @{{ this.artworkMsg.ezUser.nickname }}
              </a>
            </div>
            <div class="cbmdbh_pricetrans public_black doNotSelect" v-if="this.artworkMsg.virtualentity === 0">运费：<span
                class="public_Flame_themeColor">{{ this.artworkMsg.pricetrans }}</span>&nbsp;<span>CNY</span></div>
            <div class="cbmdbh_pricetrans public_black doNotSelect" v-else><span
                class="public_Flame_themeColor"></span>&nbsp;<span></span></div>
            <input @click="submitOrder" id="commodity_submit" class="cbmdb_submit pointer public_submit_color f-b" type="button" value="下订单">
            <input @click="toArtCanvas" class="cbmdb_showThreeD pointer f-b" v-show="this.artworkMsg.isthreed" type="button" value="3D预览">

            <div class="cbmdbh_sellAlert doNotSelect f-l ta-c" v-if="this.artworkMsg.state === 1">在售</div>
            <div class="cbmdbh_sellAlert doNotSelect f-l ta-c" v-if="this.artworkMsg.state === 2">售罄</div>

          </div>

          <div class="cbmdb_botton doNotSelect">
            <div class="cbmdbb_icon">
              <svg @click="likeArtwork" t="1672992844938" class="liked_icon pointer" viewBox="0 0 1024 1024"
                   version="1.1"
                   xmlns="http://www.w3.org/2000/svg" p-id="3107" width="40" height="40">
                <path
                    d="M729.068827 119.55798c-94.686523 0-176.905082 48.314379-217.069851 118.640074-40.163745-70.325695-122.382305-118.640074-217.044268-118.640074-143.767358 0-229.665727 123.660414-229.665727 243.219417 0 283.128359 415.870616 527.841803 433.576883 538.126031 4.062526 2.363837 8.584516 3.538593 13.132088 3.538593 4.547573 0 9.070586-1.174756 13.132088-3.538593 17.706267-10.283204 433.576883-254.997672 433.576883-538.126031C958.708971 243.218394 872.811626 119.55798 729.068827 119.55798z"
                    p-id="3108"></path>
              </svg>
            </div>
            <div class="cbmdbb_num public_gray"><span>{{ this.likedNum }}</span></div>
          </div>
        </div>
<!--        详情卡片-->
        <div class="cbm_otherMsg_body section commodity_MAXImg_bgcolor public_black doNotSelect">

          <div class="cbmob_artist_avatar">
            <img class="cbmobaa_img" :src="this.staticFileURL+'userAvatar/'+this.artworkMsg.ezUser.avatar" alt="">
          </div>
          <div class="f-20 ta-c f-l">@<a class="public_themeColor_link f-b"
                                         :href="this.artworkDetailUrl + this.artworkMsg.ezUser.nickname">{{ this.artworkMsg.ezUser.nickname }}</a></div>
          <div class="cbmdbh_line"/>
          <div class="cbmob_title ta-c f-b">艺术品信息</div>
          <div class="cbmdbh_line"/>
          <div class="cbmob_body f-l">
            <div class="cbmobb_each">
              <div class=""><span class="f-b">作品名称</span>：{{ this.artworkMsg.namecn }}</div>
              <div class=""><span class="f-b">英文名</span>：{{ this.artworkMsg.nameen }}</div>
              <div class=""><span class="f-b">风格</span>：{{ this.artworkMsg.style }}</div>
            </div>
            <div class="cbmobb_each" v-if="this.artworkMsg.virtualentity === 0">
              <div class=""><span class="f-b">材质</span>：{{ this.artworkMsg.material }}</div>
              <div class=""><span class="f-b">尺寸</span>：{{ this.artworkMsg.size }}</div>
              <div class=""><span class="f-b">创作日期</span>：{{ this.artworkMsg.createdate }}</div>
              <br>
            </div>
            <div class="cbmobb_each">
              <div class=""><span class="f-b">题材</span>：{{ this.artworkMsg.theme }}</div>
              <div class=""><span class="f-b">作品类型</span>：{{ this.artworkMsg.artType.typename }}</div>
            </div>
          </div>
        </div>


        <div class="cbm_introduce_body commodity_MAXImg_bgcolor public_black">
          <div class="cbmob_title ta-c f-b">介绍</div>
          <div class="cbmdbh_line"/>
          <div class="cbm_intro_body mt-2 ta-c">{{ this.artworkMsg.introduce }}</div>
        </div>
      </div>
    </div>
    <FlameFooter/>
  </div>
</template>

<script>
import router from "@/router";
import * as $ from "jquery";
import FlameNavBar from '@/components/Flame-NavBar'
import request from "@/utils/request";
import FlameFooter from "@/components/Flame-Footer";

export default {
  name: "commodity",
  components: {
    FlameFooter,
    FlameNavBar,
  },
  data() {
    return {
      //ArtworkImgURL
      staticFileURL: this.$globalVar + '/files/',
      //跳转艺术家主页链接
      artworkDetailUrl:"/#/store/Artist?redirectUrl=" + encodeURIComponent(window.location.origin) + encodeURIComponent(window.location.pathname) + "&artistName=",
      // 是否点赞
      likeFlag: false,
      //购买登录状态检测
      payLoginCheck:false,
      //点赞量显示
      likedNum: 0,
      //大图url
      maxShowImgUrl: '',
      //初始化艺术品详细信息
      artworkMsg: {
        id: '',
        imgurl: '',
        tdmodelurl: '',
        introduce: '',
        ismount: '',
        isoriginal: '',
        issignature: '',
        isunique: '',
        isthreed: '',
        liked: '',
        createdate: '',
        material: '',
        namecn: '',
        nameen: '',
        price: '',
        pricetrans: '',
        size: '',
        state: '',
        style: '',
        theme: '',
        type: '',
        uuid: '',
        uuuid: '',
        virtualentity: '',
        ezUser: {
          nickname: '',
          avatar: '',
        },
        artType:{
          typename:'',
        }
      },
      commodityButtonSecAlert:true,
      //urlID
      urlArtworkID: decodeURIComponent(router.currentRoute.value.query.artworkID),
      //redirect
      redirect: decodeURIComponent(router.currentRoute.value.query.redirectUrl),

    }
  },
  mounted() {
    $('.cbmib_main_img').addClass('cbmib_maximg_controHeight').addClass('cbmib_maximg_controWidth')
    this.getArtworkMsg(this.urlArtworkID)
    this.checkUserisLike()
  },
  methods: {
    submitOrder(){
      if (!this.commodityButtonSecAlert) {
        this.commoditySecondAlert('commodity_submit')
      }
      if(localStorage.getItem("flame_user_uuid") === "" || localStorage.getItem("flame_user_uuid") ===null){
        this.commodityButtonAlert(false,'请先登录')
      }else {

        request.get('artwork/store/submitOrderEvent', {
          params: {
            userUUID: localStorage.getItem('flame_user_uuid'),
            artworkUUID: this.artworkMsg.uuid
          }
        }).then(res => {
          // console.log(res.data)
          if(res.data === '0'){
            this.commodityButtonAlert(false,'已售罄')
          }else if(res.data === '2'){
            this.commodityButtonAlert(false,'不能购买自己的艺术品')
          }else if(res.data === '3'||res.data === '4'){
            this.commodityButtonAlert(false,'已售罄')
          }else{
            window.open("/#/store/check-order" + "?redirectUrl="
                + encodeURIComponent(window.location.origin)
                + encodeURIComponent(window.location.pathname)
                + "&artworkID=" + encodeURIComponent(this.artworkMsg.uuid)
                + "&orderID="+encodeURIComponent(res.data)
                + "&artworkImage=" + encodeURIComponent(this.artworkMsg.imgurl.split('%2C')[0]) ,'_self');
          }



          // if(res.data === '1'){
          //   window.open("/store/check-order" + "?redirectUrl="
          //       + encodeURIComponent(window.location.origin)
          //       + encodeURIComponent(window.location.pathname)
          //       + "&artworkID=" + encodeURIComponent(this.artworkMsg.uuid)
          //       + "&artworkImage=" + encodeURIComponent(this.artworkMsg.imgurl.split('%2C')[0]) ,'_self');
          // }else if(res.data === '0'){
          //   this.commodityButtonAlert(false,'已售罄')
          // }else if(res.data === '2'){
          //   this.commodityButtonAlert(false,'不能购买自己的艺术品')
          // }else if(res.data === '4'){
          //   this.commodityButtonAlert(false,'已售罄')
          // }

        })

      }
    },

    commodityButtonAlert(Flag, tips) {
      if (Flag) {
        this.commodityButtonSecAlert = true
        $('#commodity_submit').removeClass('public_submit_Alert').addClass('public_submit_color')
        $('#commodity_submit').val('下订单')
      } else {
        this.commodityButtonSecAlert = false
        $('#commodity_submit').removeClass('public_submit_color').addClass('public_submit_Alert')
        $('#commodity_submit').val(tips)
      }
    },
    commoditySecondAlert(BtnID) {
      $('#' + BtnID).addClass('public_submit_secAlert')
      setTimeout(() => {
        $("#" + BtnID).removeClass("public_submit_secAlert")
      }, 10);
    },


    getArtworkMsg(targetUUID) {
      const numRenderReg = /(\d)(?=(?:\d{3})+$)/g;
      request.get('artwork/store/getArtworkMsg', {params: {targetUUID: targetUUID}}).then(res => {
        this.artworkMsg = res
        this.artworkMsg.price = this.artworkMsg.price.toString().replace(numRenderReg, '$1,');
        this.likedNum = this.artworkMsg.liked.toString().replace(numRenderReg, '$1,');
        this.matchMaxImgBorder(this.artworkMsg.imgurl.split('%2C')[0])
        if(this.artworkMsg.state !== 1){
          this.commodityButtonAlert(false,'已售罄')
        }else if(this.artworkMsg.uuuid === localStorage.getItem('flame_user_uuid')){
          this.commodityButtonAlert(false,'不能购买自己的艺术品')
        }
      })
    },
    matchMaxImgBorder(imgUrl) {
      let maxImg = new Image()
      maxImg.src = this.staticFileURL+'artworkImage/'+imgUrl
      maxImg.onload = function () {

        if (((maxImg.height / maxImg.width) < 1)) {
          if((maxImg.height / maxImg.width) > 0.9){
            $('.cbmib_main_img').removeClass('cbmib_maximg_controWidth').addClass('cbmib_maximg_controHeight')
          }else{
            $('.cbmib_main_img').removeClass('cbmib_maximg_controHeight').addClass('cbmib_maximg_controWidth')
          }
        }else {
          $('.cbmib_main_img').removeClass('cbmib_maximg_controWidth').addClass('cbmib_maximg_controHeight')
        }
      }
      this.maxShowImgUrl = imgUrl
    },
    changeMaxImage(imgUrl) {
      this.matchMaxImgBorder(imgUrl)
    },
    checkUserisLike() {
      request.get('artwork/store/getuserisLke', {
        params: {
          userUUID: localStorage.getItem('flame_user_uuid'),
          artworkUUID: this.urlArtworkID
        }
      }).then(res => {
        this.likeFlag = res
        if (!this.likeFlag) {
          $('.liked_icon').css('fill', '#E4404F')
        } else {
          $('.liked_icon').css('fill', '#4b4b4b')
        }

      })
    },
    //点赞触发的方法
    likeArtwork() {
        if (localStorage.getItem("flame_user_uuid") === "" || localStorage.getItem("flame_user_uuid") ===null) {
          this.loginFlag = 1;
        } else {
          this.loginFlag = 2;
        }
      switch (this.loginFlag) {
          //未登录情况，伪赞
        case 1:
          this.likeArtworkDisplay(this.likeFlag)
          break;
          //已登录情况，写入数据库
        case 2:
          request.get("artwork/store/userLiked", {
            params: {
              likeFlag: this.likeFlag,
              userUUID: localStorage.getItem('flame_user_uuid'),
              artworkUUID: this.urlArtworkID
            }
          }).then(res => {
            this.likeArtworkDisplay(this.likeFlag)
          })

          break;
      }
    },
    //点赞更改icon fill和likeFlag的值方法
    likeArtworkDisplay(Flag) {
      const numRenderReg = /(\d)(?=(?:\d{3})+$)/g;
      if (!Flag) {
        //已赞，点击取消赞
        this.artworkMsg.liked = this.artworkMsg.liked - 1
        $('.liked_icon').css('fill', '#4b4b4b')
      } else {
        //未赞，点击点赞
        this.artworkMsg.liked = this.artworkMsg.liked + 1
        $('.liked_icon').css('fill', '#E4404F')
      }
      this.likedNum = this.artworkMsg.liked.toString().replace(numRenderReg, '$1,');
      this.likeFlag = !this.likeFlag
    },

    glassAnim(Flag) {
      if (Flag) {
        $('.cbmibmi_glass').addClass('cbmib_main_img_glassAnim')
      } else {
        $('.cbmibmi_glass').removeClass('cbmib_main_img_glassAnim')
      }
    },

    toArtCanvas(){
      window.open("/#/store/Art-Scene?redirectUrl="
          + encodeURIComponent(window.location.origin)
          + encodeURIComponent(window.location.pathname)
          + "&artworkID=" + encodeURIComponent(this.artworkMsg.uuid)
          + "&artworkModelUrl=" + encodeURIComponent(this.artworkMsg.tdmodelurl) ,'_self');
    }

  }
}
</script>

<style src="@/style/FlameTheme.css" scoped/>
<style src="@/style/FlameDarkTheme.css" scoped/>
<style src="@/style/PublicStyle.css" scoped/>
<style scoped>

.commodity_body {
  position: relative;
  margin: 0 auto;
  width: 1370px;
  height: 1800px;
  overflow: hidden;
}

.cb_main {

}

.cbmib_maximg_controWidth {
  width: 75%;
}

.cbmib_maximg_controHeight {
  height: 75%;
}

.cbm_img_border {
  overflow: hidden;
  position: relative;
  float: left;
  width: 500px;
  height: 500px;
  border-radius: 5px;
  margin-left: 50px;
  margin-top: 50px;
}

.cbmib_main_border {
  /*background-color: green;*/
  display: flex;
  position: relative;
  width: inherit;
  height: 400px;
}

.cbmib_main_img_border {
  display: flex;
  position: relative;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 490px;
  height: 390px;
  margin: 0 auto;
  align-self: center;
}

.cbmib_main_img img {
  width: 100%;
  height: 100%;
}

.cbmibmi_glass {
  position: absolute;
  left: 100%;
  transform: skewX(-40deg);
  background-image: linear-gradient(to right, #ffffff, #8A8A8A);
  /*background-color: #e0e0e0;*/
  mix-blend-mode: soft-light;
  width: 120%;
  height: 100%;
  opacity: 0.6;
}

.cbmib_main_img_glassAnim {
  animation: cb_main_img_glassAnim 0.5s;
  left: 50%;
}

@keyframes cb_main_img_glassAnim {
  from {
    left: 100%;
  }
  to {
    left: 50%;
  }
}

.cbmib_main_img {
  overflow: hidden;
  position: relative;
  margin: 0 auto;
  border: 20px solid #f1f1f1;
  box-shadow: 0px 0px 0px 10px #333333, 0px 0px 10px 15px rgba(159, 158, 158, 0.5);
  align-self: center;

}

.dark .cbmib_main_img {
  margin: 0 auto;
  border: 20px solid #484848;
  box-shadow: 0px 0px 0px 10px #e0e0e0, 0px 0px 10px 15px rgba(21, 21, 21, 0.5);
  align-self: center;
}

.cbmib_min_border {
  display: flex;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  position: relative;
  margin: 0 auto;
  width: 490px;
  height: 95px;
  overflow: hidden;
}

.cbmib_min_img {
  /*background-color: green;*/
  /*overflow: hidden;*/
  align-self: center;
  margin-left: 10px;
  width: 60px;
  height: 60px;
}

.cbmib_min_border img {
  object-fit: cover;
  border: 3px solid #414141;
  width: 100%;
  height: 100%;
}

.cbm_detailMsg_body {
  position: relative;
  float: left;
  overflow: hidden;
  width: 50%;
  height: 500px;
  border-radius: 5px;
  margin-left: 100px;
  margin-top: 50px;
}

.cbmdb_head {
  width: 100%;
  height: 400px;
}

.cbmdb_botton {
  width: 100%;
  height: 100px;
}

.cbmdbh_title {
  font-size: 40px;
  font-weight: 600;
  padding-top: 30px;
  padding-left: 50px;
}

.cbmdbh_title_colorBorder {
  float: left;
  border: none;
  background-color: #FF8000;
  width: 10px;
  height: 50px;
}

.cbmdbh_line {
  border-bottom: 1px solid #737373;
  width: 90%;
  height: 20px;
  margin: 0 auto;
}

.cbmdbh_price {
  font-size: 35px;
  font-weight: 600;
  padding-top: 20px;
  padding-left: 90px;
}

.cbmdbh_artist {
  font-size: 20px;
  padding-top: 25px;
  padding-left: 90px;
}

.cbmdbh_pricetrans {
  font-size: 13px;
  padding-top: 15px;
  padding-left: 90px;
}

.cbmdbh_artwork_include {
  width: 80%;
  height: 20px;
  margin: 0 auto;
  padding-top: 20px;
}

.cbmdbhai_each {
  position: relative;
  float: left;
  /*background-color: red;*/
  margin-left: 20px;
  width: auto;
  font-size: 15px;
  font-weight: 100;
}

.cbmdbhai_each svg {
  float: left;
}

.cbmdbhai_each div {
  float: left;
}


.cbmdb_submit {
  float: left;
  width: 300px;
  height: 50px;
  margin-top: 15px;
  margin-left: 85px;
  border: none;
}
.cbmdb_showThreeD{
  position: relative;
  float: left;
  width: 100px;
  height: 50px;
  background-color: #0078f1;
  color: #f0f0f0;
  margin-top: 15px;
  margin-left: 20px;
  border-radius: 10px;
  border: none;
}
.cbmdb_showThreeD:hover{
  animation:cbmdb_showThreeDAnim 0.2s ;
  background-color: #797979;
}
@keyframes cbmdb_showThreeDAnim {
  from{
    background-color: #0078f1;
  }to{
       background-color: #797979;
     }
}
.cbmdbh_sellAlert {
  border: 1px solid #FF8000;
  border-radius: 5px;
  width: 40px;
  float: left;
  font-size: 10px;
  margin-left: 20px;
  margin-top: 40px;
}

.cbmdbb_icon {
  position: relative;
  float: left;
  margin-left: 80px;
  margin-top: 30px;
}

.cbmdbb_num {
  display: flex;
  position: relative;
  float: left;
  width: 60%;
  height: 100%;
  font-size: 30px;
  font-weight: 600;
  margin-left: 20px;
}

.cbmdbb_num span {
  align-self: center;
}

.liked_icon {
  fill: #4b4b4b;
}

.cbm_otherMsg_body {
  overflow-y: visible;
  position: relative;
  float: left;
  width: 94%;
  height: 550px;
  border-radius: 5px;
  margin: 80px 50px;
}

.dark .cbm_otherMsg_body {
  overflow-y: visible;
}

.cbm_introduce_body {
  position: relative;
  float: left;
  width: 94%;
  height: 500px;
  border-radius: 5px;
  margin: 0 50px;
}

.cbmob_artist_avatar {
  position: relative;
  overflow: hidden;
  top: -30px;
  width: 120px;
  height: 120px;
  border-radius: 100%;
  margin: 0 auto;
}

.cbmobaa_img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.cbmob_title {
  /*background-color: red;*/
  font-size: 30px;
  padding-top: 30px;
}

.cbmob_body {
  width: 90%;
  height: 300px;
  margin: 20px auto;
  letter-spacing: 5px;
}

.cbmobb_each {
  /*background-color: red;*/
  float: left;
  margin-left: 70px;
  width: 300px;
  height: 250px;
}

.cbmobb_each div {
  margin-top: 40px;
}

.cbm_intro_body{
  position: relative;
  margin: 20px auto;
  width: 90%;
  line-height: 40px;
}



/*竖屏 css*/
/*.commodity_MIN_TAB .commodity_backRedirect {*/
/*  display: flex;*/
/*  width: 60px;*/
/*  height: 30px;*/
/*  position: relative;*/
/*  left: 0;*/
/*  margin-left: 15px;*/
/*  !*background-color: red;*!*/
/*  border-radius: 5px;*/
/*}*/

/*.commodity_MIN_TAB .commodity_backRedirect a {*/
/*  align-self: center;*/
/*  margin: 0 auto;*/
/*  z-index: 2;*/
/*}*/

.commodity_MIN_TAB .commodity_body {
  position: relative;
  margin: 0 auto;
  width: 90%;
  height: 2400px;
}


.commodity_MIN_TAB .cbmib_maximg_controWidth {
  width: 75%;
}

.commodity_MIN_TAB .cbmib_maximg_controHeight {
  height: 75%;
}

.commodity_MIN_TAB .cbm_img_border {
  overflow: hidden;
  position: relative;
  float: none;
  width: 90%;
  height: 500px;
  border-radius: 5px;
  margin: 10px auto;
}

.commodity_MIN_TAB .cbmib_main_border {
  position: relative;
  width: 100%;
  height: 400px;
}

.commodity_MIN_TAB .cbmib_main_img_border {
  display: flex;
  position: relative;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 98%;
  height: 390px;
  margin: 0 auto;
  align-self: center;
}

.commodity_MIN_TAB .cbmib_main_img img {
  width: 100%;
  height: 100%;
}

.commodity_MIN_TAB .cbmibmi_glass {
  position: absolute;
  left: 100%;
  transform: skewX(-40deg);
  background-image: linear-gradient(to right, #ffffff, #8A8A8A);
  /*background-color: #e0e0e0;*/
  mix-blend-mode: soft-light;
  width: 120%;
  height: 100%;
  opacity: 0.6;
}

.commodity_MIN_TAB .cbmib_main_img_glassAnim {
  animation: cb_main_img_glassAnim 0.5s;
  left: 50%;
}


.commodity_MIN_TAB .cbmib_main_img {
  overflow: hidden;
  position: relative;
  margin: 0 auto;
  border: 20px solid #f1f1f1;
  box-shadow: 0px 0px 0px 10px #333333, 0px 0px 10px 15px rgba(159, 158, 158, 0.5);
  align-self: center;

}

.dark .commodity_MIN_TAB .cbmib_main_img {
  margin: 0 auto;
  border: 20px solid #484848;
  box-shadow: 0px 0px 0px 10px #e0e0e0, 0px 0px 10px 15px rgba(21, 21, 21, 0.5);
  align-self: center;
}

.commodity_MIN_TAB .cbmib_min_border {
  display: flex;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  position: relative;
  margin: 0 auto;
  width: 85%;
  height: 95px;
  overflow: hidden;
}

.commodity_MIN_TAB .cbmib_min_img {
  align-self: center;
  margin-left: 10px;
  width: 60px;
  height: 60px;
}

.commodity_MIN_TAB .cbmib_min_border img {
  object-fit: cover;
  border: 3px solid #414141;
  width: 100%;
  height: 100%;
}

.commodity_MIN_TAB .cbm_detailMsg_body {
  position: relative;
  overflow: hidden;
  float: none;
  width: 90%;
  height: 610px;
  border-radius: 5px;
  margin: 0 auto;
}

.commodity_MIN_TAB .cbmdb_head {
  width: 100%;
  height: 540px;
}

.commodity_MIN_TAB .cbmdb_botton {
  width: 100%;
  height: 60px;
}

.commodity_MIN_TAB .cbmdbh_title {
  font-size: 30px;
  font-weight: 600;
  padding-top: 30px;
  padding-left: 0px;
}

.commodity_MIN_TAB .cbmdbh_title_colorBorder {
  float: left;
  border: none;
  margin-left: 5%;
  background-color: #FF8000;
  width: 10px;
  height: 40px;
}

.commodity_MIN_TAB .cbmdbh_line {
  border-bottom: 1px solid #737373;
  width: 90%;
  height: 20px;
  margin: 0 auto;
}

.commodity_MIN_TAB .cbmdbh_price {
  font-size: 35px;
  font-weight: 600;
  padding-top: 20px;
  padding-left: 10%;
}

.commodity_MIN_TAB .cbmdbh_artist {
  font-size: 20px;
  padding-top: 25px;
  padding-left: 10%;
}

.commodity_MIN_TAB .cbmdbh_pricetrans {
  font-size: 13px;
  padding-top: 15px;
  padding-left: 10%;
}

.commodity_MIN_TAB .cbmdbh_artwork_include {
  width: 80%;
  height: 15%;
  margin: 0 5%;
  padding-top: 20px;
}

.commodity_MIN_TAB .cbmdbhai_each {
  position: relative;
  float: left;
  margin-left: 20px;
  margin-top: 2px;
  width: auto;
  font-size: 15px;
  font-weight: 100;
}

.commodity_MIN_TAB .cbmdbhai_each svg {
  float: left;
}

.commodity_MIN_TAB .cbmdbhai_each div {
  float: left;
}


.commodity_MIN_TAB .cbmdb_submit {
  display: flex;
  justify-content: center;
  position: relative;
  float: none;
  width: 80%;
  height: 50px;
  margin: 15px auto;
  border: none;
}
.commodity_MIN_TAB .cbmdb_showThreeD{
  display: flex;
  justify-content: center;
  position: relative;
  float: none;
  width: 80%;
  height: 50px;
  background-color: #0078f1;
  color: #f0f0f0;
  margin: 15px auto;
  border-radius: 10px;
  border: none;
}
.commodity_MIN_TAB .cbmdb_showThreeD:hover{
  animation:cbmdb_showThreeDAnim 0.2s ;
  background-color: #797979;
}
.commodity_MIN_TAB .cbmdbh_sellAlert {
  border: 1px solid #FF8000;
  border-radius: 5px;
  width: 40px;
  float: none;
  font-size: 10px;
  margin: -5px auto;
}

.commodity_MIN_TAB .cbmdbb_icon {
  position: relative;
  float: left;
  margin-left: 8%;
  margin-top: 10px;
}

.commodity_MIN_TAB .cbmdbb_num {
  display: flex;
  position: relative;
  float: left;
  width: 60%;
  height: 100%;
  font-size: 20px;
  font-weight: 600;
  margin-left: 20px;
}

.commodity_MIN_TAB .cbmdbb_num span {
  align-self: center;
}

.commodity_MIN_TAB .liked_icon {
  fill: #4b4b4b;
}

.commodity_MIN_TAB .cbm_otherMsg_body {
  overflow-y: scroll;
  position: relative;
  float: none;
  width: 90%;
  height: 550px;
  border-radius: 5px;
  margin: 50px auto;
}

.commodity_MIN_TAB .cbm_introduce_body {
  position: relative;
  float: none;
  width: 90%;
  height: 500px;
  border-radius: 5px;
  margin: 20px auto;
}

.commodity_MIN_TAB .cbmob_artist_avatar {
  position: relative;
  overflow: hidden;
  top: 20px;
  width: 120px;
  height: 120px;
  border-radius: 100%;
  margin: 30px auto;
}

.commodity_MIN_TAB .cbmobaa_img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.commodity_MIN_TAB .cbmob_title {
  /*background-color: red;*/
  font-size: 30px;
  padding-top: 30px;
}

.commodity_MIN_TAB .cbmob_body {
  width: 90%;
  height: 300px;
  margin: 0 auto;
  letter-spacing: 0px;
  text-align: center;
}

.commodity_MIN_TAB .cbmobb_each {
  /*background-color: red;*/
  float: none;
  margin-left: 10%;
  width: 80%;
  height: 90px;
}

.commodity_MIN_TAB .cbmobb_each div {
  margin-top: 10px;
}
</style>