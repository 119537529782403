<template>
  <div class="grecaptcha_style">
    <div ref="grecaptcha">
    </div>
  </div>
</template>

<script>
export default {
  // props: ["6LdJrw0jAAAAAOsI2o9MDF5BMHhTXaxDSw6qt84v"], // 秘钥
  props:['theme'],
  mounted() {
    window.ReCaptchaLoaded = this.loaded;
    const script = document.createElement("script");
    script.src =
        "https://www.recaptcha.net/recaptcha/api.js?onload=ReCaptchaLoaded&render=explicit";
    document.head.appendChild(script);
  },
  methods: {
    loaded() {
      window.grecaptcha.render(this.$refs.grecaptcha, {
        sitekey: "6LdNDRoqAAAAAM_pr87j6ViqufFwFIpCxCx91zdn",
        /**
         * res返回的是goole的验证情况，
         * 成功返回字符串
         * 失败不返回字符串
         * 所以根据字符串判断验证情况
         */
        theme: this.theme, //主题颜色，有light与dark两个值可选
        size: 'normal',//尺寸规则，有normal与compact两个值可选
        callback: res => {// true  - 验证成功 // false - 验证失败
          // console.log(res)
          if(res.length > 0){
            this.$emit("getReCAPTCHACode", true)
          }else{
            this.$emit("getReCAPTCHACode", false)
          }
        }
      });

    }
  },
};

</script>

<style scoped>
</style>