<template>
  <FlameNavBar/>
  <div class="publish_art_body public_bgc section">
    <div class="pab_main public_card_color">
      <div class="pabm_title public_black f-b doNotSelect">发布艺术品 | Flame Store</div>
      <div class="publish_line"/>
      <!--      第一排，艺术品名称-->
      <div class="pabm_main">
        <div class="artType_title public_black f-l">基本信息</div>
        <div class="pabm_input_each">
          <div class="input_border public_gray_border public_input_bgc public_black" id="namecn_border">
            <div class="input_border_title f-l doNotSelect" id="namecn">艺术品名称</div>
            <input class="input_border_main public_black"
                   @blur="checkUserInput('namecn',this.namecn)"
                   @click="selectBorder('namecn')"
                   v-model="this.namecn"
                   type="text">
          </div>
          <div class="input_alert_msg public_warning_color f-10" id="namecn_alert"></div>
        </div>

        <div class="pabm_input_each">
          <div class="input_border public_gray_border public_input_bgc public_black" id="nameen_border">
            <div class="input_border_title f-l doNotSelect" id="nameen">英文名称</div>
            <input class="input_border_main public_black"
                   @blur="checkUserInput('nameen',this.nameen)"
                   @click="selectBorder('nameen')"
                   v-model="this.nameen"
                   type="text">
          </div>
          <div class="input_alert_msg public_warning_color f-10" id="nameen_alert"></div>
        </div>

        <div class="pabm_input_each public_black">
          <div id="entity_btn" class="art_type_btn store_art_card_color f-l pointer doNotSelect"
               @click="chooseArtworkVorE(true)">
            <div>实体艺术品</div>
          </div>
          <div id="virtual_btn" class="art_type_btn store_art_card_color f-l pointer doNotSelect"
               @click="chooseArtworkVorE(false)">
            <div>虚拟艺术品</div>
          </div>
        </div>

      </div>
      <!--      第二排，艺术品价格-->
      <div class="pabm_main">
        <div class="pabm_input_each">
          <div class="input_border public_gray_border public_input_bgc public_black b_price" id="price_border">
            <div class="input_border_title f-l doNotSelect" id="price">艺术品价格</div>
            <input class="input_border_main public_black"
                   @blur="checkUserInput('price',this.price)"
                   @click="selectBorder('price')"
                   v-model="this.price"
                   type="text"
                   maxlength="8"
            >
            <div class="p-a f-15 f-l public_Flame_themeColor doNotSelect input_price_unit">CNY</div>
          </div>
          <div class="input_alert_msg public_warning_color f-10" id="price_alert"></div>
        </div>
        <div class="pabm_input_each" v-if="this.virtualentity === 0">
          <div class="input_border public_gray_border public_input_bgc public_black b_price" id="pricetrans_border">
            <div class="input_border_title f-l doNotSelect" id="pricetrans">艺术品运费</div>
            <input class="input_border_main public_black"
                   @blur="checkUserInput('pricetrans',this.pricetrans)"
                   @click="selectBorder('pricetrans')"
                   v-model="this.pricetrans"
                   type="text"
                   maxlength="8"
            >
            <div class="p-a f-15 f-l public_Flame_themeColor doNotSelect input_price_unit">CNY</div>
          </div>
          <div class="input_alert_msg public_warning_color f-10" id="pricetrans_alert"></div>
        </div>

        <div class="pabm_input_each">
          <div class="input_border public_gray_border public_input_bgc public_black b_introduce" id="introduce_border">
            <div class="input_border_title f-l doNotSelect" id="introduce">简介</div>
            <input class="input_border_main public_black i_introduce"
                   @blur="checkUserInput('introduce',this.introduce)"
                   @click="selectBorder('introduce')"
                   v-model="this.introduce"
                   type="text"
            >
          </div>
          <div class="input_alert_msg public_warning_color f-10" id="introduce_alert"></div>
        </div>
      </div>
      <div class="publish_line"/>
      <!--      第三排，艺术品类型-->
      <div class="pabm_main artType_body public_black">
        <div class="artType_title f-l">艺术品类型</div>
        <div v-for="item in this.artworkType" :key="item.atypeid">
          <div :id="item.atypeid + '_artType'" class="art_type_btn store_art_card_color f-l pointer doNotSelect"
               @click="chooseArtworkType(item.atypeid)">
            <div>{{ item.typename }}</div>
          </div>
        </div>
      </div>
      <div class="publish_line"/>
      <!--      第四排，其他信息-->
      <div class="pabm_main public_black">
        <div class="artType_title f-l">其他信息</div>

        <div class="pabm_input_each">
          <div class="input_border public_gray_border public_input_bgc public_black b_other" id="style_border">
            <div class="input_border_title f-l doNotSelect" id="style">风格</div>
            <input class="input_border_main public_black"
                   @blur="checkUserInput('style',this.style)"
                   @click="selectBorder('style')"
                   v-model="this.style"
                   type="text">
          </div>
          <div class="input_alert_msg public_warning_color f-10" id="style_alert"></div>
        </div>
        <div class="pabm_input_each">
          <div class="input_border public_gray_border public_input_bgc public_black b_other" id="theme_border">
            <div class="input_border_title f-l doNotSelect" id="theme">题材</div>
            <input class="input_border_main public_black"
                   @blur="checkUserInput('theme',this.theme)"
                   @click="selectBorder('theme')"
                   v-model="this.theme"
                   type="text">
          </div>
          <div class="input_alert_msg public_warning_color f-10" id="theme_alert"></div>
        </div>
        <div class="pabm_input_each" v-if="this.virtualentity === 0">
          <div class="input_border public_gray_border public_input_bgc public_black b_other" id="material_border">
            <div class="input_border_title f-l doNotSelect" id="material">材质</div>
            <input class="input_border_main public_black"
                   @blur="checkUserInput('material',this.material)"
                   @click="selectBorder('material')"
                   v-model="this.material"
                   type="text">
          </div>
          <div class="input_alert_msg public_warning_color f-10" id="material_alert"></div>
        </div>
        <div class="pabm_input_each" v-if="this.virtualentity === 0">
          <div class="input_border public_gray_border public_input_bgc public_black b_other" id="size_border">
            <div class="input_border_title f-l doNotSelect" id="size">艺术品尺寸</div>
            <input class="input_border_main public_black"
                   @blur="checkUserInput('size',this.size)"
                   @click="selectBorder('size')"
                   v-model="this.size"
                   type="text">
          </div>
          <div class="input_alert_msg public_warning_color f-10" id="size_alert"></div>
        </div>

      </div>
      <!--      第五排，单选框组-->
      <div class="pabm_main artType_body public_black">
        <div class="choice_card store_art_card_color">
          <div class="choice_card_title f-l ta-c doNotSelect">原创</div>
          <el-radio-group v-model="this.isoriginal" text-color="#000000" class="choice_card_body">
            <el-radio :label="1" size="small" class="public_black">是</el-radio>
            <el-radio :label="0" size="small" class="public_black">否</el-radio>
          </el-radio-group>
        </div>
        <div class="choice_card store_art_card_color">
          <div class="choice_card_title f-l ta-c doNotSelect">3D预览</div>
          <el-radio-group v-model="this.isthreed" text-color="#000000" class="choice_card_body">
            <el-radio :label="1" size="small" class="public_black">是</el-radio>
            <el-radio :label="0" size="small" class="public_black">否</el-radio>
          </el-radio-group>
        </div>
        <div class="choice_card store_art_card_color" v-if="this.virtualentity === 0">
          <div class="choice_card_title f-l ta-c doNotSelect">独版</div>
          <el-radio-group v-model="this.isunique" text-color="#000000" class="choice_card_body">
            <el-radio :label="1" size="small" class="public_black">是</el-radio>
            <el-radio :label="0" size="small" class="public_black">否</el-radio>
          </el-radio-group>
        </div>
        <div class="choice_card store_art_card_color" v-if="this.virtualentity === 0">
          <div class="choice_card_title f-l ta-c doNotSelect">有装裱</div>
          <el-radio-group v-model="this.ismount" text-color="#000000" class="choice_card_body">
            <el-radio :label="1" size="small" class="public_black">有</el-radio>
            <el-radio :label="0" size="small" class="public_black">无</el-radio>
          </el-radio-group>
        </div>
        <div class="choice_card store_art_card_color" v-if="this.virtualentity === 0">
          <div class="choice_card_title f-l ta-c doNotSelect">艺术家签名</div>
          <el-radio-group v-model="this.issignature" text-color="#000000" class="choice_card_body">
            <el-radio :label="1" size="small" class="public_black">有</el-radio>
            <el-radio :label="0" size="small" class="public_black">无</el-radio>
          </el-radio-group>
        </div>

      </div>
      <div class="publish_line"/>
      <!--      第六排，文件上传-->
      <div class="pabm_main public_black">
        <div class="artType_title f-l">选择艺术品图片 (最多选择5张)</div>
        <div class="upload_body">
          <el-upload
              :action= "this.$globalVar + '/files/artworkImage/upload'"
              list-type="picture-card"
              :on-remove="deleteArtImage"
              :on-preview="handlePictureCardPreview"
              :on-success="getArtImageUrl"
              :limit="5"
          >
            <el-icon>
              <Plus/>
            </el-icon>
          </el-upload>
        </div>
        <el-dialog v-model="this.uploadImgMaxFlag">
          <img style="object-fit: cover;width: 100%;height: 100%" w-full :src="this.ImgMaxUrl" alt="Preview Image"/>
        </el-dialog>
      </div>
      <!--      第七排，3D文件上传-->
      <div class="pabm_main public_black" style="margin-top: 20px;" v-if="this.isthreed === 1">
        <div class="artType_title">3D模型 <span class="f-15">(为保证浏览质量,模型须为.glb格式,贴图尺寸小于或等于1024×1024)</span></div>
        <div class="upload_body">
          <FlameArtCanvas
              class="publish_Canvas"
              width="600px"
              height="300px"
              modeltype="glb"
              background=""
              :blur="100"
              :exposure="1"
              :filter="'ACESFilmicToneMapping'"
              ref="publishCanvas"
          />
          <el-upload
              class="upload-demo"
              :action="this.$globalVar + '/files/glb/upload'"
              multiple
              :limit="1"
              :on-success="getArtModelUrl"
              :on-remove="deleteArtModel"
          >
            <input class="upload-threed_btn public_submit_color mt-4 pointer" type="button" value="上传">
          </el-upload>
        </div>

      </div>


      <div class="publish_btn_body public_black">
        <input class="publish_cancel_btn pointer" type="button" value="取消">
        <input id="publish_btn" @click="publishArtwork" class="publish_submit_btn pointer" type="button" value="发布">
      </div>

    </div>
    <successRedirect :sucRedirectURL="'/account/personal'" ref="successRedirect"/>
  </div>
</template>

<script>
import * as $ from "jquery";
import FlameNavBar from '@/components/Flame-NavBar'
import FlameArtCanvas from '@/components/Flame-ArtCanvas'
import {Plus} from '@element-plus/icons-vue'
import FlameInput from '@/components/Flame-Input'
import router from "@/router";
import request from "@/utils/request";
import successRedirect from "@/components/successredirect";

export default {
  name: "publishartwork",
  components: {
    FlameNavBar,
    FlameArtCanvas,
    successRedirect,
    Plus
  },
  data() {
    return {
      namecn: '',
      nameen: '',
      price: '',
      pricetrans: '',
      introduce: '',
      type: '',
      size: '',
      style: '',
      theme: '',
      material: '',
      tdmodelurl: '',
      imgurl: '',
      //是否原创
      isoriginal: 1,
      //装裱
      ismount: 0,
      // 艺术家签名
      issignature: 0,
      //独版
      isunique: 0,
      isthreed: 0,
      //虚拟艺术品或实体艺术品
      virtualentity: 0,


      artworkType: [],

      imglist: [{
        name: '',
        url: '',
      }],

      //用户输入验证
      inputFlag: [
        {
          Flag: false,
        }, {
          Flag: false,
        }, {
          Flag: false,
        }, {
          Flag: false,
        }, {
          Flag: false,
        }, {
          Flag: false,
        }, {
          Flag: false,
        }, {
          Flag: false,
        }, {
          Flag: false,
        }
      ],
      //二次警告
      secAlert: false,
      uploadImgMaxFlag: false,
      ImgMaxUrl: '',
      //是否选择了艺术品类型
      isChoiceArtType: false,
    }
  },
  mounted() {
    this.initPublishArtwork();
    this.getArtworkType();
  },
  methods: {
    test() {
    },
    initPublishArtwork() {
      $('#entity_btn').addClass('art_type_btn_choice').removeClass('store_art_card_color')
    },
    //blur事件验证
    checkUserInput(idName, inputValue) {
      //输入值为空时
      if (inputValue === "") {
        this.showAlertMsg(idName, '必填')
        $('#' + idName).stop(true, false).animate({
          left: '20px',
          top: '20px',
          fontSize: '15px',
        }, 100)
      } else {
        const textReg = /^[\u4E00-\u9FA5-A-Za-z0-9-_]+(?:[\s][A-Za-z0-9]+)*$/
        const numReg = /^\d+(\.{0,1}\d+){0,1}$/
        switch (idName) {
          case "namecn":
            if (inputValue === "") {
              this.showAlertMsg(idName, '必填')
              this.inputFlag[0].Flag = false;
            } else if (inputValue.length > 80) {
              this.showAlertMsg(idName, '名称过长')
              this.inputFlag[0].Flag = false;
            } else if (inputValue.length < 1) {
              this.showAlertMsg(idName, '名称过短')
              this.inputFlag[0].Flag = false;
            } else {
              this.inputFlag[0].Flag = true;
            }
            break;
          case "nameen":
            if (inputValue === "") {
              this.showAlertMsg(idName, '必填')
              this.inputFlag[1].Flag = false;
            } else if (inputValue.length > 80) {
              this.showAlertMsg(idName, '名称过长')
              this.inputFlag[1].Flag = false;
            } else if (inputValue.length < 1) {
              this.showAlertMsg(idName, '名称过短')
              this.inputFlag[1].Flag = false;
            }  else {
              this.inputFlag[1].Flag = true;
            }
            break;
          case "price":
            if (inputValue === "") {
              this.showAlertMsg(idName, '必填')
              this.inputFlag[2].Flag = false;
            } else if (!numReg.test(inputValue)) {
              this.showAlertMsg(idName, '请输入合法的价格')
              this.inputFlag[2].Flag = false;
            } else {
              this.inputFlag[2].Flag = true;
            }
            break;
          case "pricetrans":
            if (inputValue === "") {
              this.showAlertMsg(idName, '必填')
              this.inputFlag[3].Flag = false;
            } else if (!numReg.test(inputValue)) {
              this.showAlertMsg(idName, '请输入合法的价格')
              this.inputFlag[3].Flag = false;
            } else {
              this.inputFlag[3].Flag = true;
            }
            break;
          case "introduce":
            if (inputValue === "") {
              this.showAlertMsg(idName, '必填')
              this.inputFlag[4].Flag = false;
            } else if (inputValue.length > 255) {
              this.showAlertMsg(idName, '简介过长')
              this.inputFlag[4].Flag = false;
            } else if (inputValue.length < 1) {
              this.showAlertMsg(idName, '简介过短')
              this.inputFlag[4].Flag = false;
            }  else {
              this.inputFlag[4].Flag = true;
            }
            break;
          case "style":
            if (inputValue === "") {
              this.showAlertMsg(idName, '必填')
              this.inputFlag[5].Flag = false;
            } else if (inputValue.length > 20) {
              this.showAlertMsg(idName, '过长')
              this.inputFlag[5].Flag = false;
            } else if (inputValue.length < 1) {
              this.showAlertMsg(idName, '过短')
              this.inputFlag[5].Flag = false;
            } else if (!textReg.test(inputValue)) {
              this.showAlertMsg(idName, '包含非法字符')
              this.inputFlag[5].Flag = false;
            } else {
              this.inputFlag[5].Flag = true;
            }
            break;
          case "theme":
            if (inputValue === "") {
              this.showAlertMsg(idName, '必填')
              this.inputFlag[6].Flag = false;
            } else if (inputValue.length > 20) {
              this.showAlertMsg(idName, '过长')
              this.inputFlag[6].Flag = false;
            } else if (inputValue.length < 1) {
              this.showAlertMsg(idName, '过短')
              this.inputFlag[6].Flag = false;
            } else if (!textReg.test(inputValue)) {
              this.showAlertMsg(idName, '包含非法字符')
              this.inputFlag[6].Flag = false;
            } else {
              this.inputFlag[6].Flag = true;
            }
            break;
          case "material":
            if (inputValue === "") {
              this.showAlertMsg(idName, '必填')
              this.inputFlag[7].Flag = false;
            } else if (inputValue.length > 20) {
              this.showAlertMsg(idName, '过长')
              this.inputFlag[7].Flag = false;
            } else if (inputValue.length < 1) {
              this.showAlertMsg(idName, '过短')
              this.inputFlag[7].Flag = false;
            } else {
              this.inputFlag[7].Flag = true;
            }
            break;
          case "size":
            if (inputValue === "") {
              this.showAlertMsg(idName, '必填')
              this.inputFlag[8].Flag = false;
            } else if (inputValue.length > 20) {
              this.showAlertMsg(idName, '过长')
              this.inputFlag[8].Flag = false;
            } else if (inputValue.length < 1) {
              this.showAlertMsg(idName, '过短')
              this.inputFlag[8].Flag = false;
            } else {
              this.inputFlag[8].Flag = true;
            }
            break;
        }

      }

    },
    publishArtwork() {
      let checkFlag = true
      if (this.secAlert) {
        this.secondAlert()
      }
      if(this.virtualentity === 1){
        this.inputFlag[3].Flag = true
        this.inputFlag[7].Flag = true
        this.inputFlag[8].Flag = true
      }

      if (this.type !== "") {
        if (this.imgurl.length !== 0) {
          if (this.isthreed) {
            if (this.tdmodelurl !== "") {
              checkFlag = true
              this.buttonAlert(true)
            } else {
              this.buttonAlert(false, '模型不能为空')
              checkFlag = false
            }
          } else {
            checkFlag = true
            this.buttonAlert(true)
          }
        } else {
          this.buttonAlert(false, '艺术品图片不能为空')
          checkFlag = false
        }
      } else {
        this.buttonAlert(false, '艺术品类型不能为空')
        checkFlag = false
      }

      if (checkFlag) {
        for (let i = 0; i < this.inputFlag.length; i++) {
          if (!this.inputFlag[i].Flag) {
            checkFlag = false
            this.buttonAlert(false, '请检查输入值')
            break
          }
        }

        if(checkFlag){
          request.post('artwork/publish/publishArtwork', {
            'namecn': this.namecn,
            'nameen': this.nameen,
            'price': this.price,
            'pricetrans': this.pricetrans,
            'introduce': this.introduce,
            'uuuid': localStorage.getItem('flame_user_uuid'),
            'virtualentity': this.virtualentity,
            'imgurl': encodeURIComponent(this.imgurl),
            'tdmodelurl': encodeURIComponent(this.tdmodelurl),
            'isoriginal':this.isoriginal,
            'ismount': this.ismount,
            'issignature': this.issignature,
            'isunique': this.isunique,
            'isthreed': this.isthreed,
            'size': this.size,
            'style':this.style,
            'theme': this.theme,
            'type': this.type,
            'material': this.material,
          }).then(()=>{
            this.$refs.successRedirect.successRedirectTable('发布成功')
          })
        }
      }





    },


    getArtImageUrl(response) {
      if (this.imgurl === "") {
        this.imgurl = response.data
      } else {
        this.imgurl = this.imgurl + ',' + response.data
      }
      this.imgurl = this.imgurl.split(',')
      // console.log(this.imgurl)
    },
    getArtModelUrl(response) {
      this.tdmodelurl = response.data
      this.$refs.publishCanvas.loadModel(this.$globalVar + '/files/glb/' + this.tdmodelurl)
    },
    deleteArtModel() {
      this.$refs.publishCanvas.removeModel()
      request.post('files/glb/' + this.tdmodelurl + '/delete')
    },

    deleteArtImage(response) {
      for (let i = 0; i < this.imgurl.length; i++) {
        if (this.imgurl[i] === response.response.data) {
          this.imgurl.splice(i, 1)
        }
      }
      request.post('files/artworkImage/' + response.response.data + '/delete')
    },
    handlePictureCardPreview(response) {
      // console.log(response)
      this.ImgMaxUrl = response.url
      this.uploadImgMaxFlag = true
    },
    //选择艺术品是实体还是虚拟
    chooseArtworkVorE(typeFlag) {
      if (typeFlag) {
        this.virtualentity = 0;
        this.inputFlag[3].Flag = false
        this.inputFlag[7].Flag = false
        this.inputFlag[8].Flag = false
        $('#virtual_btn').addClass('store_art_card_color').removeClass('art_type_btn_choice')
        $('#entity_btn').addClass('art_type_btn_choice').removeClass('store_art_card_color')
      } else {
        this.virtualentity = 1;
        $('#entity_btn').addClass('store_art_card_color').removeClass('art_type_btn_choice')
        $('#virtual_btn').addClass('art_type_btn_choice').removeClass('store_art_card_color')
      }
    },
    //选择艺术品类型
    chooseArtworkType(typeFlag) {
      this.isChoiceArtType = true
      $('#' + this.type + '_artType').addClass('store_art_card_color').removeClass('art_type_btn_choice')
      this.type = typeFlag
      $('#' + typeFlag + '_artType').addClass('art_type_btn_choice').removeClass('store_art_card_color')
    },


    //点击初始化样式
    selectBorder(idName) {
      this.buttonAlert(true)
      $('#' + idName + '_alert').text('')
      $('#' + idName + "_border").removeClass("border_Warning")
      $('#' + idName).stop(true, false).animate({
        left: '15px',
        top: '5px',
        fontSize: '10px',
      }, 100)
    },
    //下方展示的验证警告
    showAlertMsg(idName, Msg) {
      $('#' + idName + "_border").addClass("border_Warning")
      $('#' + idName + '_alert').text(Msg)
    },
    getArtworkType() {
      request.get('artwork/store/getAllArtworkType').then(res => {
        this.artworkType = res
      })
    },


    buttonAlert(Flag, tips) {
      if (Flag) {
        this.secAlert = false
        $('#publish_btn').removeClass('public_submit_Alert').addClass('publish_submit_btn')
        $('#publish_btn').val('发布')
      } else {
        this.secAlert = true
        $('#publish_btn').removeClass('publish_submit_btn').addClass('public_submit_Alert')
        $('#publish_btn').val(tips)
      }
    },
    secondAlert() {
      $('#publish_btn').addClass('public_submit_secAlert')
      setTimeout(() => {
        $('#publish_btn').removeClass("public_submit_secAlert")
      }, 10);
    },

  }
}
</script>

<style src="@/style/FlameTheme.css" scoped/>
<style src="@/style/FlameDarkTheme.css" scoped/>
<style src="@/style/PublicStyle.css" scoped/>
<style scoped>
@media screen and (orientation: landscape) {
  .publish_art_body {
    position: absolute;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    height: 100%;
  }

  .publish_line {
    display: flex;
    height: 10px;
    width: 90%;
    border-bottom: 1px solid #939393;
    margin-bottom: 20px;
    margin-left: 5%;
    margin-right: 5%;
  }

  .pab_main {
    overflow: hidden;
    position: relative;
    width: 1170px;
    height: auto;
    margin: 52px auto;
  }

  .pabm_title {
    position: relative;
    margin-top: 30px;
    margin-left: 70px;
    font-size: 25px;
  }

  .pabm_main {
    overflow: hidden;
    width: 100%;
    height: auto;
    padding-top: 20px;
    padding-left: 50px;
  }

  .pabm_input_each {
    float: left;
    margin-left: 15px;
    /*background-color: #737373;*/
  }

  .artType_body {
    height: 110px;
  }

  .art_type_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    float: left;
    width: 120px;
    height: 60px;
    margin-left: 20px;
  }

  .art_type_btn_choice {
    font-weight: 600;
    color: #1c1c1c;
    background-color: #FF8000;
  }

  .dark .art_type_btn_choice {
    background-color: #FF8000;
  }


  .artType_title {
    margin-top: -20px;
    margin-left: 20px;
    font-size: 20px;
    margin-bottom: 20px;
  }

  .choice_card {
    float: left;
    width: 150px;
    height: 80px;
    border-radius: 5px;
    margin-left: 15px;

  }

  .choice_card_title {
    margin-top: 20px;
    height: 20px;
  }

  .choice_card_body {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }


  .upload_body {
    float: left;
    margin-left: 15px;
  }

  .upload-threed_btn {
    border: none;
    border-radius: 10px;
    width: 320px;
    height: 50px;
  }

  .publish_Canvas {
    border-radius: 10px;
  }

  .publish_btn_body {
    position: relative;
    margin: 50px 45px;
    width: auto;
  }


  .publish_btn_body input {
    border-radius: 5px;
    border: none;
    width: 150px;
    height: 50px;
    margin-left: 20px;
    margin-top: 20px;
  }

  .publish_cancel_btn {
    background-color: #d0d0d0;
  }

  .publish_submit_btn {
    color: #F0F0F0;
    background-color: #0074e3;
  }

  .publish_cancel_btn:hover {
    animation: publish_cancel_btnAnim 0.5s;
    background-color: #dedede;
  }

  .publish_submit_btn:hover {
    animation: managem_submit_btnAnim 0.5s;
    background-color: #005bb2;
  }

  @keyframes publish_cancel_btnAnim {
    from {
      background-color: #d0d0d0;
    }
    to {
      background-color: #dedede;
    }
  }

  @keyframes publish_submit_btnAnim {
    from {
      background-color: #0074e3;
    }
    to {
      background-color: #005bb2;
    }
  }


  /*INput样式*/
  .input_border {
    position: relative;
    border-radius: 5px;
    width: 365px;
    height: 60px;
  }

  .input_border_title {
    position: absolute;
    left: 20px;
    top: 20px;
    font-size: 15px;
  }

  .input_border_main {
    position: absolute;
    border: none;
    background-color: #FFFFFF00;
    outline: none;
    padding-left: 10px;
    font-size: 15px;
    width: 70%;
    height: 50%;
    bottom: 5px;
    left: 5px;
  }

  .input_alert_msg {
    position: relative;
    height: 20px;
  }
  .input_price_unit{
    right: 10px;
    top: 23px;
  }
  .b_price{
    width: 250px;
  }
  .b_introduce{
    width: 510px;
  }
  .i_introduce{
    width: 480px;
  }
  .b_other{
    width: 150px
  }
}
@media screen and (orientation: portrait) {
  .publish_art_body {
    position: absolute;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    height: 100%;
  }

  .publish_line {
    display: flex;
    height: 10px;
    width: 90%;
    border-bottom: 1px solid #939393;
    margin-bottom: 20px;
    margin-left: 5%;
    margin-right: 5%;
  }

  .pab_main {
    overflow: hidden;
    position: relative;
    width: 90%;
    height: auto;
    margin: 52px auto;
  }

  .pabm_title {
    position: relative;
    margin-top: 30px;
    margin-left: 5%;
    font-size: 25px;
  }

  .pabm_main {
    overflow: hidden;
    width: 100%;
    height: auto;
    padding-bottom: 10px;
    padding-top: 20px;
    padding-left: 0;
  }

  .pabm_input_each {
    position: relative;
    width: 100%;
    float: left;
    margin-left: 5%;
  }

  .artType_body {
    position: relative;
    /*padding-left: 10px;*/
    height: auto;
  }

  .art_type_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    float: left;
    width: 25%;
    height: 30px;
    margin-top: 10px;
    margin-left: 20px;
    font-size: 15px;
  }

  .art_type_btn_choice {
    font-weight: 600;
    color: #1c1c1c;
    background-color: #FF8000;
  }

  .dark .art_type_btn_choice {
    background-color: #FF8000;
  }


  .artType_title {
    margin-top: -20px;
    margin-left: 20px;
    font-size: 20px;
    margin-bottom: 20px;
  }

  .choice_card {
    float: left;
    width: 40%;
    height: 80px;
    border-radius: 5px;
    margin-top: 20px;
    margin-left: 5%;
  }

  .choice_card_title {
    margin-top: 20px;
    height: 20px;
  }

  .choice_card_body {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }


  .upload_body {
    float: left;
    margin-left: 15px;
  }

  .upload-threed_btn {
    border: none;
    border-radius: 10px;
    width: 320px;
    height: 50px;
  }

  .publish_Canvas {
    border-radius: 10px;
  }

  .publish_btn_body {
    position: relative;
    margin: 50px auto;
    width: 90%;
  }


  .publish_btn_body input {
    border-radius: 5px;
    border: none;
    width: 45%;
    height: 50px;
    margin-left: 5%;
    margin-top: 20px;
  }

  .publish_cancel_btn {
    background-color: #d0d0d0;
  }

  .publish_submit_btn {
    color: #F0F0F0;
    background-color: #0074e3;
  }

  .publish_cancel_btn:hover {
    animation: publish_cancel_btnAnim 0.5s;
    background-color: #dedede;
  }

  .publish_submit_btn:hover {
    animation: managem_submit_btnAnim 0.5s;
    background-color: #005bb2;
  }

  @keyframes publish_cancel_btnAnim {
    from {
      background-color: #d0d0d0;
    }
    to {
      background-color: #dedede;
    }
  }

  @keyframes publish_submit_btnAnim {
    from {
      background-color: #0074e3;
    }
    to {
      background-color: #005bb2;
    }
  }


  /*INput样式*/
  .input_border {
    position: relative;
    border-radius: 5px;
    width: 90%;
    height: 60px;
  }

  .input_border_title {
    position: absolute;
    left: 20px;
    top: 20px;
    font-size: 15px;
  }

  .input_border_main {
    position: absolute;
    border: none;
    background-color: #FFFFFF00;
    outline: none;
    padding-left: 10px;
    font-size: 15px;
    width: 70%;
    height: 50%;
    bottom: 5px;
    left: 5px;
  }

  .input_alert_msg {
    position: relative;
    height: 20px;
  }
  .input_price_unit{
    right: 10px;
    top: 23px;
  }

  .b_price,
  .b_introduce,
  .i_introduce,
  .b_other{
    width: 90%;
  }


}





</style>