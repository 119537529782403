<template>
  <FlameNavBar/>
  <div class="od_body public_bgc public_black">
    <div class="odb_main public_card_color">
      <div class="odbm_title ta-c">订单详情</div>
      <div class="od_line"/>
      <div class="odbm_detail_border f-l">
        <div class="odbm_detail_each">ID：{{this.orderDetail.oid}}</div>
        <div class="odbm_detail_each">名称：{{this.orderDetail.ezArtwork.namecn}}</div>
        <div class="odbm_detail_each">价格：{{this.totalPriceShow}} <span>CNY</span></div>
        <div class="odbm_detail_each">创建时间：{{this.orderDetail.orderdate}}</div>
        <div class="odbm_detail_each" v-if="this.orderDetail.orderstate === 0">订单状态：<span class="public_warning_color f-b">未支付</span></div>
        <div class="odbm_detail_each" v-if="this.orderDetail.orderstate === 1">订单状态：<span class="public_green">已支付</span></div>
        <div class="odbm_detail_each" v-if="this.orderDetail.orderstate === 2">订单状态：<span class="public_green">进行中</span></div>
        <div class="odbm_detail_each" v-if="this.orderDetail.orderstate === 3">订单状态：<span class="public_green">已成交</span></div>
        <div class="odbm_detail_each" v-if="this.orderDetail.orderstate === 9">订单状态：<span class="public_warning_color">已取消</span></div>

      </div>

      <div class="odbm_img_border">
        <img class="odbm_img" :src="this.staticFileURL + 'artworkImage/' + this.orderDetail.ezArtwork.imgurl.split('%2C')[0]" alt="">
      </div>


      <div class="odbm_pay" v-if="this.orderDetail.orderstate === 0">
        <div class="ml-2 public_gray f-l">账户余额：{{this.balanceShow}}&nbsp;CNY</div>
        <input id="pay_submit_btn" @click="payOrder" class="odbm_pay_btn public_green_btn pointer" type="button" value="支付" title="支付订单">
        <input @click="this.cancelTabFlag = true" class="odbm_cancel_btn public_submit_color pointer" type="button" value="取消" title="取消订单">
      </div>
      <div class="odbm_pay" v-if="this.orderDetail.orderstate === 2">
        <input @click="receiving(false)" class="odbm_pay_btn public_green_btn pointer" type="button" value="确认收货" title="确认收货">
      </div>
      <div class="odbm_pay" v-if="this.orderDetail.orderstate === 1 && this.userType === 1">
        <input @click="dispatch" class="odbm_pay_btn public_green_btn pointer" type="button" value="发货" title="发货">
      </div>
    </div>


    <div class="cancel_alert" v-show="this.cancelTabFlag">
      <div class="cancel_alert_body public_card_color">
        <div class="cab_title ta-c f-l">确定取消订单吗</div>
        <div @click="cancelOrderForm" class="cab_btn pointer" title="取消订单">确定</div>
        <div @click="this.cancelTabFlag = false" class="cab_btn public_submit_color pointer" title="关闭菜单">关闭</div>
      </div>
    </div>
    <div class="cancel_alert" v-show="this.receivingFlag">
      <div class="cancel_alert_body public_card_color">
        <div class="cab_title ta-c f-l">确认收货吗？</div>
        <div @click="this.receivingFlag = false" class="cab_btn pointer" title="关闭菜单">取消</div>
        <div @click="receiving(true)" class="cab_btn public_submit_color pointer" title="确认收货">确定</div>
      </div>
    </div>

    <successRedirect :sucRedirectURL="this.redirect" ref="successRedirect"/>



  </div>
</template>

<script>
import FlameNavBar from "@/components/Flame-NavBar";
import successRedirect from '@/components/successredirect'
import router from "@/router";
import request from "@/utils/request";
import * as $ from "jquery";

const numRenderReg = /(\d)(?=(?:\d{3})+$)/g;
export default {
  name: "orderdetail",
  components:{
    FlameNavBar,
    successRedirect,
  },
  data(){
    return{
      //ArtworkImgURL
      staticFileURL: this.$globalVar + '/files/',
      //用户类型
      userType:0,
      userID:localStorage.getItem('flame_user_uuid'),
      orderDetail:{
        ezArtwork: {
          imgurl:'',
          namecn:'',
        },
      },

      totalPriceShow:'',
      userBalance:'',
      balanceShow:'',
      //收货菜单flag
      receivingFlag:false,
      //取消菜单Flag
      cancelTabFlag:false,
      cancelUUID:'',
      //二次警告
      payButtonSecAlert: true,
      orderID : decodeURIComponent(router.currentRoute.value.query.orderID),
      redirect : decodeURIComponent(router.currentRoute.value.query.redirectUrl),
    }
  },
  mounted() {
    if (localStorage.getItem('flame_user_uuid') === null || localStorage.getItem('flame_user_uuid') === "") {
      window.open('/#/user/login?redirectUrl=' + encodeURIComponent(window.location.origin) + encodeURIComponent(window.location.pathname), '_self');
    } else {
      this.getUserType();
      this.getOrderDetail()
    }
  },
  methods:{
    getUserType(){
      request.get('user/getUserType',{params:{useruuid:this.userID}}).then(res=>{
        this.userType = res
      })
    },
    getOrderDetail(){
      request.get('artwork/server/getUserOrderDetail',
          {params:{
              uuid:this.orderID
            }}
      ).then(res=>{
        // console.log(res)
        this.orderDetail = res
        this.totalPriceShow = (this.orderDetail.ezArtwork.price + this.orderDetail.ezArtwork.pricetrans).toString().replace(numRenderReg, '$1, ');
        if(this.orderDetail.orderstate === 0){
          request.get('user/balance',
              {params:{
                  uuid:localStorage.getItem('flame_user_uuid')
                }}
          ).then(res=>{
            this.userBalance = res
            this.balanceShow =this.userBalance.toString().replace(numRenderReg, '$1, ');
          })
        }
      })
    },

    payOrder(){
      if (this.payButtonSecAlert) {
        this.paySecondAlert('pay_submit_btn')
      }
      if(this.userBalance<(this.orderDetail.ezArtwork.price + this.orderDetail.ezArtwork.pricetrans)){
        this.payButtonAlert(false,'余额不足')
      }else{
        request.get('artwork/store/payOrderEvent', {
          params: {
            userUUID: localStorage.getItem('flame_user_uuid'),
            orderUUID: this.orderDetail.oid,
            artworkUUID: this.orderDetail.ezArtwork.uuid,
            payFlag: true
          }
        }).then(res => {
          if (res.data === "200") {
            this.$refs.successRedirect.successRedirectTable('付款成功')
          } else if (res.data === "203") {
            this.payButtonAlert(false, '您不能购买自己的艺术品')
          } else if (res.data === "204") {
            this.payButtonAlert(false, '余额不足')
          } else if (res.data === "205") {
            this.payButtonAlert(false, '已售罄')
          } else {
            this.payButtonAlert(false, '请求错误')
          }
        })
      }
    },
    receiving(flag){
      if(flag){
        request.get('artwork/store/receiving', {
          params: {
            orderuuid: this.orderDetail.oid,
          }
        }).then(() => {

            this.$refs.successRedirect.successRedirectTable('收货成功')
        })
      }else{
        this.receivingFlag = true
      }
    },

    dispatch(){
      request.get('artwork/store/artworkDispatch', {
        params: {
          orderid: this.orderDetail.oid,
          artworkuuid: this.orderDetail.ezArtwork.uuid,
        }
      }).then(() => {
        this.$refs.successRedirect.successRedirectTable('发货成功')
      })
    },

    cancelOrderForm(){
      request.get('artwork/store/cancelOrder', {
        params: {
          orderuuid: this.orderDetail.oid,
        }
      }).then(res => {

        this.$refs.successRedirect.successRedirectTable('取消成功')

      })
    },


    payButtonAlert(Flag, tips) {
      if (Flag) {
        this.payButtonSecAlert = false
        $('#pay_submit_btn').removeClass('public_submit_Alert').addClass('public_green_btn')
        $('#pay_submit_btn').val('支付')
      } else {
        this.payButtonSecAlert = true
        $('#pay_submit_btn').removeClass('public_green_btn').addClass('public_submit_Alert')
        $('#pay_submit_btn').val(tips)
      }
    },
    paySecondAlert(BtnID) {
      $('#' + BtnID).addClass('public_submit_secAlert')
      setTimeout(() => {
        $("#" + BtnID).removeClass("public_submit_secAlert")
      }, 10);
    },

  }
}
</script>
<style src="@/style/FlameTheme.css" scoped/>
<style src="@/style/FlameDarkTheme.css" scoped/>
<style src="@/style/PublicStyle.css" scoped/>
<style scoped>
/*横屏 css*/
@media screen and (orientation: landscape) {
  .od_body{
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
  }
  .odb_main{
    position: relative;
    width: 970px;
    height: 100%;
    margin: 0 auto;
  }
  .od_line{
    height: 20px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    width: 90%;
    border-bottom: 1px solid #b6b6b6;
  }
  .odbm_title{
    position: relative;
    margin-top: 100px;
    font-size: 25px;
  }
  .odbm_detail_border{
    position: relative;
    float: left;
    width: 50%;
    margin-left: 100px;
    margin-top: 20px;
  }
  .odbm_detail_each{
    margin-top: 20px;
    font-size: 18px;
  }

  .odbm_img_border{
    position: relative;
    float: right;
    margin-top: 50px;
    box-shadow: 0px 0px 0px 10px #ffffff,0px 0px 0px 20px #a9a9a9;
    right: 100px;
    width: 200px;
    height: 200px;
  }
  .dark .odbm_img_border{
    box-shadow: 0px 0px 0px 10px #a9a9a9,0px 0px 0px 20px #1c1c1c;
  }
  .odbm_img{
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .odbm_pay{
    position: relative;
    float: left;
    margin-top: 150px;
    margin-left:70px;
    width: 100%;
    height: 60px;
  }
  .odbm_pay_btn{
    border: none;
    width: 300px;
    height: 50px;
    margin-left: 20px;
    margin-top: 10px;
  }
  .odbm_cancel_btn{
    border: none;
    width: 100px;
    height: 50px;
    margin-left: 20px;
    margin-top: 10px;
  }
  .cancel_alert{
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(28, 28, 28, 0.3);
    backdrop-filter: blur(20px);
    z-index: 500;
  }
  .cancel_alert_body{
    position: relative;
    border-radius: 5px;
    margin: 15% auto;
    width: 400px;
    height: 200px;
  }
  .cab_title{
    position: relative;
    top: 60px;
    font-size: 20px;
  }
  .cab_btn{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 5px;
    margin-top: 110px;
    margin-left: 70px;
    float: left;
    width: 100px;
    height: 40px;
  }
}

/*竖屏 css*/
@media screen and (orientation: portrait) {
  .od_body{
    overflow: hidden;
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
  }
  .odb_main{
    position: relative;
    width: 90%;
    height: 100%;
    margin: 0 auto;
  }
  .od_line{
    height: 20px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    width: 90%;
    border-bottom: 1px solid #b6b6b6;
  }
  .odbm_title{
    position: relative;
    margin-top: 100px;
    font-size: 25px;
  }
  .odbm_detail_border{
    position: relative;
    float: left;
    width: 100%;
    height: 150px;
    margin-left: 10%;
  }
  .odbm_detail_each{
    position: relative;
    margin-top: 10px;
    font-size: 12px;
  }

  .odbm_img_border{
    display: flex;
    position: relative;
    margin: 40px auto;
    top: 25px;
    box-shadow: 0px 0px 0px 5px #ffffff,0px 0px 0px 10px #a9a9a9;
    width: 150px;
    height: 150px;
  }
  .dark .odbm_img_border{
    box-shadow: 0px 0px 0px 5px #a9a9a9,0px 0px 0px 10px #1c1c1c;
  }
  .odbm_img{
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .odbm_pay{
    position: relative;
    float: left;
    margin-top: 20px;
    margin-left:10%;
    font-size: 10px;
    width: 100%;
    height: 50px;
  }
  .odbm_pay_btn{
    position: relative;
    border: none;
    width: 80%;
    height: 50px;
    margin: 10px auto;
  }
  .odbm_cancel_btn{
    position: relative;
    border: none;
    width: 80%;
    height: 50px;
    margin: 10px auto;
  }
  .cancel_alert{
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(28, 28, 28, 0.3);
    backdrop-filter: blur(20px);
    z-index: 9999;
  }
  .cancel_alert_body{
    position: relative;
    border-radius: 5px;
    margin: 50% auto;
    width: 80%;
    height: 200px;
  }
  .cab_title{
    position: relative;
    top: 60px;
    font-size: 15px;
  }
  .cab_btn{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 5px;
    margin-top: 110px;
    margin-left: 5%;
    float: left;
    width: 40%;
    height: 40px;
  }
}


</style>