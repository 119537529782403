<template>
  <div>
    <div class="title">
      <span class="p-r t-20 l-2 f-25 c-white f-b doNotSelect">面板 | Flame Administrator</span>
      <span class="admin_welcome_title doNotSelect p-r t-30 fl-r f-l">欢迎您，{{adminName}} 管理员</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdminHeader",
  data(){
    return{
      adminName:localStorage.getItem("flame-admin-name"),
    }
  },
  methods:{
  }
}
</script>

<style src="@/style/PublicStyle.css" scoped/>
<style scoped>
div {
  background-color: #3a3a3a;
}
h1 {
  color: white;
}
.title{
  color: white;
  position: fixed;
  width: 100%;
  left: 0px;
  top: 0px;
  height: 60px;
}
.admin_welcome_title {
  right: 20px;
}
</style>