<template>
  <div class="login_body">
    <FlameNavBar/>
    <div class="login_canvas-body public_bgc">
      <div class="canvas-container" id="canvas-container" ref="loginCanvas"></div>
    </div>

    <div class="lg_card public_card_color public_black doNotSelect">
      <div class="lgc_title f-b f-40">
        <span class="public_black">登录</span>
      </div>
      <div class="lgc_input">

        <div class="lgci_border public_gray_border public_input_bgc" id="user_email_border">
          <div class="lgcib_tilte f-l" id="user_email">电子邮件地址</div>
          <input class="public_black" @blur="checkUserInput('user_email',email)" @click="selectBorder('user_email')"
                 v-model="email"
                 type="text">
        </div>
        <div class="lgcib_alert public_warning_color f-10" id="user_email_alert"></div>

        <div class="lgci_border public_gray_border public_input_bgc" id="user_pwd_border">
          <div class="lgcib_tilte f-l" id="user_pwd">密码</div>
          <input class="public_black" @blur="checkUserInput('user_pwd',password)" @click="selectBorder('user_pwd')"
                 v-model="password" :type="pwdInputType">


          <div class="pwd_seeOrHide public_tips_backgroundColor" @click="pwdShoworHide">
            <svg t="1672201369757" class="pwd_seeOrHide_icon public_tips_iconFill" viewBox="0 0 1024 1024" version="1.1"
                 xmlns="http://www.w3.org/2000/svg" p-id="3580" width="21" height="21" v-if="isPwdShow">
              <path
                  d="M512 204.8C269.06624 204.8 40.96 358.68672 40.96 512s228.10624 307.2 471.04 307.2 471.04-153.88672 471.04-307.2-228.10624-307.2-471.04-307.2z"
                  p-id="3581"></path>
              <path
                  d="M512 337.92a174.08 174.08 0 1 0 0 348.16 174.08 174.08 0 0 0 0-348.16z m0 61.44a112.64 112.64 0 1 1 0 225.28 112.64 112.64 0 0 1 0-225.28z"
                  fill="#9d9d9d" p-id="3582"></path>
            </svg>

            <svg t="1672201563313" class="pwd_seeOrHide_icon public_tips_iconFill" viewBox="0 0 1024 1024" version="1.1"
                 xmlns="http://www.w3.org/2000/svg"
                 p-id="3728" width="21" height="21" v-if="!isPwdShow">
              <path
                  d="M983.04 512c0 153.31328-228.10624 307.2-471.04 307.2-90.3168 0-178.56512-21.25824-253.80864-55.86944l129.49504-129.47456a174.08 174.08 0 1 0 246.1696-246.1696l128.57344-128.57344C891.392 317.44 983.04 414.8224 983.04 512zM512 204.8c65.00352 0 128.96256 11.01824 187.76064 30.1056l-117.69856 117.69856a174.08 174.08 0 0 0-229.4784 229.4784l-151.18336 151.1424C105.02144 674.67264 40.96 593.26464 40.96 512c0-153.31328 228.10624-307.2 471.04-307.2z m112.64 307.2a112.64 112.64 0 0 1-193.49504 78.41792l159.27296-159.27296c21.0944 20.48 34.22208 49.13152 34.22208 80.85504zM512 399.36c7.2704 0 14.37696 0.69632 21.27872 2.00704l-131.91168 131.8912A112.64 112.64 0 0 1 512 399.36z"
                  p-id="3729"></path>
              <path
                  d="M872.796352 148.719008m21.72232 21.72232l0 0q21.72232 21.72232 0 43.444641l-680.632703 680.632703q-21.72232 21.72232-43.444641 0l0 0q-21.72232-21.72232 0-43.444641l680.632703-680.632703q21.72232-21.72232 43.444641 0Z"
                  p-id="3730"></path>
            </svg>
          </div>


        </div>
        <div class="lgcib_alert public_warning_color f-10" id="user_pwd_alert"></div>
        <span class="fgt_pwd"><a :href="originUrl + forgotPasswordUrl" class="public_noColor_link" title="找回密码">忘记密码</a></span>
      </div>
      <div class="lgc_submit">
        <input id="lgc_submit" class="public_submit_color pointer" type="button" value="登录" title="点击登录" @click="login">
      </div>
      <div class="reg_account f-l"><span>还没有账户？</span><a :href="originUrl + registerUrl" class="public_themeColor_link t-underline" title="注册">注册</a>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, onMounted} from "vue";
import * as THREE from "three";
import * as $ from "jquery";
import request from "@/utils/request";
import router from "@/router";
import {Moon, Sunny} from '@element-plus/icons-vue'
import FlameNavBar from '@/components/Flame-NavBar'

import {FontLoader} from 'three/examples/jsm/loaders/FontLoader.js';
import {SMAAPass} from 'three/examples/jsm/postprocessing/SMAAPass.js';
import {EffectComposer} from 'three/examples/jsm/postprocessing/EffectComposer.js';
import {RenderPass} from 'three/examples/jsm/postprocessing/RenderPass.js';

const originUrl = ref(window.location.origin);
const registerUrl = ref("/#/user/register?redirectUrl=" + encodeURIComponent(window.location.origin) + encodeURIComponent(window.location.pathname))
const forgotPasswordUrl = ref("/#/user/forgot-password?redirectUrl=" + encodeURIComponent(window.location.origin) + encodeURIComponent(window.location.pathname))
const redirect = ref(decodeURIComponent(router.currentRoute.value.query.redirectUrl));
let loginCanvas = ref(null);
let container, camera, scene, renderer, effect, composer, showTextColor1, showTextColor2;
let mouseX = 0;
let mouseY = 0;
let windowHalfX = window.innerWidth / 2;
let windowHalfY = window.innerHeight / 2;

let theme = false;
let email = "";
let password = "";
const pwdInputType = ref("password");
const isPwdShow = ref(false);

const emailLegal = ref(false);
const passwordLegal = ref(false);

const submitError = ref(true);

onMounted(() => {
  checkWebTheme()

  document.addEventListener('mousemove', onDocumentMouseMove);
  const scene = new THREE.Scene();
  const camera = new THREE.PerspectiveCamera(70, window.innerWidth / window.innerHeight, 0.1, 1000);
  const renderer = new THREE.WebGLRenderer({antialias: true, alpha: true});
  //设置画布自适应大小
  renderer.setSize($("#canvas-container").width(), $("#canvas-container").height());
  //生成画布
  loginCanvas.value.appendChild(renderer.domElement)
  //按照canvas的大小来调整camera的aspect和投影矩阵
  const canvas = renderer.domElement;
  camera.aspect = canvas.clientWidth / canvas.clientHeight;
  camera.updateProjectionMatrix();
  TextLoader('Flame', 0x656565)
  TextLoader('            Art', 0xFF8000)
  Render();

  function TextLoader(textbody, textcolor) {
    const loader = new FontLoader();
    loader.load('https://elysova.com:9090/files/webResource/helvetiker_bold', function (font) {
      const color = textcolor;
      const matLite = new THREE.MeshBasicMaterial({
        color: color,
        transparent: true,
        opacity: 0.5,
        side: THREE.DoubleSide
      });
      //显示的文字
      const message = textbody;
      const shapes = font.generateShapes(message, 100);
      const geometry = new THREE.ShapeGeometry(shapes);
      geometry.computeBoundingBox();
      geometry.translate(-450, -40, -200);
      // make shape ( N.B. edge view not visible )
      const text = new THREE.Mesh(geometry, matLite);
      text.position.z = -150;
      scene.add(text);
      // make line shape ( N.B. edge view remains visible )
      const holeShapes = [];
      for (let i = 0; i < shapes.length; i++) {
        const shape = shapes[i];
        if (shape.holes && shape.holes.length > 0) {
          for (let j = 0; j < shape.holes.length; j++) {
            const hole = shape.holes[j];
            holeShapes.push(hole);
          }
        }
      }
      shapes.push.apply(shapes, holeShapes);
    });
  }

  function onDocumentMouseMove(event) {
    mouseX = (event.clientX - windowHalfX) / 10;
    mouseY = (event.clientY - windowHalfY) / 10;
  }

  function Render() {
    //渲染场景
    composer = new EffectComposer(renderer);
    const renderScene = new RenderPass(scene, camera);
    const pass = new SMAAPass(window.innerWidth * renderer.getPixelRatio(), window.innerHeight * renderer.getPixelRatio());
    composer.addPass(pass);
    composer.addPass(renderScene);
    renderer.render(scene, camera);
    camera.position.x += (mouseX - camera.position.x) * 1 - 300;
    camera.position.y += (mouseY - camera.position.y) * 0.5;
    camera.position.z = 350
    camera.lookAt(scene.position);
    requestAnimationFrame(Render);
  }
})

function checkWebTheme() {
  if (localStorage.getItem("WebTheme") === 'dark') {
    $("#body").addClass("dark")
    theme = true
  } else {
    $("#body").removeClass("dark")
    theme = false
  }
}



//点击登录
function login() {
  if (emailLegal.value && passwordLegal.value) {
    submitError.value = true
  }
  if (!submitError.value) {
    // console.log('执行了动画')
    loginButtonSecAlert()
  }
  if (!emailLegal.value) {
    // console.log('邮箱错了')
    loginButtonAlert(false, "请检查邮箱")
    submitError.value = false
  } else if (!passwordLegal.value) {
    loginButtonAlert(false, "请检查密码")
    submitError.value = false
  } else {
    request.post("user/normalLogin", {'email': email, 'pwd': password})
        .then(res => {
              if(res.data.split(',')[2]==='0'){
                loginButtonAlert(false, "账号已被封禁")
                loginButtonSecAlert()
              }else{
                if(res.data.split(',')[0] === ""){
                  loginButtonAlert(false, "账号或密码错误")
                  loginButtonSecAlert()
                }else{
                  // 登录后
                  localStorage.setItem('flame_user_uuid',res.data.split(',')[0])
                  localStorage.setItem('flame_user_name',res.data.split(',')[1])
                  email = ""
                  password = ""
                  window.open(redirect.value,'_self')
                }
              }
        })
  }
}

//点击输入栏
function selectBorder(titleName) {
  loginButtonAlert(true, "登录")
  $('#' + titleName + '_alert').text('')
  $('#' + titleName + "_border").removeClass("border_Warning")
  $('#' + titleName).stop(true, false).animate({
    left: '15px',
    top: '5px',
    fontSize: '10px',
  }, 100)
}

//blur事件
function checkUserInput(titleName, inputValue) {
  if (titleName === "user_pwd") {
    if (inputValue === "") {
      $('#' + titleName).stop(true, false).animate({
        left: '20px',
        top: '20px',
        fontSize: '15px',
      }, 100)
      showAlertMsg(titleName, '必填')
      passwordLegal.value = false
    } else {
      passwordLegal.value = true
    }
  } else {
    if (inputValue === "") {
      $('#' + titleName).stop(true, false).animate({
        left: '20px',
        top: '20px',
        fontSize: '15px',
      }, 100)
      showAlertMsg(titleName, '必填')
      emailLegal.value = false
    } else {
      if (titleName === "user_email") {
        const emailCheckReg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;
        if (emailCheckReg.test(inputValue)) {
          request.post("user/checkEmail", {'email': inputValue})
              .then(res => {
                if (res) {
                  showAlertMsg(titleName, '电子邮件无效')
                  emailLegal.value = false
                } else {
                  emailLegal.value = true
                }
              })
        } else {
          showAlertMsg(titleName, '格式错误')
          emailLegal.value = false
        }
      }
    }
  }
}


function showAlertMsg(titleName, Msg) {
  $('#' + titleName + "_border").addClass("border_Warning")
  $('#' + titleName + '_alert').text(Msg)
}

function pwdShoworHide() {
  if (isPwdShow.value) {
    pwdInputType.value = "password"
  } else {
    pwdInputType.value = "text"
  }
  isPwdShow.value = !isPwdShow.value
}

function loginButtonAlert(Flag, tips) {
  if (Flag) {
    submitError.value = true
    $('#lgc_submit').removeClass('public_submit_Alert').addClass('public_submit_color')
    $('#lgc_submit').val('登录')
  } else {
    submitError.value = false
    $('#lgc_submit').removeClass('public_submit_color').addClass('public_submit_Alert')
    $('#lgc_submit').val(tips)
  }
}

function loginButtonSecAlert() {
  $('#lgc_submit').addClass('public_submit_secAlert')
  setTimeout(() => {
    $("#lgc_submit").removeClass("public_submit_secAlert")
  }, 10);
}

</script>

<style src="@/style/FlameTheme.css" scoped/>
<style src="@/style/FlameDarkTheme.css" scoped/>
<style src="@/style/PublicStyle.css" scoped/>
<style scoped>
.login_body {
  height: inherit;
}

.login_canvas-body {
  overflow: hidden;
}

.canvas-container {
  object-fit: cover;
}


.lg_card {
  position: absolute;
  width: 460px;
  height: 500px;
  float: right;
  border-radius: 10px;
  backdrop-filter: blur(5px);
}


.lgci_border {
  position: relative;
  border-radius: 5px;
}

.lgci_border input {
  position: absolute;
  border: none;
  background-color: #FFFFFF00;
  outline: none;
}

.lgcib_tilte {
  position: absolute;
  left: 20px;
  top: 20px;
  font-size: 15px;
}

.lgcib_alert {
  position: relative;
  float: left;
}


.lgc_submit input {
  border: none;
  padding-left: 10px;
  outline: none;
}


.pwd_seeOrHide {
  position: relative;
  border-radius: 5px;
  float: right;
}

.fgt_pwd {
  float: right;
  font-size: 14px;
}

.reg_account {
  text-align: center;
}

/*横屏 css*/
@media screen and (orientation: landscape) {
  .login_body {
    height: inherit;
  }

  .login_canvas-body {
    width: 100%;
    height: inherit;
  }

  .canvas-container {
    width: 100%;
    height: 100%;
  }

  .login_navbar {
    top: 0;
    width: 100%;
    height: 60px;
  }

  .lg_card {
    top: 20%;
    right: 15%;
    width: 460px;
    height: 500px;
  }

  .lgc_title {
    margin-left: 40px;
    margin-top: 40px;
  }

  .lgc_input {
    width: 365px;
    height: 200px;
    margin: 30px auto;
  }

  .lgci_border {
    margin-top: 40px;
    width: 100%;
    height: 60px;
  }


  .lgci_border input {
    padding-left: 10px;
    font-size: 15px;
    width: 70%;
    height: 50%;
    bottom: 5px;
    left: 5px;
  }

  .lgc_submit {
    width: 100%;
  }

  .lgc_submit input {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 365px;
    height: 40px;
    margin: 20px auto;
  }

  .pwd_seeOrHide {
    top: 15px;
    right: 10px;
    width: 32px;
    height: 32px;
  }

  .pwd_seeOrHide_icon {
    position: relative;
    left: 5px;
    top: 5px;
  }

  .fgt_pwd {
    margin-top: 10px;
  }

  .reg_account {
    margin-top: 20px;
  }

  .ThemeSwitch {
    float: right;
    position: absolute;
    top: 10px;
    right: 5%;
  }

  .ln_title {
    font-size: 25px;
    margin-top: 15px;
    margin-left: 50px;
    font-weight: 600;

  }
}

/*竖屏 css*/
@media screen and (orientation: portrait) {
  .login_body {
    height: inherit;
  }

  .login_canvas-body {
    width: 100%;
    height: inherit;
  }

  .canvas-container {
    width: 0%;
    height: 0%;
  }

  .login_navbar {
    top: 0;
    width: 100%;
    height: 60px;
  }

  .lg_card {
    top: 15%;
    right: 5%;
    width: 90%;
    height: 500px;
  }

  .lgc_title {
    margin-left: 5%;
    margin-top: 10%;
  }

  .lgc_input {
    width: 90%;
    height: 200px;
    margin: 30px auto;
  }

  .lgci_border {
    /*margin-top: 40px;*/
    width: 95%;
    height: 60px;
    margin: 40px auto;
  }

  .lgci_border input {
    padding-left: 10px;
    font-size: 15px;
    width: 70%;
    height: 50%;
    bottom: 5px;
    left: 5px;
  }

  .lgc_submit {
    width: 100%;
  }

  .lgc_submit input {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 85%;
    height: 40px;
    margin: 20px auto;
  }

  .lgcib_alert {
    top: -35px;
    left: 10px;
  }

  .pwd_seeOrHide {
    top: 15px;
    right: 10px;
    width: 32px;
    height: 32px;
  }

  .pwd_seeOrHide_icon {
    position: relative;
    left: 5px;
    top: 5px;
  }


  .fgt_pwd {
    margin-right: 5%;
    margin-top: -5%;
  }

  .reg_account {
    margin-top: 20px;
  }

  .ThemeSwitch {
    float: right;
    position: absolute;
    top: 15px;
    right: 5%;
  }

  .ln_title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    margin-top: 15px;
    margin-left: -10px;
    font-weight: 100;
  }
}


</style>