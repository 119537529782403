<template>
  <div class="a_searchArt_body">
    <div class="fl-l">
      <el-cascader
          ref="cascader"
          placeholder="类别"
          @change="changeFilter"
          :options="options"
          clearable
          filterable
          :show-all-levels="false"/>
    </div>
    <div class="fl-l ml-2">
      <el-input v-model="searchInput" placeholder="输入用户信息" @keydown.enter="searchUser">
        <template #prefix>
          <el-icon class="el-input__icon">
            <search/>
          </el-icon>
        </template>
      </el-input>
    </div>

    <el-table :data="this.usersMsg" stripe height="550" style="width: 100%;">
      <el-table-column type="expand">
        <template #default="scope">

          <el-descriptions
              class="margin-top"
              title="用户信息"
              :column="3"
              size="small"
              border
          >
            <el-descriptions-item width="120">
              <template #label>
                <div class="cell-item">
                  <el-icon>
                    <user/>
                  </el-icon>
                  用户头像
                </div>
              </template>
              <img style="height: 30px" :src="this.staticFileURL+ 'userAvatar/' + scope.row.avatar">
            </el-descriptions-item>
            <el-descriptions-item width="180">
              <template #label>
                <div class="cell-item">
                  <el-icon>
                    <iphone/>
                  </el-icon>
                  联系方式
                </div>
              </template>
              <span>{{scope.row.tel}}</span>
            </el-descriptions-item>
            <el-descriptions-item >
              <template #label>
                <div class="cell-item">
                  <el-icon>
                    <user/>
                  </el-icon>
                  UUID
                </div>
              </template>
              <span>{{scope.row.uuid}}</span>
            </el-descriptions-item>
            <el-descriptions-item>
              <template #label>
                <div class="cell-item">
                  <el-icon>
                    <user/>
                  </el-icon>
                  用户背景
                </div>
              </template>

              <img style="height: 30px" :src="this.staticFileURL+ 'userBackground/' + scope.row.bgimg">
            </el-descriptions-item>

            <el-descriptions-item>
              <template #label>
                <div class="cell-item">
                  <el-icon>
                    <office-building/>
                  </el-icon>
                  地址
                </div>
              </template>
              <span>{{scope.row.address}}</span>
            </el-descriptions-item>
          </el-descriptions>


        </template>
      </el-table-column>
      <el-table-column prop="nickname" label="昵称" width="290"/>
      <el-table-column prop="realname" label="真实姓名" width="280"/>
      <el-table-column prop="email" label="电子邮箱" width="250"/>
      <el-table-column prop="type" label="类型" width="150">
        <template #default="scope">
          <span v-if="scope.row.type === 1" style="color: #67C23A;font-weight: 600">艺术家</span>
          <span v-if="scope.row.type === 0" style="color: #409EFF;font-weight: 100">普通用户</span>
        </template>
      </el-table-column>
      <el-table-column label="状态" width="150">
        <template #default="scope">
          <span v-if="scope.row.state === 1" style="color: #67C23A;font-weight: 100">正常</span>
          <span v-if="scope.row.state === 0" style="color: #F56C6C;font-weight: 600">黑名单</span>
        </template>
      </el-table-column>

      <el-table-column label="操作" width="120">
        <template #default="scope">
          <el-popconfirm
              v-if="scope.row.state === 0"
              @confirm="deleteUser(scope.row.uuid,false)"
              title="确定移出黑名单吗">
            <template slot-scope="scope" #reference>
              <el-button size="small"
                         type="success">移出黑名单
              </el-button>
            </template>
          </el-popconfirm>
          <el-popconfirm
              v-else
              @confirm="deleteUser(scope.row.uuid,true)"
              title="确定加入黑名单吗">
            <template slot-scope="scope" #reference>
              <el-button size="small"
                         type="danger">加入黑名单
              </el-button>
            </template>
          </el-popconfirm>
        </template>


      </el-table-column>
    </el-table>
    <el-pagination
        v-model:current-page="this.pageNum"
        v-model:page-size="pageSize"
        :disabled="disabled"
        :background="background"
        layout="prev, pager, next"
        :total="this.pageLength"
        @current-change="changePage"
    />
  </div>
</template>

<script>
import request from "@/utils/request";
import {ElMessage} from "element-plus";
import {
  Search,
  Iphone,
  Location,
  OfficeBuilding,
  Tickets,
  User,
} from '@element-plus/icons-vue'

export default {
  name: "adminsearchusers",
  components: {
    Search,
    Iphone,
    Location,
    OfficeBuilding,
    Tickets,
    User,
  },
  data() {
    return {
      staticFileURL: this.$globalVar + '/files/',
      searchInput: '',
      usersMsg: [{
        address: "",
        avatar: "DefaultAvatar.png",
        balance: 0,
        bgimg: "DefaultBackground.jpg",
        email: "",
        fans: 0,
        followers: 0,
        id: '',
        introduce: "",
        nickname: "",
        pwd: "",
        realname: "",
        state: 1,
        tel: "",
        type: 0,
        uuid: "",
      }],


      options: [
        {
          value: 'type',
          label: '用户类型',
          children: [
            {
              value: '0',
              label: '普通用户',
            },{
              value: '1',
              label: '艺术家用户',
            }
          ],
        },{
          value: 'state',
          label: '用户状态',
          children: [
            {
              value: '1',
              label: '正常',
            },{
              value: '0',
              label: '黑名单',
            }
          ],
        }],

      pageFilter:'all',
      pageFlag:0,

      pageLength:0,
      pageNum:1,
    }
  },
  mounted() {
    this.getLength()
    this.getUsersMessage(1, 'all', 0);
  },
  methods: {
    getLength(){
      request.get('/private/admin/getUserLength').then(res=>{
        this.pageLength = res
      })
    },
    changePage(page){
      this.pageNum = page
      this.getUsersMessage(this.pageNum,this.pageFilter, this.pageFlag)
    },


    getUsersMessage(page, filter, flag) {
      request.get('/private/admin/getUser', {params: {page: page, filter: filter, flag: flag}}).then(res => {
        console.log(res)
        this.usersMsg = res
      })
    },

    deleteUser(id, flag) {
      request.get('/private/admin/deleteUser',{params:{useruuid:id,operationflag:flag}}).then(()=>{
        this.getUsersMessage(this.pageNum,this.pageFilter,this.pageFlag)
        ElMessage({
          message: '操作成功',
          type: 'success',
        })
      })

    },

    changeFilter() {
      let res = this.$refs['cascader'].getCheckedNodes()
      this.pageNum = 1
      if (res.length===0){
        this.pageFilter = 'all'
        this.getUsersMessage(this.pageNum,this.pageFilter,0)
      }else{
        this.pageFilter = res[0].pathValues[0]
        this.pageFlag =  res[0].data.value

        this.getUsersMessage(this.pageNum,this.pageFilter,this.pageFlag)
      }

    },
    searchUser() {
      this.pageNum = 1
      if(this.searchInput === ""){
        this.pageFilter = 'all'
        this.getUsersMessage(this.pageNum,this.pageFilter,0);
      }else{
        request.get('/private/admin/searchUser', {params: {page:this.pageNum,searchbody: this.searchInput}}).then(res => {
          this.usersMsg = res
        })
      }
    }

  }
}
</script>
<style src="@/style/PublicStyle.css" scoped/>
<style scoped>

</style>