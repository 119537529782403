<template>
  <div>
    <el-table :data="storeCarousel" height="580" style="width: 100%">
      <el-table-column label="轮播图" width="480" >
        <template #default="scope">
            <img style="width: 200px" :src="this.staticFileURL + 'carousel/' + scope.row.imgurl" alt="">
        </template>
      </el-table-column>
      <el-table-column label="操作" >
        <template #default="scope">
          <el-button @click="deleteCarousel(scope.row.id,scope.row.imgurl)" size="large" type="danger">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-upload
        class="upload-demo fl-l"
        :action="this.$globalVar+ '/files/carousel/upload'"
        multiple
        :show-file-list="false"
        :on-success="addCarousel"
    >
      <el-button size="large" type="primary" class="p-r" style="margin-top: 55px">添加轮播图
      </el-button>
    </el-upload>
  </div>
</template>

<script>
import request from "@/utils/request";
import {ElMessage} from "element-plus";

export default {
  name: "admincarousel",
  data(){
    return{
      staticFileURL: this.$globalVar + '/files/',

      storeCarousel:[],
    }
  },
  mounted() {
    this.getCarousel()
  },
  methods:{
    getCarousel(){
      request.get('/private/admin/getCarousel').then(res=>{
        this.storeCarousel = res
      })
    },
    addCarousel(response){
      request.get('/private/admin/addCarousel',{params:{url:response.data}}).then(()=>{
        this.getCarousel()
        ElMessage({
          message: '添加成功',
          type: 'success',
        })
      })
    },

    deleteCarousel(id,imgurl){
      request.post('files/carousel/' + imgurl + '/delete').then(res=>{
        request.get('/private/admin/deleteCarousel',{params:{targetid:id}}).then(()=>{
          this.getCarousel()
          ElMessage({
            message: '删除成功',
            type: 'success',
          })
        })
      })

    }
  }

}
</script>

<style scoped>

</style>