<template>
  <div class="adminMain_body">
    <el-container>
      <el-header><AdminHeader/></el-header>
      <el-container>
        <el-aside width="200px"><AdminTab/></el-aside>
        <el-main>
            <router-view/>
        </el-main>
      </el-container>
    </el-container>
  </div>

</template>

<script>
import AdminTab from "@/components/AdminTab";
import AdminHeader from "@/components/AdminHeader";
import router from "@/router";

export default {
  name: "adminmain",
  components: {
    AdminHeader,
    AdminTab,
  },
  data() {
    return{

    }
  },
  mounted() {
    if(localStorage.getItem('flame-admin-name') === ""){
      router.push('/private/admin-login')
    }
  },
  methods: {

  }
}
</script>
<style src="@/style/PublicStyle.css" scoped/>
<style scoped>
.adminMain_body{
  position: relative;
  overflow: hidden;
  background-color: #ffffff;
  width: 100%;
  height: 100%;
}

.el-main{
 /*padding: 0;*/
}
</style>