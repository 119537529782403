<template>
  <FlameNavBar/>
  <div class="order_body public_bgc public_black">
    <div class="ob_main public_card_color">
      <div class="obm_title doNotSelect">购买历史</div>

      <div class="ob_line"/>
      <div class="obm_btnColumn doNotSelect f-l f-15">
        <div @click="changeFilter(5,'all')" id="order_btn5" class="obm_title_btn store_art_card_color pointer">
          全部
        </div>
        <div @click="changeFilter(0,'notpay')" id="order_btn0" class="obm_title_btn store_art_card_color pointer">
          待付款
        </div>
        <div @click="changeFilter(1,'progress')" id="order_btn1" class="obm_title_btn store_art_card_color pointer">
          已付款
        </div>
        <div @click="changeFilter(2,'progress')" id="order_btn2" class="obm_title_btn store_art_card_color pointer">
          进行中
        </div>
        <div @click="changeFilter(3,'accomplish')" id="order_btn3" class="obm_title_btn store_art_card_color pointer">
          已完成
        </div>
        <div @click="changeFilter(9,'cancel')" id="order_btn9" class="obm_title_btn store_art_card_color pointer">
          已取消
        </div>
        <div v-if="this.userType===1" @click="changeFilter(10,'dispatch')" id="order_btn10"
             class="obm_title_btn store_art_card_color pointer">
          发货
        </div>
      </div>


      <div class="obm_table">

        <div class="obmt_head f-b">
          <div class="obmt_head_item obmthi_date">&nbsp;日期</div>
          <div class="obmt_head_item obmthi_id">订单ID</div>
          <div class="obmt_head_item obmthi_name">艺术品名</div>
          <div class="obmt_head_item obmthi_state" v-if="this.orderBtnFlag === 5">状态</div>
          <div class="obmt_head_item obmthi_state" v-if="this.orderBtnFlag !== 5">操作</div>
        </div>

        <div class="obmt_body f-l">

          <div class="obmtb_each" v-for="item in this.userOrderdata" :key="item.oid"
               @click="openOrderDetail(item.oid,'line')">
            <div class="obmt_head_item obmthi_date">&nbsp;{{ item.orderdate }}</div>
            <div class="obmt_head_item obmthi_id"><span class="doNotSelect">ID:&nbsp;</span>{{ item.oid }}</div>
            <div class="obmt_head_item obmthi_name">{{ item.ezArtwork.namecn }}</div>
            <div class="obmt_head_item obmthi_state public_warning_color f-b"
                 v-if="item.orderstate === 0 && this.orderBtnFlag === 5">未支付
            </div>
            <div class="obmt_head_item obmthi_state public_green f-b"
                 v-if="item.orderstate === 1 && this.orderBtnFlag === 5">已支付
            </div>
            <div class="obmt_head_item obmthi_state public_green f-b"
                 v-if="item.orderstate === 2 && this.orderBtnFlag === 5">订单进行中
            </div>
            <div class="obmt_head_item obmthi_state public_green f-b"
                 v-if="item.orderstate === 3 && this.orderBtnFlag === 5">已成交
            </div>
            <div class="obmt_head_item obmthi_state public_warning_color f-l"
                 v-if="item.orderstate === 9 && this.orderBtnFlag === 5">已取消
            </div>
            <div class="obmt_head_item obmthi_state obmthi_btn" v-if="item.orderstate === 0 && this.orderBtnFlag !== 5">
              <span @click="openOrderDetail(item.oid,'btn')" class="public_green_btn -ml-5 f-15 pointer"
                    title="支付">去支付</span>
              <span @click="cancelOrder(item.oid)" class="public_noColor_link ml-5 f-15 pointer" title="取消订单">取消</span>
            </div>
            <div class="obmt_head_item obmthi_state obmthi_btn"
                 v-if="(item.orderstate === 1 || item.orderstate === 2 || item.orderstate === 3 || item.orderstate === 9) && this.orderBtnFlag !== 5">
              <span @click="openOrderDetail(item.oid,'btn')" class="public_submit_color f-15 pointer"
                    title="查看订单">查看</span>
            </div>

          </div>


        </div>

        <div class="ta-c mt-10" v-if="this.listIsNull">
          无
        </div>


      </div>
      <div class="obmtb_pageBtn doNotSelect">
        <div id="toPrevious" class="obmtb_pageBtn_each public_noColor_link pointer" @click="changePage(true)"
             title="上一页">上一页
        </div>
        <div class="obmtb_pageBtn_each">{{ this.orderPage }}</div>
        <div id="toNext" class="obmtb_pageBtn_each public_noColor_link pointer" @click="changePage(false)" title="下一页">
          下一页
        </div>
      </div>

    </div>


    <div class="cancel_alert" v-show="this.cancelTabFlag">
      <div class="cancel_alert_body public_card_color">
        <div class="cab_title ta-c f-l">确定取消订单吗</div>
        <div @click="cancelOrderForm" class="cab_btn pointer" title="取消订单">确定</div>
        <div @click="this.cancelTabFlag = false" class="cab_btn public_submit_color pointer" title="关闭菜单">关闭</div>
      </div>
    </div>

    <successRedirect :sucRedirectURL="'My-Order?requestID='+this.userID" ref="successRedirect"/>


  </div>
</template>

<script>
import FlameNavBar from "@/components/Flame-NavBar";
import successRedirect from '@/components/successredirect'
import router from "@/router";
import request from "@/utils/request";
import * as $ from "jquery";
import FlameFooter from "@/components/Flame-Footer";

export default {
  name: "userorder",
  components: {
    FlameFooter,
    FlameNavBar,
    successRedirect,
  },
  data() {
    return {
      userID: localStorage.getItem('flame_user_uuid'),
      //用户类型
      userType: 0,
      userOrderdata: [{
        ezArtwork: {},
      }],
      // tableShowFlag:1,
      orderBtnFlag: 5,
      orderPage: 1,
      orderIsNull: false,

      //为空
      listIsNull: false,

      //取消菜单Flag
      cancelTabFlag: false,
      cancelUUID: '',
    }
  },
  mounted() {
    if (localStorage.getItem('flame_user_uuid') === null || localStorage.getItem('flame_user_uuid') === "") {
      window.open('/#/user/login?redirectUrl=' + encodeURIComponent(window.location.origin) + encodeURIComponent(window.location.pathname), '_self');
    } else {
      this.getUserType();
      this.getUserOrder();
    }
  },
  methods: {
    getUserType() {
      request.get('user/getUserType', {params: {useruuid: this.userID}}).then(res => {
        this.userType = res
      })
    },
    openOrderDetail(id, btnType) {
      if (this.orderBtnFlag === 5 && btnType === 'line') {
        window.open('/#/account/Order-Message?redirectUrl=' + encodeURIComponent(window.location.origin) + encodeURIComponent(window.location.pathname) + encodeURIComponent(window.location.search) + '&orderID=' + encodeURIComponent(id), '_self')
      } else if (btnType === 'btn') {
        window.open('/#/account/Order-Message?redirectUrl=' + encodeURIComponent(window.location.origin) + encodeURIComponent(window.location.pathname) + encodeURIComponent(window.location.search) + '&orderID=' + encodeURIComponent(id), '_self')
      }
    },
    getUserOrder() {
      $('#order_btn' + this.orderBtnFlag).removeClass('store_art_card_color').addClass('orderBtn_choice')
      request.get('artwork/server/getUserOrderFormFilter',
          {
            params: {
              uuid: localStorage.getItem('flame_user_uuid'),
              filterFlag: 5,
              orderPage: 1
            }
          }).then(res => {
        this.userOrderdata = res
        if (this.userOrderdata.length === 0) {
          this.listIsNull = true
        } else {
          this.listIsNull = false
          if (this.userOrderdata.length < 6) {
            this.orderIsNull = true
          } else {
            this.orderIsNull = false
          }
        }
      })
    },
    getUserOrderFilter(filterFlag) {
      request.get('artwork/server/getUserOrderFormFilter', {
        params: {
          uuid: localStorage.getItem('flame_user_uuid'),
          filterFlag: filterFlag,
          orderPage: this.orderPage
        }
      }).then(res => {
        this.userOrderdata = res
        this.listIsNull = false
        if (this.userOrderdata.length < 6) {
          this.orderIsNull = true
          if (this.userOrderdata.length === 0) {
            if(this.orderPage !==0){
              this.orderIsNull = true
            }else{
              this.listIsNull = true
            }
          } else {
            this.listIsNull = false
          }
        }
      })
    },
    changePage(flag) {
      if (!this.listIsNull) {
        console.log(this.orderIsNull)
        if (this.orderIsNull) {
          if (flag) {
            if (this.orderPage !== 1) {
              this.orderPage = this.orderPage - 1
              this.orderIsNull = false
              this.getUserOrderFilter(this.orderBtnFlag)
            }
          }
        } else {
          if (flag) {
            if (this.orderPage > 1) {
              this.orderPage = this.orderPage - 1
              this.getUserOrderFilter(this.orderBtnFlag)
            }
          } else {
            this.orderPage = this.orderPage + 1
            this.getUserOrderFilter(this.orderBtnFlag)
          }
        }
      }


    },
    cancelOrderForm() {
      request.get('artwork/store/cancelOrder', {
        params: {
          orderuuid: this.cancelUUID,
        }
      }).then(res => {

        this.$refs.successRedirect.successRedirectTable('取消成功')

      })
    },
    cancelOrder(orderUUID) {
      this.cancelTabFlag = true
      this.cancelUUID = orderUUID
    },

    changeFilter(btnID, FilterType) {
      this.orderPage = 1
      this.orderIsNull = false
      this.changeFilterBtnStyle(btnID);
      this.tableShowFlag = btnID
    },
    changeFilterBtnStyle(btnID) {

      $('#order_btn' + this.orderBtnFlag).removeClass('orderBtn_choice').addClass('store_art_card_color')
      this.orderBtnFlag = btnID
      this.getUserOrderFilter(btnID)
      $('#order_btn' + btnID).removeClass('store_art_card_color').addClass('orderBtn_choice')
    }


  },


}
</script>

<style src="@/style/FlameTheme.css" scoped/>
<style src="@/style/FlameDarkTheme.css" scoped/>
<style src="@/style/PublicStyle.css" scoped/>
<style scoped>
/*横屏*/
@media screen and (orientation: landscape) {
  .order_body {
    display: flex;
    position: relative;
    /*overflow: hidden;*/
    width: 100%;
    height: 100%;
  }

  .ob_main {
    position: relative;
    width: 1370px;
    height: 85%;
    margin: 5% auto;
  }

  .ob_line {
    height: 20px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    width: 90%;
    border-bottom: 1px solid #b6b6b6;
  }

  .obm_title {
    position: relative;
    margin-top: 20px;
    margin-left: 100px;
    font-size: 25px;
  }

  .obm_btnColumn {
    position: relative;
    margin-left: 80px;
  }

  .obm_title_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    float: left;
    width: 60px;
    height: 30px;
    margin-left: 20px;
    border-radius: 5px;
  }

  .obm_table {
    position: relative;
    width: 90%;
    margin: 60px auto;
  }

  .obmt_head {
    /*background-color: red;*/
    width: 100%;
    height: 50px;
  }

  .obmt_head_item {
    display: flex;
    align-items: center;
    position: relative;
    float: left;
    height: 100%;
    border-bottom: 1px solid #939393;
  }

  .obmthi_btn span {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    /*background-color: red;*/
    width: 60px;
    height: 30px;
    z-index: 10;
  }

  .obmthi_date {
    width: 15%;
  }

  .obmthi_id {
    width: 30%;
  }

  .obmthi_name {
    width: 45%;
  }

  .obmthi_state {
    width: 10%;
  }

  .obmt_body {
    overflow: hidden;
    /*background-color: #850000;*/
    width: 100%;
    height: auto;
  }

  .obmtb_each {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #939393;
  }

  .obmtb_each:hover {
    animation: obmtb_eachAnimL 0.5s;
    background-color: #e3e3e3;
  }

  @keyframes obmtb_eachAnimL {
    from {
      background-color: #FFFFFF00;
    }
    to {
      background-color: #e3e3e3;
    }
  }

  .dark .obmtb_each:hover {
    animation: obmtb_eachAnimD 0.5s;
    background-color: #292929;
  }

  @keyframes obmtb_eachAnimD {
    from {
      background-color: #FFFFFF00;
    }
    to {
      background-color: #292929;
    }
  }


  .orderBtn_choice {
    background-color: #FF8000;
  }

  .obmtb_pageBtn {
    position: absolute;
    left: 50px;
    bottom: 20px;
  }

  .obmtb_pageBtn_each {
    float: left;
    margin-left: 20px;
  }

  .cancel_alert {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(28, 28, 28, 0.3);
    backdrop-filter: blur(20px);
    z-index: 500;
  }

  .cancel_alert_body {
    position: relative;
    border-radius: 5px;
    margin: 15% auto;
    width: 400px;
    height: 200px;
  }

  .cab_title {
    position: relative;
    top: 60px;
    font-size: 20px;
  }

  .cab_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 5px;
    margin-top: 110px;
    margin-left: 70px;
    float: left;
    width: 100px;
    height: 40px;
  }

}

/*竖屏 css*/
@media screen and (orientation: portrait) {
  .order_body {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
  }

  .ob_main {
    position: relative;
    width: 90%;
    height: 85%;
    margin: 75px auto;
  }

  .ob_line {
    height: 20px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    width: 90%;
    border-bottom: 1px solid #b6b6b6;
  }

  .obm_title {
    position: relative;
    margin-top: 20px;
    margin-left: 5%;
    font-size: 25px;
  }

  .obm_btnColumn {
    position: relative;
    margin-left: 5%;
    height: 65px;
    width: 90%;
  }

  .obm_title_btn {
    transform: scale(0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    float: left;
    width: 50px;
    height: 25px;
    font-size: 10px;
    margin-top: 5px;
    border-radius: 5px;
  }

  .obm_table {
    position: relative;
    width: 90%;
    margin: 0 auto;
  }

  .obmt_head {
    position: relative;
    width: 100%;
    height: 30px;
  }

  .obmt_head_item {
    display: flex;
    align-items: center;
    float: left;
    height: 100%;
    font-size: 10px;
    border-bottom: 1px solid #939393;
  }

  .obmthi_btn span {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 5px;
    width: 60px;
    height: 30px;
    font-size: 10px;
    z-index: 10;
  }

  .obmthi_date {
    width: 0;
    opacity: 0;
  }

  .obmthi_id {
    width: 0;
    opacity: 0;
  }

  .obmthi_name {
    width: 70%;
  }

  .obmthi_state {
    width: 30%;
  }

  .obmt_body {
    overflow: hidden;
    width: 100%;
    height: auto;
  }

  .obmtb_each {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #939393;

  }

  .obmtb_each:hover {
    animation: obmtb_eachAnimL 0.5s;
    background-color: #e3e3e3;
  }

  @keyframes obmtb_eachAnimL {
    from {
      background-color: #FFFFFF00;
    }
    to {
      background-color: #e3e3e3;
    }
  }

  .dark .obmtb_each:hover {
    animation: obmtb_eachAnimD 0.5s;
    background-color: #292929;
  }

  @keyframes obmtb_eachAnimD {
    from {
      background-color: #FFFFFF00;
    }
    to {
      background-color: #292929;
    }
  }


  .orderBtn_choice {
    background-color: #FF8000;
  }

  .obmtb_pageBtn {
    position: absolute;
    bottom: 20px;
  }

  .obmtb_pageBtn_each {
    float: left;
    margin-left: 20px;
  }

  .cancel_alert {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(28, 28, 28, 0.3);
    backdrop-filter: blur(20px);
    z-index: 500;
  }

  .cancel_alert_body {
    position: relative;
    border-radius: 5px;
    margin: 15% auto;
    width: 400px;
    height: 200px;
  }

  .cab_title {
    position: relative;
    top: 60px;
    font-size: 20px;
  }

  .cab_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 5px;
    margin-top: 110px;
    margin-left: 70px;
    float: left;
    width: 100px;
    height: 40px;
  }

}


</style>