<template>
  <FlameNavBar/>
  <transition name="fade">
    <div class="cancel_alert" v-show="this.cancelTabFlag">
      <div class="cancel_alert_body public_card_color public_black">
        <div class="cab_title ta-c f-l">您不能关注自己</div>
        <div @click="this.cancelTabFlag = false" class="cab_btn pointer" title="关闭">确定</div>
        <div @click="this.cancelTabFlag = false" class="cab_btn public_submit_color pointer" title="关闭">关闭</div>
      </div>
    </div>
  </transition>
  <div class="artist_body public_bgc section">
    <div class="ab_head doNotSelect">
      <div class="ab_background">
        <div class="abb_blackFilter"/>
        <img class="ab_img" :src="this.staticFileURL + 'userBackground/' + this.artistMsg.bgimg" alt="">
      </div>
      <div class="ab_avatar_border">
        <div class="ab_avatar">
          <img class="ab_img" :src="this.staticFileURL + 'userAvatar/' + this.artistMsg.avatar" alt="">
        </div>
        <div id="abf_btn" class="ab_followbtn pointer" @click="followBtn" :title="this.followBtnTitle">
          <svg v-if="this.isFollow" t="1673689350735" class="icon" viewBox="0 0 1024 1024" version="1.1"
               xmlns="http://www.w3.org/2000/svg"
               p-id="2694" width="20" height="20">
            <path
                d="M469.333333 469.333333V170.666667h85.333334v298.666666h298.666666v85.333334h-298.666666v298.666666h-85.333334v-298.666666H170.666667v-85.333334h298.666666z"
                fill="#444444" p-id="2695"></path>
          </svg>
          <svg v-if="!this.isFollow" t="1673753725656" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
               p-id="2678" width="20" height="20">
            <path
                d="M892.064 261.888a31.936 31.936 0 0 0-45.216 1.472L421.664 717.248l-220.448-185.216a32 32 0 1 0-41.152 48.992l243.648 204.704a31.872 31.872 0 0 0 20.576 7.488 31.808 31.808 0 0 0 23.36-10.112L893.536 307.136a32 32 0 0 0-1.472-45.248z"
                fill="#52961C" p-id="2679"></path>
          </svg>
        </div>
      </div>


      <div class="abh_nickname ta-c f-b">{{ this.artistName }}</div>

      <div class="abh_fansFollow ta-c f-l">
        <div class="abhf_each abhf-line">
          <div>粉丝</div>
          <div>{{ this.artistMsg.fans }}</div>
        </div>

        <div class="abhf_each">
          <div>关注</div>
          <div>{{ this.artistMsg.followers }}</div>
        </div>

      </div>
    </div>

    <div class="ab_body public_black">
      <div class="abb_introduce ta-c f-l">{{ this.artistMsg.introduce }}</div>

      <div class="abb_line"/>
      <div>
        <!--        艺术卡片-->
        <div class="art_each-card store_art_card_color" v-for="item in this.artistArtwork" :key="item.uuid">
          <a class="art_each-card_a public_black" :href="this.artworkDetailUrl + item.uuid">
            <div class="art_img_border">
              <img class="art_img" :src="this.staticFileURL + 'artworkImage/' + item.imgurl.split('%2C')[0]" alt="">
            </div>
            <div class="art_title f-b f-20">{{ item.namecn }}</div>
            <div class="art_nickname public_black f-l f-10">@{{ item.nickname }}</div>
            <div class="art_price f-25 f-b public_Flame_themeColor">{{ item.price }}&nbsp;<span
                class="public_gray f-l f-15">CNY</span></div>
          </a>
        </div>
      </div>

    </div>
    <FlameFooter/>
  </div>
</template>

<script>
import router from "@/router";
import * as $ from "jquery";
import FlameNavBar from '@/components/Flame-NavBar'
import request from "@/utils/request";
import FlameFooter from "@/components/Flame-Footer";

export default {
  name: "artisthomepage",
  components: {
    FlameFooter,
    FlameNavBar,
  },
  data() {
    return {
      //staticUrl
      staticFileURL: this.$globalVar + '/files/',
      //跳转链接
      artworkDetailUrl: "/#/store/commodity?redirectUrl=" + encodeURIComponent(window.location.origin) + encodeURIComponent(window.location.pathname) + "&artworkID=",
      //艺术家信息
      artistMsg: {
        uuid: '',
        introduce: '',
        avatar: '',
        bgimg: '',
        fans: 0,
        followers: 0,
      },
      artistArtwork: [{
        imgurl: ''
      }],
      isFollow: false,
      followBtnTitle: '',
      cancelTabFlag:false,
      //urlID
      artistName: decodeURIComponent(router.currentRoute.value.query.artistName),
    }
  },
  mounted() {
    this.getArtistMsg()
  },
  methods: {
    getArtistMsg() {
      request.get('user/getArtistMsg', {
        params: {
          artistname: this.artistName
        }
      }).then(res => {
        this.artistMsg = res
        // console.log(res)

        request.get('artwork/store/getUserPublishArtwork', {params: {userUUID: this.artistMsg.uuid}}).then(res => {
          // console.log(res)
          this.artistArtwork = res

          request.get('user/checkFollow', {
            params: {
              useruuid: localStorage.getItem('flame_user_uuid'),
              artistuuid: this.artistMsg.uuid,
            }
          }).then(res => {
            // console.log(res)
            this.isFollow = res
            if (this.isFollow) {
              this.followBtnTitle = '关注'
            } else {
              this.followBtnTitle = '取消关注'
            }

          })

        })

      })
    },

    // public void follow(String useruuid,String artistuuid,Boolean followflag){
    followBtn() {
      if (localStorage.getItem('flame_user_uuid') === null || localStorage.getItem('flame_user_uuid') === "") {
        window.open('/user/login?redirectUrl=' + encodeURIComponent(window.location.origin) + encodeURIComponent(window.location.pathname), '_self');
      } else {
        if(this.artistMsg.uuid === localStorage.getItem('flame_user_uuid')){
          this.cancelTabFlag = true
        }else{
          this.cancelTabFlag = false
          request.get('user/follow',
              {
                params: {
                  useruuid: localStorage.getItem('flame_user_uuid'),
                  artistuuid: this.artistMsg.uuid,
                  followflag: this.isFollow
                }
              }).then(() => {
            this.isFollow = !this.isFollow
            if (this.isFollow) {
              this.followBtnTitle = '关注'
              this.artistMsg.fans = this.artistMsg.fans - 1
            } else {
              this.followBtnTitle = '取消关注'
              this.artistMsg.fans = this.artistMsg.fans + 1
            }
          })
        }
      }


    },


  }
}
</script>
<style src="@/style/FlameTheme.css" scoped/>
<style src="@/style/FlameDarkTheme.css" scoped/>
<style src="@/style/PublicStyle.css" scoped/>
<style scoped>
@media screen and (orientation: landscape) {
  .artist_body {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
  }

  .abb_line {
    position: relative;
    height: 20px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    width: 90%;
    border-bottom: 1px solid #b6b6b6;
  }

  .ab_head {
    color: #F0F0F0;
    position: relative;
    width: 100%;
    height: 350px;
  }

  .ab_background {
    position: relative;
    margin-top: 52px;
    width: 100%;
    height: 100%;
  }

  .abb_blackFilter {
    position: absolute;
    background-color: rgba(28, 28, 28, 0.5);
    width: 100%;
    height: 100%;
  }

  .ab_img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .ab_avatar_border {
    position: relative;
    width: 200px;
    height: 130px;
    margin: -300px auto;
  }

  .ab_avatar {
    overflow: hidden;
    position: relative;
    background-color: #F0F0F0;
    margin: -300px auto;
    width: 120px;
    height: 120px;
    border-radius: 100%;
    border: 3px solid #FF8000;
    box-shadow: 0 0 10px 2px #1c1c1c;
  }

  .ab_followbtn {
    display: flex;
    position: relative;
    margin-top: 255px;
    margin-left: 130px;
    width: 35px;
    height: 35px;
    border-radius: 100%;
    background-color: #F0F0F0;
  }

  .ab_followbtn .icon {
    position: relative;
    align-self: center;
    margin: 0 auto;

  }

  .abh_nickname {
    position: relative;
    font-size: 30px;
    margin-top: 320px;
  }

  .abh_fansFollow {
    position: relative;
    font-size: 15px;
    margin: 20px auto;
    width: 200px;
    height: 50px;
  }

  .abhf-line {
    border-right: 1px solid #F0F0F0;
  }

  .abhf_each {
    position: relative;
    width: 98px;
    height: 50px;
    float: left;
  }

  .abhf_each div {
    margin-top: 5px;
  }

  .ab_body {
    position: relative;
    width: 1170px;
    margin: 0 auto;
  }


  .abb_introduce {
    position: relative;
    padding-top: 20px;
    margin: 0 auto;
    width: 50%;
    height: 100px;
  }
  .cancel_alert{
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(28, 28, 28, 0.3);
    backdrop-filter: blur(20px);
    z-index: 500;
  }
  .cancel_alert_body{
    position: relative;
    border-radius: 5px;
    margin: 15% auto;
    width: 400px;
    height: 200px;
  }
  .cab_title{
    position: relative;
    top: 60px;
    font-size: 20px;
  }
  .cab_btn{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 5px;
    margin-top: 110px;
    margin-left: 70px;
    float: left;
    width: 100px;
    height: 40px;
  }


  /*艺术品卡片*/
  .art_each-card {
    position: relative;
    display: flex;
    border-radius: 10px;
    overflow: hidden;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 42px;
    float: left;
    width: 240px;
    height: 300px;
  }

  .art_each-card_a {
    display: block;
    width: 100%;
    height: 100%;
  }

  .art_img_border {
    position: relative;
    width: 100%;
    height: 180px;
  }

  .art_img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .art_title {
    position: relative;
    top: 10px;
    left: 10px;
  }

  .art_nickname {
    position: relative;
    top: 15px;
    left: 10px;
  }

  .art_price {
    position: relative;
    top: 40px;
    left: 10px;
  }
}


/*竖屏 css*/
@media screen and (orientation: portrait) {
  .artist_body {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
  }

  .abb_line {
    position: relative;
    height: 20px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    width: 90%;
    border-bottom: 1px solid #b6b6b6;
  }

  .ab_head {
    color: #F0F0F0;
    position: relative;
    width: 100%;
    height: 350px;
  }

  .ab_background {
    position: relative;
    margin-top: 52px;
    width: 100%;
    height: 100%;
  }

  .abb_blackFilter {
    position: absolute;
    background-color: rgba(28, 28, 28, 0.5);
    width: 100%;
    height: 100%;
  }

  .ab_img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .ab_avatar_border {
    position: relative;
    width: 200px;
    height: 130px;
    margin: -300px auto;
  }

  .ab_avatar {
    overflow: hidden;
    position: relative;
    background-color: #F0F0F0;
    margin: -300px auto;
    width: 120px;
    height: 120px;
    border-radius: 100%;
    border: 3px solid #FF8000;
    box-shadow: 0 0 10px 2px #1c1c1c;
  }

  .ab_followbtn {
    display: flex;
    position: relative;
    margin-top: 255px;
    margin-left: 130px;
    width: 35px;
    height: 35px;
    border-radius: 100%;
    background-color: #F0F0F0;
  }

  .ab_followbtn .icon {
    position: relative;
    align-self: center;
    margin: 0 auto;

  }

  .abh_nickname {
    position: relative;
    font-size: 25px;
    margin-top: 320px;
  }

  .abh_fansFollow {
    position: relative;
    font-size: 15px;
    margin: 20px auto;
    width: 200px;
    height: 50px;
  }

  .abhf-line {
    border-right: 1px solid #F0F0F0;
  }

  .abhf_each {
    position: relative;
    width: 98px;
    height: 50px;
    float: left;
  }

  .abhf_each div {
    margin-top: 5px;
  }

  .ab_body {
    position: relative;
    width: 90%;
    margin: 0 auto;
  }


  .abb_introduce {
    position: relative;
    padding-top: 20px;
    margin: 0 auto;
    width: 50%;
    height: 100px;
    font-size: 10px;
  }
  .cancel_alert{
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(28, 28, 28, 0.3);
    backdrop-filter: blur(20px);
    z-index: 500;
  }
  .cancel_alert_body{
    position: relative;
    border-radius: 5px;
    margin: 15% auto;
    width: 400px;
    height: 200px;
  }
  .cab_title{
    position: relative;
    top: 60px;
    font-size: 20px;
  }
  .cab_btn{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 5px;
    margin-top: 110px;
    margin-left: 70px;
    float: left;
    width: 100px;
    height: 40px;
  }



  /*艺术品卡片*/
  .art_each-card {
    display: flex;
    border-radius: 10px;
    overflow: hidden;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 7%;
    float: left;
    width: 40%;
    height: 200px;
  }

  .art_each-card_a {
    display: block;
    width: 100%;
    height: 100%;
  }

  .art_img_border {
    position: relative;
    width: 100%;
    height: 100px;
  }

  .art_img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .art_title {
    position: relative;
    width: 90%;
    top: 10px;
    left: 10px;
    font-size: 15px;
  }

  .art_nickname {
    position: relative;
    top: 15px;
    left: 10px;
    font-size: 10px;
  }

  .art_price {
    position: relative;
    top: 10%;
    left: 10px;
    font-size: 15px;
  }

  /*结束*/
}


</style>