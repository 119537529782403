<template>
  <div class="successredirect_body doNotSelect" v-show="this.successRedirect">
    <div class="srb_card public_card_color public_black">
      <div class="srbc_title">
        <div class="srbct_tips f-40 f-b p-r ta-c">{{ this.successTipsTitle }}</div>
        <div class="srbct_tips f-25 f-l p-r ta-c mt-5">正在自动跳转</div>
      </div>
      <div class="srbc_circle">
        <el-progress class="srbc_circle_body" type="circle" :percentage="this.successRedirectTabPercent"
                     status="success"
                     :width="150">
          <div class="f-30">{{ this.successRedirectTimes }}</div>
        </el-progress>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "successredirect",
  props: ['sucRedirectURL'],
  data() {
    return {
      successTipsTitle: '',
      successRedirect: false,
      successRedirectTimes: 3,
      successRedirectTabPercent: 0,
    }
  },
  mounted() {

  },
  methods: {
    successRedirectTable(successTipsTitle) {
      this.successRedirect = true
      this.successTipsTitle = successTipsTitle
      this.successRedirectCircle(this.sucRedirectURL)
      this.successRedirectCirclePercent()
    },
    successRedirectCircle() {
      if (this.successRedirectTimes > 0) {
        this.successRedirectTimes--;
        setTimeout(this.successRedirectCircle, 1000);
      } else {
        this.successRedirect = false
        if(this.sucRedirectURL === 'refresh'){
          window.location.reload();
        }else{
          window.open(this.sucRedirectURL, '_self');
        }

      }
    },
    successRedirectCirclePercent() {
      if (this.successRedirectTabPercent < 100) {
        this.successRedirectTabPercent++;
        setTimeout(this.successRedirectCirclePercent, 15);
      } else {
        this.successRedirectTabPercent = 100;
      }
    },
  }
}
</script>

<style src="@/style/FlameTheme.css" scoped/>
<style src="@/style/FlameDarkTheme.css" scoped/>
<style src="@/style/PublicStyle.css" scoped/>
<style scoped>
.successredirect_body {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(30px);
  z-index: 9999;
}

.srb_card {
  position: relative;
  margin: 0 auto;
}

@media screen and (orientation: landscape) {
  .srb_card {
    margin-top: 120px;
    width: 400px;
    height: 500px;
  }

  .srbc_title {
    width: 100%;
    height: 40%;
  }

  .srbct_tips {
    top: 50%;
  }

  .srbc_circle {
    width: 100%;
  }

  .srbc_circle_body {
    display: flex;
    top: 80px;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
}

@media screen and (orientation: portrait) {
  .srb_card {
    margin-top: 120px;
    width: 80%;
    height: 500px;
  }

  .srbc_title {
    width: 100%;
    height: 40%;
  }

  .srbct_tips {
    top: 50%;
  }

  .srbc_circle {
    width: 100%;
  }

  .srbc_circle_body {
    display: flex;
    top: 80px;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
}
</style>