<template>
  <FlameNavBar/>
  <div class="artcanvas_body public_bgc public_black">
    <div class="artcanvas_main">
      <FlameArtCanvas
        class="max_art_Canvas"
        width="1300px"
        height="660px"
        modeltype="glb"
        :background="this.backgroundValue"
        :blur="this.blurValue"
        :exposure="this.exposureValue"
        :filter = "this.canvasFilter"
        ref="publishCanvas"
      />

      <div class="artcanvas_menu_mini public_card_color">
        <div class="amm_slider">
          <div class="f-l">背景模糊</div>
          <el-slider
              v-model="blurValue"
              :show-tooltip="false"
              :max="50"
              size="small"
          />
        </div>
        <div class="amm_slider">
          <div class="f-l">场景曝光</div>
          <el-slider
              v-model="exposureValue"
              :show-tooltip="false"
              :min="0.1"
              :max="5"
              :step="0.1"
              size="small"
          />
        </div>
        <div class="amm_slider">
          <div class="f-l">色调</div>
          <el-select v-model="canvasFilter" class="m-2" placeholder="Select" size="small">
            <el-option
                v-for="item in filterList"
                :key="item.id"
                :value="item.value"
                :label="item.label"
            />
          </el-select>
        </div>
      </div>


    </div>

    <div class="artcanvas_menu public_card_color section">
      <div class="art_menu_each pointer" @click="changeBackground(item.url)" v-for="item in this.backgroundUrl" :key="item.id" title="更换背景">
        <div class="art_menu_each_filter f-l">
          {{item.name}}
        </div>
        <img class="ame_img" :src="'/web/canvas/backgroundPreview/'+item.imgurl" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import * as $ from "jquery";
import FlameNavBar from '@/components/Flame-NavBar'
import FlameArtCanvas from '@/components/Flame-ArtCanvas'
import {Plus} from '@element-plus/icons-vue'
import FlameInput from '@/components/Flame-Input'
import router from "@/router";
import request from "@/utils/request";


export default {
  name: "artcanvas",
  components: {
    FlameNavBar,
    FlameArtCanvas,
    Plus
  },
  data(){
    return{
      blurValue:0,
      exposureValue:1,
      backgroundValue:'',
      backgroundUrl:[{
        id:2,
        url:'HDRI_STUDIO_vol2_030.hdr',
        name:'摄影棚1',
        imgurl:'HDRI_STUDIO_vol2_030.jpg'
      },{
        id:6,
        url:'CreativeOffice.hdr',
        name:'办公室',
        imgurl:'CreativeOffice.jpg'
      },{
        id:7,
        url:'EmptyConventionHall.hdr',
        name:'大厅',
        imgurl:'EmptyConventionHall.jpg'
      }],

      filterList:[{
        id:1,
        value:'CineonToneMapping',
        label:'柔和'
      },{
        id:2,
        value:'ACESFilmicToneMapping',
        label:'电影'
      },{
        id:3,
        value:'LinearToneMapping',
        label:'明亮'
      },{
        id:4,
        value:'ReinhardToneMapping',
        label:'HDR'
      }],

      canvasFilter:'ACESFilmicToneMapping',

      //redirct
      modelUrl: decodeURIComponent(this.$route.query.artworkModelUrl),
    }
  },
  mounted() {
    this.$refs.publishCanvas.loadModel(this.$globalVar + '/files/glb/' + this.modelUrl)
  },
  methods:{
    changeBackground(targetUrl){
      this.$refs.publishCanvas.loadEnvironment(targetUrl)
      // this.backgroundValue = 'CloudCity.hdr'
    },
  }


}
</script>

<style src="@/style/FlameTheme.css" scoped/>
<style src="@/style/FlameDarkTheme.css" scoped/>
<style src="@/style/PublicStyle.css" scoped/>
<style scoped>


@media screen and (orientation: landscape) {
  .artcanvas_body{
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .artcanvas_main{
    position: fixed;
    width: 1300px;
    height: 720px;
    float: left;
    top: 52px;
  }
  .artcanvas_menu{
    position: absolute;
    overflow-y: scroll;
    width: 235px;
    height: 85%;
    float: right;
    right: 0;
    top: 52px;
  }
  .artcanvas_menu_mini{
    position: fixed;
    width: 100%;
    height: 60px;
    float: left;
    bottom: 0;
  }

  .amm_slider{
    position: relative;
    margin-left: 20px;
    margin-top: 10px;
    float: left;
    font-size: 10px;
    /*background-color: red;*/
    width: 200px;
  }

  .art_menu_each{
    position: relative;
    width: 90%;
    height: 100px;
    margin: 10px auto;
  }
  .art_menu_each_filter{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 100%;
    width: 100%;
    font-size: 15px;
    letter-spacing: 5px;
    color: #F0F0F0;
    backdrop-filter: blur(10px);
    background-color: rgba(49, 47, 47, 0.8);
    opacity: 0;
  }
  .art_menu_each_filter:hover{
    animation: art_menu_each_filterAnim 0.2s;
    opacity: 1;
  }
  @keyframes art_menu_each_filterAnim {
    from{
      opacity: 0;
    }to{
       opacity: 1;
     }
  }
  .ame_img{
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

/*竖屏 css*/
@media screen and (orientation: portrait) {
  .artcanvas_body{
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .artcanvas_main{
    position: fixed;
    width: 1300px;
    height: 720px;
    float: left;
    top: 52px;
  }

  .artcanvas_menu_mini{
    position: fixed;
    width: 100%;
    height: 60px;
    float: left;
    bottom: 0;
  }

  .amm_slider{
    position: relative;
    margin-left: 20px;
    margin-top: 10px;
    float: left;
    font-size: 10px;
    /*background-color: red;*/
    width: 100px;
  }

  .artcanvas_menu{
    position: absolute;
    overflow-y: scroll;
    width: 100%;
    height: 150px;
    float: right;
    right: 0;
    bottom: 60px;
  }
  .art_menu_each{
    position: relative;
    float: left;
    width: 40%;
    height: 80px;
    margin: 10px 15px;

  }
  .art_menu_each_filter{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 100%;
    width: 100%;
    font-size: 15px;
    letter-spacing: 5px;
    color: #F0F0F0;
    backdrop-filter: blur(10px);
    background-color: rgba(49, 47, 47, 0.8);
    opacity: 0;
  }
  .art_menu_each_filter:hover{
    animation: art_menu_each_filterAnim 0.2s;
    opacity: 1;
  }
  @keyframes art_menu_each_filterAnim {
    from{
      opacity: 0;
    }to{
       opacity: 1;
     }
  }
  .ame_img{
    object-fit: cover;
    width: 100%;
    height: 100%;
  }


}





</style>