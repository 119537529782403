<template>
  <div class="a_searchArt_body">
    <el-table :data="artworkType" style="width: 100%">
      <el-table-column prop="atypeid" label="类型编号" width="180" />
      <el-table-column label="艺术品类型" width="280" >
        <template #default="scope">
          <el-input v-model="scope.row.typename" placeholder="艺术品类型" />
        </template>
      </el-table-column>
      <el-table-column  label="操作">
        <template #default="scope">
          <el-popconfirm
              confirm-button-text="确认"
              cancel-button-text="关闭"
              @confirm="updateArtworkType(scope.row.atypeid,scope.row.typename)"
              title="确定要更新类型吗？请谨慎修改此项！">
            <template slot-scope="scope" #reference>
              <el-button size="small"
                         type="success">更新
              </el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

  </div>
</template>

<script>
import request from "@/utils/request";
import {ElMessage} from "element-plus";

export default {
  name: "adminartclassify",
  data(){
    return{
      artworkType:[],
    }
  },
  mounted() {
    this.getArtworkType()
  },
  methods:{
    getArtworkType(){
      // console.log(this.options[0].children[0].value)
      request.get('/private/admin/getArtType').then(res => {
        // console.log(res)
        this.artworkType = res

      })
    },

    updateArtworkType(atypeid,typename){
      request.get('/private/admin/editArtType',{params:{atypeid:atypeid,typename:typename}}).then(res => {
        ElMessage({
          message: '操作成功',
          type: 'success',
        })
        this.getArtworkType()
      })
    }
  }
}
</script>

<style scoped>

</style>