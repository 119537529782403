<template>
  <FlameNavBar/>
  <div class="manage_body section public_bgc">
    <div class="manb_main public_card_color public_black">
      <div class="manbm_title f-b doNotSelect">账户设置</div>
      <div class="manage_line"/>
      <div class="manbm_little_title f-l doNotSelect">账户信息</div>
      <div class="manbm_msgtitle f-b"><span class="doNotSelect">ID:&nbsp;&nbsp;</span><span
          class="f-l">{{ this.userPersonalMsg.uuid }}</span></div>
      <div class="managem_body">
        <div class="managem_input_each">
          <div class="input_border public_gray_border public_input_bgc public_black b_realname" id="realname_border">
            <div class="input_border_title f-l doNotSelect f-10" id="realname" style="left: 15px;top: 5px;">
              真实姓名
            </div>
            <input class="input_border_main public_black i_realname"
                   @blur="checkUserInput('realname',this.userPersonalMsg.realname)"
                   @click="selectBorder('realname')"
                   v-model="this.userPersonalMsg.realname"
                   type="text"
            >
          </div>
          <div class="input_alert_msg public_warning_color f-10" id="realname_alert"></div>
        </div>
        <div class="managem_input_each">
          <div class="input_border public_gray_border public_input_bgc public_black b_nickname" id="nickname_border">
            <div class="input_border_title f-l doNotSelect" id="nickname" style="left: 15px;top: 5px;font-size: 10px">
              显示名称
            </div>
            <input class="input_border_main public_black i_nickname"
                   @blur="checkUserInput('nickname',this.userPersonalMsg.nickname)"
                   @click="selectBorder('nickname')"
                   v-model="this.userPersonalMsg.nickname"
                   type="text"
            >
          </div>
          <div class="input_alert_msg public_warning_color f-10" id="nickname_alert"></div>
        </div>
      </div>

      <div class="manbm_little_title f-l doNotSelect">地址</div>
      <div class="managem_body">
        <div class="managem_input_each">
          <div class="input_border public_gray_border public_input_bgc public_black b_address" id="address_border">
            <div class="input_border_title f-l doNotSelect" id="address">
              收件地址
            </div>
            <input class="input_border_main public_black i_address"
                   @blur="checkUserInput('address',this.userPersonalMsg.address)"
                   @click="selectBorder('address')"
                   v-model="this.userPersonalMsg.address"
                   type="text"
            >
          </div>
          <div class="input_alert_msg public_warning_color f-10" id="address_alert"></div>
        </div>
        <div class="managem_input_each">
          <div class="input_border public_gray_border public_input_bgc public_black b_tel" id="tel_border">
            <div class="input_border_title f-l doNotSelect" id="tel">
              联系方式
            </div>
            <input class="input_border_main public_black i_tel"
                   @blur="checkUserInput('tel',this.userPersonalMsg.tel)"
                   @click="selectBorder('tel')"
                   v-model="this.userPersonalMsg.tel"
                   type="text"
            >
          </div>
          <div class="input_alert_msg public_warning_color f-10" id="tel_alert"></div>
        </div>
      </div>


      <div class="manbm_little_title f-l doNotSelect">电子邮箱</div>
      <div class="managem_body">
        <div class="managem_input_each">
          <div class="input_border public_gray_border  public_black canNotPointer b_email" id="email_border">
            <div class="input_border_title f-l doNotSelect" id="email" style="left: 15px;top: 5px;font-size: 10px">
              电子邮箱
            </div>
            <input class="input_border_main public_black canNotPointer doNotPointer"
                   @blur="checkUserInput('email',this.userPersonalMsg.email)"
                   @click="selectBorder('email')"
                   v-model="this.userPersonalMsg.email"
                   type="text"
                   style="width: 500px"
            >
          </div>
          <div class="input_alert_msg public_warning_color f-10" id="email_alert"></div>
        </div>

      </div>


      <div class="managem_submit_body">
        <input @click="cancelEdit" class="managem_cancel_btn pointer" type="button" value="放弃更改">
        <input id="manage_btn" @click="updateUserNewMsg" class="managem_submit_btn pointer" type="button" value="保存变更">
      </div>


    </div>

    <successRedirect :sucRedirectURL="'/account/personal'" ref="successRedirect"/>

  </div>
</template>

<script>
import * as $ from "jquery";
import FlameNavBar from '@/components/Flame-NavBar'
import router from "@/router";
import request from "@/utils/request";
import successRedirect from "@/components/successredirect";

export default {
  name: "manage",
  components: {
    FlameNavBar,
    successRedirect,
  },
  data() {
    return {
      userPersonalMsg: {
        realname: '',
        nickname: '',
        address: '',
        tel: '',
      },
      inputFlag: [
        {
          Flag: true,
        }, {
          Flag: true,
        }, {
          Flag: true,
        }, {
          Flag: true,
        }],
      addCity: '',
      addSpecific: '',
      useruuid:localStorage.getItem('flame_user_uuid'),
      //二次警告
      secAlert: false,
    }
  },
  mounted() {
    if (localStorage.getItem('flame_user_uuid') === null || localStorage.getItem('flame_user_uuid') === "") {
      window.open('/#/user/login?redirectUrl=' + encodeURIComponent(window.location.origin) + encodeURIComponent(window.location.pathname), '_self');
    } else {
      this.getUsersMsg();
    }
  },
  methods: {
    getUsersMsg() {
      request.post('user/userPersonalMsg', {'uuid': localStorage.getItem('flame_user_uuid')}).then(res => {
        // console.log(res)
        this.userPersonalMsg = res
        // console.log(this.userPersonalMsg.tel)
        if (this.userPersonalMsg.address !== "") {
          this.selectBorder('address')
        }
        if (this.userPersonalMsg.tel !== "") {
          this.selectBorder('tel')
        }
      })
    },
    updateUserNewMsg() {
      let checkFlag = true
      if (this.secAlert) {
        this.secondAlert()
      }

      if(this.userPersonalMsg.nickname === ""){
        this.buttonAlert(false, '请检查输入值')
      }else{
        if(this.userPersonalMsg.realname === ""){
          this.buttonAlert(false, '请检查输入值')
        }else{
          for (let i = 0; i < this.inputFlag.length; i++) {
            if (!this.inputFlag[i].Flag) {
              checkFlag = false
              this.buttonAlert(false, '请检查输入值')
              break
            }
          }
          if(checkFlag){
            request.post('user/editUserMsg', {
              'uuid': localStorage.getItem('flame_user_uuid'),
              'nickname': this.userPersonalMsg.nickname,
              'realname': this.userPersonalMsg.realname,
              'tel': this.userPersonalMsg.tel,
              'address': this.userPersonalMsg.address,
            }).then(() => {
              localStorage.setItem('flame_user_name', this.userPersonalMsg.nickname)
              this.$refs.successRedirect.successRedirectTable('修改成功')
            })
          }
        }
      }
    },


    //blur事件验证
    checkUserInput(idName, inputValue) {
      //输入值为空时
      if (inputValue === "") {
        if (idName !== 'address' && idName !== 'tel') {
          this.showAlertMsg(idName, '必填')
        }else{
          if(idName === 'address'){
            this.inputFlag[2].Flag = true
          }else{
            this.inputFlag[3].Flag = true
          }
        }
        $('#' + idName).stop(true, false).animate({
          left: '20px',
          top: '20px',
          fontSize: '15px',
        }, 100)
      } else {
        const realnameCheckReg = /^[\u4E00-\u9FA5A-Za-z]+$/;
        const nicknameCheckReg = /^[\u4E00-\u9FA5-A-Za-z0-9-_]+(?:[\s][A-Za-z0-9]+)*$/;
        const telReg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
        switch (idName) {
          case 'realname':
            if (inputValue === "") {
              this.showAlertMsg(idName, '必填')
              this.inputFlag[0].Flag = false
            }else if (inputValue.length > 170) {
              this.showAlertMsg(idName, '过长')
              this.inputFlag[0].Flag = false
            } else if (!realnameCheckReg.test(inputValue)) {
              this.showAlertMsg(idName, '格式错误')
              this.inputFlag[0].Flag = false
            } else {
              this.inputFlag[0].Flag = true
            }
            break;
          case 'nickname':
            if (inputValue === "") {
              this.showAlertMsg(idName, '必填')
              this.inputFlag[1].Flag = false
            } else if (inputValue.length < 3) {
              this.showAlertMsg(idName, '过短')
              this.inputFlag[1].Flag = false
            } else if (inputValue.length > 16) {
              this.showAlertMsg(idName, '过长')
              this.inputFlag[1].Flag = false
            } else if (!nicknameCheckReg.test(inputValue)) {
              this.showAlertMsg(idName, '格式错误')
              this.inputFlag[1].Flag = false
            } else {
              request.get("user/checkEditNickname", {params:{useruuid:this.useruuid,value:inputValue}})
                  .then(res => {
                    if (res) {
                      this.inputFlag[1].Flag = true
                    } else {
                      this.showAlertMsg(idName, '名称已被占用')
                      this.inputFlag[1].Flag = false
                    }
                  })
            }
            break;
          case 'address':
            if(inputValue.length > 255){
              this.showAlertMsg(idName, '过长')
              this.inputFlag[2].Flag = false
            }else{
              this.inputFlag[2].Flag = true
            }
            break;
          case 'tel':
            if(inputValue.length > 255){
              this.showAlertMsg(idName, '过长')
              this.inputFlag[3].Flag = false
            }else if (!telReg.test(inputValue)) {
              this.showAlertMsg(idName, '格式错误')
              this.inputFlag[3].Flag = false
            }else{
              this.inputFlag[3].Flag = true
            }
            break;

        }

      }

    },


    //点击初始化样式
    selectBorder(idName) {
      this.buttonAlert(true)
      $('#' + idName + '_alert').text('')
      $('#' + idName + "_border").removeClass("border_Warning")
      $('#' + idName).stop(true, false).animate({
        left: '15px',
        top: '5px',
        fontSize: '10px',
      }, 100)
    },
    //下方展示的验证警告
    showAlertMsg(idName, Msg) {
      $('#' + idName + "_border").addClass("border_Warning")
      $('#' + idName + '_alert').text(Msg)
    },

    buttonAlert(Flag, tips) {
      if (Flag) {
        this.secAlert = false
        $('#manage_btn').removeClass('public_submit_Alert').addClass('managem_submit_btn')
        $('#manage_btn').val('保存变更')
      } else {
        this.secAlert = true
        $('#manage_btn').removeClass('managem_submit_btn').addClass('public_submit_Alert')
        $('#manage_btn').val(tips)
      }
    },
    secondAlert() {
      $('#manage_btn').addClass('public_submit_secAlert')
      setTimeout(() => {
        $('#manage_btn').removeClass("public_submit_secAlert")
      }, 10);
    },

    cancelEdit() {
      window.open('/#/account/personal', '_self')
    }
  }
}
</script>
<style src="@/style/FlameTheme.css" scoped/>
<style src="@/style/FlameDarkTheme.css" scoped/>
<style src="@/style/PublicStyle.css" scoped/>
<style scoped>

@media screen and (orientation: landscape) {
  .manage_body {
    display: flex;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }

  .manage_line {
    height: 20px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    width: 90%;
    border-bottom: 1px solid #b6b6b6;
  }

  .manb_main {
    position: relative;
    width: 1170px;
    height: 100%;
    margin: 52px auto;
  }

  .manbm_title {
    position: relative;
    margin-top: 20px;
    margin-left: 100px;
    font-size: 25px;
  }

  .manbm_little_title {
    position: relative;
    margin-top: 20px;
    margin-left: 100px;
    font-size: 20px;
  }

  .manbm_msgtitle {
    position: relative;
    margin-top: 20px;
    margin-left: 100px;
    font-size: 10px;
  }

  .managem_body {
    position: relative;
    height: 120px;
    width: 90%;
    margin: auto;
    /*background-color: red;*/
  }

  .managem_input_each {
    position: relative;
    margin-top: 20px;
    margin-left: 30px;
    float: left;
  }

  .managem_submit_body {
    position: relative;
    width: auto;
    margin-left: 70px;
  }

  .managem_submit_body input {
    border-radius: 5px;
    border: none;
    width: 150px;
    height: 50px;
    margin-left: 20px;
    margin-top: 20px;
  }

  .managem_cancel_btn {
    background-color: #d0d0d0;
  }

  .managem_submit_btn {
    color: #F0F0F0;
    background-color: #0074e3;
  }

  .managem_cancel_btn:hover {
    animation: managem_cancel_btnAnim 0.5s;
    background-color: #dedede;
  }

  .managem_submit_btn:hover {
    animation: managem_submit_btnAnim 0.5s;
    background-color: #005bb2;
  }

  @keyframes managem_cancel_btnAnim {
    from {
      background-color: #d0d0d0;
    }
    to {
      background-color: #dedede;
    }
  }

  @keyframes managem_submit_btnAnim {
    from {
      background-color: #0074e3;
    }
    to {
      background-color: #005bb2;
    }
  }

  /*INput样式*/
  .input_border {
    position: relative;
    border-radius: 5px;
    width: 365px;
    height: 60px;
  }

  .input_border_title {
    position: absolute;
    left: 20px;
    top: 20px;
    font-size: 15px;
  }

  .input_border_main {
    position: absolute;
    border: none;
    background-color: #FFFFFF00;
    outline: none;
    padding-left: 10px;
    font-size: 15px;
    width: 70%;
    height: 50%;
    bottom: 5px;
    left: 5px;
  }

  .input_alert_msg {
    position: relative;
    height: 20px;
  }
  .i_address{
    width: 500px;
  }
  .i_tel{
    width: 200px;
  }
  .b_realname{
    width: 250px;
  }

  .b_nickname{
    width: 250px;
  }
  .b_address{
    width: 530px
  }
  .b_tel{
    width: 230px
  }
  .b_email{
    width: 250px
  }

}
@media screen and (orientation: portrait) {
  .manage_body {
    display: flex;
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    height: auto;
    padding-top: 30px;
  }

  .manage_line {
    height: 20px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    width: 90%;
    border-bottom: 1px solid #b6b6b6;
  }

  .manb_main {
    position: relative;
    width: 90%;
    height: 100%;
    margin: 52px auto;
    padding-bottom: 30px;
  }

  .manbm_title {
    position: relative;
    margin-top: 20px;
    margin-left: 5%;
    font-size: 15px;
  }

  .manbm_little_title {
    position: relative;
    width: 90%;
    margin-top: 20px;
    margin-left: 5%;
    font-size: 10px;
  }

  .manbm_msgtitle {
    position: relative;
    margin-top: 20px;
    margin-left: 5%;
    font-size: 10px;
  }

  .managem_body {
    position: relative;
    height: 120px;
    width: 100%;
    margin: auto;
  }

  .managem_input_each {
    position: relative;
    width: 100%;
    margin-top: 20px;
    margin-left: 5%;
    float: left;
  }

  .managem_submit_body {
    position: relative;
    width: auto;
    margin-left: 5%;
  }

  .managem_submit_body input {
    border-radius: 5px;
    border: none;
    width: 40%;
    height: 50px;
    margin-left: 5%;
    margin-top: 20px;
  }

  .managem_cancel_btn {
    background-color: #d0d0d0;
  }

  .managem_submit_btn {
    color: #F0F0F0;
    background-color: #0074e3;
  }

  .managem_cancel_btn:hover {
    animation: managem_cancel_btnAnim 0.5s;
    background-color: #dedede;
  }

  .managem_submit_btn:hover {
    animation: managem_submit_btnAnim 0.5s;
    background-color: #005bb2;
  }

  @keyframes managem_cancel_btnAnim {
    from {
      background-color: #d0d0d0;
    }
    to {
      background-color: #dedede;
    }
  }

  @keyframes managem_submit_btnAnim {
    from {
      background-color: #0074e3;
    }
    to {
      background-color: #005bb2;
    }
  }

  /*INput样式*/
  .input_border {
    position: relative;
    border-radius: 5px;
    width: 365px;
    height: 60px;
  }

  .input_border_title {
    position: absolute;
    left: 20px;
    top: 20px;
    font-size: 15px;
  }

  .input_border_main {
    position: absolute;
    border: none;
    background-color: #FFFFFF00;
    outline: none;
    padding-left: 10px;
    font-size: 15px;
    width: 80%;
    height: 50%;
    bottom: 5px;
    left: 5px;
    z-index: 2;
  }

  .input_alert_msg {
    position: relative;
    height: 20px;
  }




  .b_realname,
  .b_nickname,
  .b_address,
  .b_tel,
  .b_email{
    width: 90%;
  }
}
</style>