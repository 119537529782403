<template>
  <div class="fp_body public_bgc doNotSelect">
    <div class="fpb_card public_card_color">
      <div class="ta-c f-25 f-b p-r t-5 public_black">找回密码</div>
      <div class="fpb_steps">
        <el-steps :space="200" :active="this.nowStep" finish-status="success" align-center>
          <el-step :title="this.stepName[0].title"/>
          <el-step :title="this.stepName[1].title"/>
          <el-step :title="this.stepName[2].title"/>
        </el-steps>
      </div>

      <div class="fpb_inputArea">
        <div id="input_email_border" class="fpb_input_border public_gray_border" v-show="this.showEmail">
          <div id="input_email" class="fpb_input_title public_gray">电子邮箱地址</div>
          <input class="f-15 public_black" v-model="this.email"
                 @click="initInputBorder('input_email')"
                 @blur="checkUserInput('input_email',this.email)" type="text">
          <div id="input_email_alert" class="fpb_alert_msg f-10 public_warning_color"></div>
        </div>


        <div class="cc_validateCode" v-show="this.showVcode">
          <div class="ccv_input_border">
            <div class="ccvi_each public_gray_border public_input_bgc">
              <input class="public_gray" v-model="input0" ref="input0" @click="vCodeBorderAlert(false)"
                     @keydown="deleteValue($event,'input0','input0')"
                     @keyup="changeValue($event,'input0','input1')" type="text" maxlength="1">
            </div>
            <div class="ccvi_each public_gray_border public_input_bgc">
              <input class="public_gray" v-model="input1" ref="input1" @click="vCodeBorderAlert(false)"
                     @keydown="deleteValue($event,'input1','input0')"
                     @keyup="changeValue($event,'input1','input2')" type="text" maxlength="1">
            </div>
            <div class="ccvi_each public_gray_border public_input_bgc">
              <input class="public_gray" v-model="input2" ref="input2" @click="vCodeBorderAlert(false)"
                     @keydown="deleteValue($event,'input2','input1')"
                     @keyup="changeValue($event,'input2','input3')" type="text" maxlength="1">
            </div>
            <div class="ccvi_each public_gray_border public_input_bgc">
              <input class="public_gray" v-model="input3" ref="input3" @click="vCodeBorderAlert(false)"
                     @keydown="deleteValue($event,'input3','input2')"
                     @keyup="changeValue($event,'input3','input3')" type="text" maxlength="1">
            </div>
          </div>
          <div class="p-r ta-c f-15 -t-20 public_gray">我们发送了一封携带验证码的邮件到您的邮箱上</div>
        </div>


        <div id="input_password_border" class="fpb_input_border public_gray_border" v-show="this.showpwd">
          <div id="input_password" class="fpb_input_title public_gray">新密码</div>
          <input class="f-15 public_black" v-model="this.newpassword"
                 @keyup="checkPassword"
                 @click="initInputBorder('input_password')"
                 @blur="checkUserInput('input_password',this.newpassword)" :type="pwdInputType" maxlength="20">
          <div id="input_password_alert" class="fpb_alert_msg f-10 public_warning_color"></div>


          <div class="ani_each_1_tips public_black" :class="tips_anim" v-show="passwordTips">
            <div>密码长度必须在6到18位之间。至少包含1个数字和1个字母，特殊字符有且仅可包含横线和下划线，不能包含空格</div>
          </div>

          <div class="input_tips public_tips_backgroundColor"
               @mouseover="this.passwordTips = true;this.tips_anim = 'tips_into_anim'"
               @mouseleave="this.passwordTips = false"
               @click="this.passwordTips = !this.passwordTips">
            <svg t="1672202106208" class="input_tips_icon public_tips_iconFill" viewBox="0 0 1024 1024" version="1.1"
                 xmlns="http://www.w3.org/2000/svg"
                 p-id="4670" width="21" height="21">
              <path
                  d="M512 64C264.8 64 64 264.8 64 512s200.8 448 448 448 448-200.8 448-448S759.2 64 512 64z m32 704h-64v-64h64v64z m-64-128V256h64v384h-64z"
                  p-id="4671"></path>
            </svg>
          </div>


          <div class="input_tips public_tips_backgroundColor" style="right: 30px" @click="pwdShoworHide">
            <svg t="1672201369757" class="input_tips_icon public_tips_iconFill" viewBox="0 0 1024 1024" version="1.1"
                 xmlns="http://www.w3.org/2000/svg" p-id="3580" width="21" height="21" v-if="isPwdShow">
              <path
                  d="M512 204.8C269.06624 204.8 40.96 358.68672 40.96 512s228.10624 307.2 471.04 307.2 471.04-153.88672 471.04-307.2-228.10624-307.2-471.04-307.2z"
                  p-id="3581"></path>
              <path
                  d="M512 337.92a174.08 174.08 0 1 0 0 348.16 174.08 174.08 0 0 0 0-348.16z m0 61.44a112.64 112.64 0 1 1 0 225.28 112.64 112.64 0 0 1 0-225.28z"
                  fill="#FFFFFF" p-id="3582"></path>
            </svg>

            <svg t="1672201563313" class="input_tips_icon public_tips_iconFill" viewBox="0 0 1024 1024" version="1.1"
                 xmlns="http://www.w3.org/2000/svg"
                 p-id="3728" width="21" height="21" v-if="!isPwdShow">
              <path
                  d="M983.04 512c0 153.31328-228.10624 307.2-471.04 307.2-90.3168 0-178.56512-21.25824-253.80864-55.86944l129.49504-129.47456a174.08 174.08 0 1 0 246.1696-246.1696l128.57344-128.57344C891.392 317.44 983.04 414.8224 983.04 512zM512 204.8c65.00352 0 128.96256 11.01824 187.76064 30.1056l-117.69856 117.69856a174.08 174.08 0 0 0-229.4784 229.4784l-151.18336 151.1424C105.02144 674.67264 40.96 593.26464 40.96 512c0-153.31328 228.10624-307.2 471.04-307.2z m112.64 307.2a112.64 112.64 0 0 1-193.49504 78.41792l159.27296-159.27296c21.0944 20.48 34.22208 49.13152 34.22208 80.85504zM512 399.36c7.2704 0 14.37696 0.69632 21.27872 2.00704l-131.91168 131.8912A112.64 112.64 0 0 1 512 399.36z"
                  p-id="3729"></path>
              <path
                  d="M872.796352 148.719008m21.72232 21.72232l0 0q21.72232 21.72232 0 43.444641l-680.632703 680.632703q-21.72232 21.72232-43.444641 0l0 0q-21.72232-21.72232 0-43.444641l680.632703-680.632703q21.72232-21.72232 43.444641 0Z"
                  p-id="3730"></path>
            </svg>
          </div>
          <div class="password_strength public_black">
            <div class="password_strength_text f-l">
              <div id="strength_text" class="pst_body"></div>
            </div>
            <div class="password_strength_color">
              <div class="password_strength_color_border" id="strength1" v-show="this.pwdStrength1"></div>
              <div class="password_strength_color_border" id="strength2" v-show="this.pwdStrength2"></div>
              <div class="password_strength_color_border" id="strength3" v-show="this.pwdStrength3"></div>
            </div>
          </div>


        </div>


      </div>

      <div class="fpb_submit">
        <input id="fpb_submit_btn" class="public_submit_color" @click="fpSubmit" type="button"
               value="下一步">
      </div>
      <div class="p-r ta-c f-15 t-25 public_gray">记住密码？<a :href="this.loginUrl" class="public_themeColor_link t-underline">登录</a></div>
    </div>

    <div class="fpsuccess_body doNotSelect" v-show="successTab">
      <div class="fpb_card public_card_color public_black">
        <div class="cc_success_title">
          <div class="ccs_title f-40 f-b p-r ta-c">修改成功</div>
          <div class="ccs_title f-25 f-l p-r ta-c mt-5">正在自动跳转</div>
        </div>
        <div class="ccs_circle">
          <el-progress class="ccs_circle_body" type="circle" :percentage="this.successPercent" status="success"
                       :width="150">
            <div class="f-30">{{ this.successTimes }}</div>
          </el-progress>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import * as $ from "jquery";
import request from "@/utils/request";
import router from "@/router";

export default {
  name: "forgotpassword",
  data() {
    return {
      //网页风格
      theme: false,

      loginUrl :"/#/user/login?redirectUrl=" + encodeURIComponent(window.location.origin) + encodeURIComponent(window.location.pathname),

      //用户输入的数据
      email: '',
      vcode: '',
      newpassword: '',

      //进行的步骤
      nowStep: 0,
      //进度条步骤名
      stepName: [
        {
          title: '输入邮箱'
        }, {
          title: ''
        }, {
          title: ''
        }
      ],
      //输入框展示
      showEmail: true,
      showVcode: false,
      showpwd: false,
      //用户输入提示动画CSS类
      tips_anim: "",
      //密码提示
      passwordTips: false,
      //密码显示与隐藏
      pwdInputType: "password",
      //密码显示与隐藏图标Flag
      isPwdShow: false,
      //密码强度判定
      pwdStrength1: true,
      pwdStrength2: true,
      pwdStrength3: true,

      //验证码
      vCodeWindowShow: false,
      validateCode: '',
      input0: '',
      input1: '',
      input2: '',
      input3: '',
      sendEmailAgainTips: "点击此处再次发送",
      sendEmailAgainTimes: 0,
      sendEmailDisabled: false,


      //数据合法性
      emailIsLegal: false,
      vcodeIsLegal: false,
      newpasswordIsLegal: false,
      //二次警告
      needSecondAlert: false,

      //修改成功
      successTab: false,
      successTimes: 3,
      successPercent: 0,


      //redirct
      redirect: decodeURIComponent(this.$route.query.redirectUrl),
    }
  },
  mounted() {
    // console.log(this.redirect)
    this.checkWebTheme()
  },
  methods: {
    checkWebTheme() {
      $("#body").removeClass("changeDarkThemeAnim")
      $("#body").removeClass("changeLightThemeAnim")
      if (localStorage.getItem("WebTheme") === 'dark') {
        $("#body").addClass("dark")
        $(".el-step__title").css('color', '#cecece')
        $(".el-step__line").css('background', '#0b0b0b')
        $(".el-step__icon").css('background', '#1f1f1f')
        this.theme = true
      } else {
        $("#body").removeClass("dark")
        $(".el-step__title").css('color', '#1f1f1f')
        this.theme = false
      }
    },

    checkUserInput(titleName, inputValue) {
      if (inputValue === "") {
        $('#' + titleName).stop(true, false).animate({
          left: '20px',
          top: '15px',
          fontSize: '15px',
        }, 100)
        this.showAlertMsg(titleName, '必填')
      } else {

        switch (titleName) {
          case 'input_email':
            const emailCheckReg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;
            // console.log('执行了switch')
            if (emailCheckReg.test(inputValue)) {
              this.emailIsLegal = true


              // request.post("user/checkEmail", {'email': inputValue})
              //     .then(res => {
              //       if (!res) {
              //         // console.log('执行了true赋值')
              //         this.emailIsLegal = true
              //       } else {
              //         // console.log('执行了false赋值')
              //         this.showAlertMsg(titleName, '电子邮件无效')
              //         this.emailIsLegal = false
              //       }
              //     })
            } else {
              this.showAlertMsg(titleName, '格式错误')
              this.emailIsLegal = false
            }
            break;
          case 'input_password':
            const passwordCheckReg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z_-]{6,18}$/
            if (inputValue.length < 6) {
              this.showAlertMsg(titleName, '过短')
              this.newpasswordIsLegal = false
            } else if (inputValue.length > 18) {
              this.showAlertMsg(titleName, '过长')
              this.newpasswordIsLegal = false
            } else if (!passwordCheckReg.test(inputValue)) {
              this.showAlertMsg(titleName, '格式错误')
              this.newpasswordIsLegal = false
            } else {
              this.newpasswordIsLegal = true
            }
            break;

        }

      }
    },

    pwdShoworHide() {
      if (this.isPwdShow) {
        this.pwdInputType = "password"
      } else {
        this.pwdInputType = "text"
      }
      this.isPwdShow = !this.isPwdShow
    },
    checkPassword() {
      let reg = 0;
      if (this.newpassword === "") {
        reg = 0
      } else if (this.newpassword.length < 6) {
        reg = 1
      } else {
        if (/\d/.test(this.newpassword)) {
          reg++
        }
        if (/[a-z]/.test(this.newpassword)) {
          reg++
        }
        if (/[A-Z]/.test(this.newpassword)) {
          reg++
        }
        if (/\W/.test(this.newpassword)) {
          reg++
        }
        if (!/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z_-]{6,18}$/.test(this.newpassword)) {
          reg = 5
        }
      }
      switch (reg) {
        case 0:
          document.getElementById("strength_text").innerText = ""
          this.pwdStrength1 = false
          this.pwdStrength2 = false
          this.pwdStrength3 = false
          break
        case 1:
          $('#strength_text').text("低");
          $('#strength1').css("backgroundColor", "#9a2521");
          $('#strength2').css("backgroundColor", "none");
          $('#strength3').css("backgroundColor", "none");
          this.pwdStrength1 = true
          this.pwdStrength2 = false
          this.pwdStrength3 = false
          break
        case 2:
          $('#strength_text').text("中");
          $('#strength1').css("backgroundColor", "#ffc547");
          $('#strength2').css("backgroundColor", "#ffc547");
          $('#strength3').css("backgroundColor", "none");
          this.pwdStrength1 = true
          this.pwdStrength2 = true
          this.pwdStrength3 = false
          break
        case 3:
          $('#strength_text').text("高");
          $('#strength1').css("backgroundColor", "#498f4b");
          $('#strength2').css("backgroundColor", "#498f4b");
          $('#strength3').css("backgroundColor", "#498f4b");
          this.pwdStrength1 = true
          this.pwdStrength2 = true
          this.pwdStrength3 = true
          break
        case 4:
          $('#strength_text').text("非常高");
          $('#strength1').css("backgroundColor", "#1fa4c4");
          $('#strength2').css("backgroundColor", "#1fa4c4");
          $('#strength3').css("backgroundColor", "#1fa4c4");
          this.pwdStrength1 = true
          this.pwdStrength2 = true
          this.pwdStrength3 = true
          break
        case 5:
          $('#strength_text').text("格式错误");
          $('#strength1').css("backgroundColor", "#380d0c");
          $('#strength2').css("backgroundColor", "#380d0c");
          $('#strength3').css("backgroundColor", "#380d0c");
          this.pwdStrength1 = true
          this.pwdStrength2 = true
          this.pwdStrength3 = true
          break
      }
    },


    fpSubmit() {
      // setTimeout(() => {
        if (this.needSecondAlert) {
          this.buttonSecondAlert()
        }
        switch (this.nowStep) {
          case 0:
            if (!this.emailIsLegal) {
              this.buttonAlert(false, '请检查邮箱')
            } else {
              request.post("user/checkEmail", {'email': this.email})
                  .then(res => {
                    if (!res) {
                      this.buttonAlert(true)
                      request.post("user/sendValidateCodeEmail", {'email': this.email})
                      this.nowStep++
                      this.showEmail = false
                      this.showVcode = true
                      this.stepName[0].title = ''
                      this.stepName[1].title = '输入验证码'
                    } else {
                      // console.log('执行了false赋值')
                      this.showAlertMsg('input_email', '电子邮件无效')
                      this.buttonAlert(false, '电子邮件无效')
                      this.emailIsLegal = false
                    }
                  })
              // this.buttonAlert(true)
              // request.post("user/sendValidateCodeEmail", {'email': this.email})
              // this.nowStep++
              // this.showEmail = false
              // this.showVcode = true
              // this.stepName[0].title = ''
              // this.stepName[1].title = '输入验证码'
            }
            break;
          case 1:

            if (this.input0 === '' || this.input1 === '' || this.input2 === '' || this.input3 === '') {
              this.registerBtnSecAlert = false
              this.buttonAlert(false, '请输入完整验证码')
              this.vCodeBorderAlert(true)
              this.vcodeIsLegal = false
              break;
            } else {
              this.buttonAlert(true)
              request.get("user/checkRePasswordValidateCode", {
                params: {
                  'email': this.email,
                  'inputCode': this.input0 + this.input1 + this.input2 + this.input3
                }
              }).then(res => {
                if (res) {
                  this.nowStep++
                  this.showVcode = false
                  this.showpwd = true
                  this.stepName[1].title = ''
                  this.stepName[2].title = '重置密码'
                } else {
                  this.registerBtnSecAlert = false
                  this.buttonAlert(false, '验证码错误')
                  this.vCodeBorderAlert(true)
                  this.vcodeIsLegal = false
                }
              })

            }
            break;
          case 2:
            if (!this.newpasswordIsLegal) {
              this.registerBtnSecAlert = false
              this.buttonAlert(false, '请检查密码')
              this.vCodeBorderAlert(true)
              this.newpasswordIsLegal = false
            } else {
              this.buttonAlert(true)
              request.post("user/changePassword", {'email': this.email, 'pwd': this.newpassword})
              this.successTab = true
              this.successTable()
              this.nowStep = 0
              this.showpwd = false
              this.stepName[2].title = '修改成功'
            }
            break;
          default:
            this.nowStep = 0
            break
        }
      // }, 500);

    },



    changeValue(e, inputValue, nextItem) { // 键盘抬起时$event，当前input，下一个input
      // console.log(e.keyCode, this[inputValue], this[nextItem])
      if (e.keyCode !== 8) {
        this.$nextTick(() => {
          this.$refs[nextItem].focus() // 截取当前值最后一位，跳到下一个input
          // this[inputValue] = (this[inputValue]).toString().slice(-1)
        })
      }
    },
    //验证码
    deleteValue(e, inputValue, previousItem) { // 键盘按下时$event，当前input，上一个input
      // console.log(inputValue, previousItem)
      if (e.keyCode === 8) {
        if (this[inputValue].length > 0) { // 当前有值，清空
          this[inputValue] = ''
        } else { // 当前没有值，光标跳转到上一个input，并清空该input值
          this.$nextTick(() => {
            this.$refs[previousItem].focus()
            this[previousItem] = ''
            this[inputValue] = ''
          })
        }
      }

    },

    successTable() {
      this.successTab = true;
      this.successCircle()
      this.successCirclePercent()
    },

    successCircle() {
      if (this.successTimes > 0) {
        this.successTimes--;
        setTimeout(this.successCircle, 1000);
      } else {
        window.open(this.redirect + "?redirectUrl=" + encodeURIComponent(window.location.origin) + encodeURIComponent(window.location.pathname),'_self')
        // window.open(this.redirect)
        // router.push(this.redirect)
      }
    },

    successCirclePercent() {
      if (this.successPercent < 100) {
        this.successPercent++;
        setTimeout(this.successCirclePercent, 15);
      } else {
        this.successPercent = 100;
      }
    },


    buttonAlert(Flag, tips) {
      if (Flag) {
        this.needSecondAlert = false
        $('#fpb_submit_btn').removeClass('public_submit_Alert').addClass('public_submit_color')
        $('#fpb_submit_btn').val('下一步')
      } else {
        this.needSecondAlert = true
        $('#fpb_submit_btn').removeClass('public_submit_color').addClass('public_submit_Alert')
        $('#fpb_submit_btn').val(tips)
      }
    },

    buttonSecondAlert() {
      $('#fpb_submit_btn').addClass('public_submit_secAlert')
      setTimeout(() => {
        $("#fpb_submit_btn").removeClass("public_submit_secAlert")
      }, 10);
    },

    showAlertMsg(titleName, Msg) {
      this.needSecondAlert = true
      $('#' + titleName + "_border").addClass("border_Warning")
      $('#' + titleName + '_alert').text(Msg)
    },
    vCodeBorderAlert(FLag) {
      if (FLag) {
        $('.ccvi_each').removeClass('public_gray_border')
        $('.ccvi_each').addClass('border_Warning')
      } else {
        this.buttonAlert(true)
        $('.ccvi_each').addClass('public_gray_border')
        $('.ccvi_each').removeClass('border_Warning')
      }
    },
    initInputBorder(titleID) {
      this.buttonAlert(true)
      $('#' + titleID + '_alert').text('')
      $('#' + titleID).stop(true, false).animate({
        left: '15px',
        top: '5px',
        fontSize: '10px',
      }, 100)
      $('#' + titleID + '_border').removeClass('border_Warning')
    }
  }
}
</script>

<style src="@/style/FlameTheme.css" scoped/>
<style src="@/style/FlameDarkTheme.css" scoped/>
<style src="@/style/PublicStyle.css" scoped/>
<style scoped>
.fp_body {
  width: inherit;
  height: inherit;
}

.fpb_card {
  position: relative;
  margin: 0 auto;
}

.fpb_steps {
  position: relative;
}

.fpb_inputArea {
  position: relative;
}

.fpb_input_border {
  position: absolute;
  border-radius: 5px;
}

.fpb_input_border input {
  border: none;
  outline: none;
  background-color: rgba(255, 255, 255, 0);
}

.fpb_alert_msg {
  position: absolute;
}

.fpb_submit {
  position: relative;
  width: 100%;
  height: 100px;
}

.fpb_submit input {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border: none;
  border-radius: 5px;
}

.input_tips {
  position: absolute;
  border-radius: 5px;
  float: right;
}

.ani_each_1_tips {
  position: relative;
  float: right;
  border-radius: 5px;
  z-index: 1;
}

.ani_each_1_tips div {
  font-size: 15px;
  width: 90%;
  position: relative;
  left: 5%;
  top: 13%;
}

/*验证码*/
.cc_validateCode {
  position: absolute;
}

.ccv_input_border {
  position: relative;

  margin: 0 auto;
}

.ccvi_each {
  position: relative;
  float: left;
}

.ccvi_each input {
  border: none;
  outline: none;
  background-color: rgba(255, 255, 255, 0);
}

.fpsuccess_body {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(30px);
  z-index: 2;
}


/*横屏 css*/
@media screen and (orientation: landscape) {
  .fpb_card {
    top: 130px;
    width: 450px;
    height: 500px;
  }

  .fpb_steps {
    top: 70px;
    width: 100%;
    height: 60px;
  }

  .fpb_inputArea {
    top: 80px;
    width: 100%;
    height: 100px;
  }

  .fpb_input_border {
    width: 80%;
    height: 50%;
    top: 40px;
    left: 10%;
  }

  .fpb_input_border input {
    position: absolute;
    bottom: 2px;
    left: 10px;
    padding-left: 5px;
    width: 80%;
    height: 25px;
  }

  .fpb_input_title {
    position: absolute;
    font-size: 15px;
    top: 15px;
    left: 20px;
  }

  .fpb_alert_msg {
    top: 50px;
  }

  .fpb_submit {
    top: 130px;
  }

  .fpb_submit input {
    width: 85%;
    height: 50%;
    top: 20px;
  }

  .input_tips {
    top: 10px;
    right: 0px;
    width: 30px;
    height: 30px;
  }

  .input_tips_icon {
    position: relative;
    left: 5px;
    top: 5px;
  }

  .ani_each_1_tips {
    top: 50px;
    right: 10px;
    width: 300px;
    height: 85px;
  }

  .tips_into_anim {
    animation: tipsAnim 0.5s;
  }

  @keyframes tipsAnim {
    from {
      transform: translateX(-2%);
    }
    to {
      transform: translateX(0px);
    }
  }
  .password_strength {
    position: absolute;
    right: 60px;
    top: -5px;
    width: 120px;
    height: 50px;
  }

  .password_strength_text {
    position: absolute;
    width: 100px;
    height: 30px;
    top: 10px;
    right: 10px;
  }

  .pst_body {
    position: absolute;
    font-size: 10px;
    float: right;
    top: 12px;
    right: 2px;
  }

  .password_strength_color_border {
    border: none;
    position: relative;
    float: right;
    width: 20px;
    height: 4px;
    right: 10px;
    margin-left: 3px;
    top: 40px;
  }

  /*  验证码*/
  .cc_validateCode {
    width: 100%;
    height: 150px;
    top: 0px;
  }

  .ccv_input_border {
    width: 405px;
    height: 150px;
    top: 10px;
  }

  .ccvi_each {
    width: 60px;
    border-radius: 5px;
    letter-spacing: 5px;
    margin-left: 30px;
    margin-top: 20px;
  }

  .ccvi_each input {
    width: 50px;
    height: 70px;
    padding-left: 12px;
    margin-left: 0px;
    margin-top: -2px;
    letter-spacing: 0px;
    font-size: 55px;
  }


  .checkEmail_btn input {
    width: 400px;
    height: 50px;
    border: none;
  }

  .cc_success_title {
    width: 100%;
    height: 40%;
  }

  .ccs_title {
    top: 40%;
  }

  .ccs_circle {
    width: 100%;
  }

  .ccs_circle_body {
    display: flex;
    top: 80px;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }

}

/*竖屏 css*/
@media screen and (orientation: portrait) {
  .fpb_card {
    top: 10%;
    width: 90%;
    height: 60%;
  }

  .fpb_steps {
    top: 70px;
    width: 100%;
    height: 60px;
  }

  .fpb_inputArea {
    top: 15%;
    width: 100%;
    height: 20%;
  }

  .fpb_input_border {
    width: 80%;
    height: 50%;
    top: 40%;
    left: 10%;
  }

  .fpb_input_border input {
    position: absolute;
    bottom: 2px;
    left: 10px;
    padding-left: 5px;
    width: 60%;
    height: 25px;
    z-index: 4;
  }

  .fpb_input_title {
    position: absolute;
    font-size: 15px;
    top: 15px;
    left: 20px;
  }

  .fpb_alert_msg {
    top: 50px;
  }

  .fpb_submit {
    top: 30%;
  }

  .fpb_submit input {
    width: 88%;
    height: 50%;
    top: 20px;
  }

  .input_tips {
    top: 10px;
    right: 0px;
    width: 30px;
    height: 30px;
  }

  .input_tips_icon {
    position: relative;
    left: 5px;
    top: 5px;
  }

  .ani_each_1_tips {
    top: 50px;
    right: 0px;
    width: 300px;
    height: 85px;
  }

  .tips_into_anim {
    animation: tipsAnim 0.5s;
  }

  @keyframes tipsAnim {
    from {
      transform: translateX(-2%);
    }
    to {
      transform: translateX(0px);
    }
  }
  .password_strength {
    position: absolute;
    right: 60px;
    top: -5px;
    width: 60px;
    height: 50px;
  }

  .password_strength_text {
    position: absolute;
    width: 100px;
    height: 30px;
    top: 0px;
    right: 10px;
  }

  .pst_body {
    position: absolute;
    font-size: 10px;
    float: right;
    top: 12px;
    right: 2px;
  }

  .password_strength_color_border {
    border: none;
    border-radius: 5px;
    position: relative;
    float: right;
    width: 5px;
    height: 4px;
    right: 10px;
    margin-left: 3px;
    top: 40px;
  }

  /*  验证码*/
  .cc_validateCode {
    width: 100%;
    height: 15%;
    top: 0px;
  }

  .ccv_input_border {
    width: 100%;
    height: 150px;
    top: 10px;
  }

  .ccvi_each {
    width: 15%;
    border-radius: 5px;
    letter-spacing: 5px;
    margin-left: 8%;
    margin-top: 20px;
  }

  .ccvi_each input {
    width: 50px;
    height: 70px;
    padding-left: 12px;
    margin-left: 0px;
    margin-top: -2px;
    letter-spacing: 0px;
    font-size: 55px;
  }


  .checkEmail_btn input {
    width: 400px;
    height: 50px;
    border: none;
  }

  .cc_success_title {
    width: 100%;
    height: 40%;
  }

  .ccs_title {
    top: 40%;
  }

  .ccs_circle {
    width: 100%;
  }

  .ccs_circle_body {
    display: flex;
    top: 80px;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }


}


</style>