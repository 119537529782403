<template>
  <div class="admin_hello">欢迎进入管理员系统</div>
</template>

<script>
export default {
  name: "adminhello"
}
</script>

<style scoped>
  .admin_hello{
    margin-top: 20%;
    font-size: 30px;
    font-width: 100;
    text-align: center;
    letter-spacing: 2px;
  }
</style>