<script setup>
  window.open("https://elysova.com/#/store/Flame-store","_self")
</script>

<template>

</template>

<style scoped>

</style>