<template>
  <div class="reg_body public_bgc section doNotSelect">
    <div class="reg_card public_card_color public_black">
      <div class="regc_title  f-20">
        <span class="f-l">Flame Art</span><br>
        <span class="f-b">注册</span>
      </div>
      <div class="regc_input">
        <div class="regc_realname public_gray_border public_input_bgc" id="ani_name_1">
          <div id="ani_firstname" class="regc_item">名字&nbsp;<span style="color: #D94D22">*</span></div>
          <br>
          <input class="public_black" type="text" v-model="this.firstname"
                 @click="initInputBorder('ani_name_1','ani_firstname')"
                 @blur="checkUserInput('ani_name_1',this.firstname,'ani_firstname',0)">
          <div class="noValueAlert public_warning_color" id="ani_firstname_alert"></div>
        </div>

        <div class="regc_realname public_gray_border public_input_bgc" id="ani_name_2">
          <div id="ani_lastname" class="regc_item">姓氏&nbsp;<span style="color: #D94D22">*</span></div>
          <br>
          <input class="public_black" type="text" v-model="this.lastname"
                 @click="initInputBorder('ani_name_2','ani_lastname')"
                 @blur="checkUserInput('ani_name_2',this.lastname,'ani_lastname',1)">
          <div class="noValueAlert public_warning_color" id="ani_lastname_alert"></div>
        </div>

        <div class="regc_each public_gray_border public_input_bgc" id="ani_each_1">
          <div id="ani_nickname" class="regc_item">显示名称&nbsp;<span style="color: #D94D22">*</span></div>
          <br>
          <input class="public_black" type="text" v-model="this.nickname"
                 @click="initInputBorder('ani_each_1','ani_nickname')"
                 @blur="checkUserInput('ani_each_1',this.nickname,'ani_nickname',2)" maxlength="20">
          <div class="noValueAlert public_warning_color" id="ani_nickname_alert"></div>

          <div class="input_tips public_tips_backgroundColor"
               @mouseover="this.nicknameTips = true;this.tips_anim = 'tips_into_anim'"
               @mouseleave="this.nicknameTips = false" @click="this.nicknameTips = true">
            <svg t="1672202106208" class="input_tips_icon public_tips_iconFill" viewBox="0 0 1024 1024" version="1.1"
                 xmlns="http://www.w3.org/2000/svg"
                 p-id="4670" width="21" height="21">
              <path
                  d="M512 64C264.8 64 64 264.8 64 512s200.8 448 448 448 448-200.8 448-448S759.2 64 512 64z m32 704h-64v-64h64v64z m-64-128V256h64v384h-64z"
                  p-id="4671"></path>
            </svg>
          </div>
          <div class="ani_each_1_tips" :class="tips_anim" v-show="nicknameTips">
            <div>您的显示名称必须在3-16个字符之间，可以包含中文、字母、数字以及非连续的横线、下划线和不作结尾的空格</div>
          </div>
        </div>

        <div class="regc_each public_gray_border public_input_bgc" id="ani_each_2">
          <div id="ani_email" class="regc_item">电子邮箱&nbsp;<span style="color: #D94D22">*</span></div>
          <br>
          <input class="public_black" type="text" v-model="this.email"
                 @click="initInputBorder('ani_each_2','ani_email')"
                 @blur="checkUserInput('ani_each_2',this.email,'ani_email',3)">
          <div class="noValueAlert public_warning_color" id="ani_email_alert"></div>
        </div>


        <div class="regc_each public_gray_border public_input_bgc" id="ani_each_3">
          <div id="ani_pwd" class="regc_item">密码&nbsp;<span style="color: #D94D22">*</span></div>
          <br>
          <input class="public_black" :type="pwdInputType" @keyup="checkPassword" v-model="this.password"
                 @click="initInputBorder('ani_each_3','ani_pwd')"
                 @blur="checkUserInput('ani_each_3',this.password,'ani_pwd',4)" maxlength="20">
          <div class="noValueAlert public_warning_color" id="ani_pwd_alert"></div>
          <div class="ani_each_1_tips" :class="tips_anim" v-show="passwordTips">
            <div>密码长度必须在6到18位之间。至少包含1个数字和1个字母，特殊字符有且仅可包含横线和下划线，不能包含空格</div>
          </div>

          <div class="input_tips public_tips_backgroundColor" @mouseover="this.passwordTips = true;this.tips_anim = 'tips_into_anim'"
               @mouseleave="this.passwordTips = false"
               @click="this.passwordTips = !this.passwordTips">
            <svg t="1672202106208" class="input_tips_icon public_tips_iconFill" viewBox="0 0 1024 1024" version="1.1"
                 xmlns="http://www.w3.org/2000/svg"
                 p-id="4670" width="21" height="21">
              <path
                  d="M512 64C264.8 64 64 264.8 64 512s200.8 448 448 448 448-200.8 448-448S759.2 64 512 64z m32 704h-64v-64h64v64z m-64-128V256h64v384h-64z"
                  p-id="4671"></path>
            </svg>
          </div>


          <div class="input_tips public_tips_backgroundColor" style="right: 45px" @click="pwdShoworHide">
            <svg t="1672201369757" class="input_tips_icon public_tips_iconFill" viewBox="0 0 1024 1024" version="1.1"
                 xmlns="http://www.w3.org/2000/svg" p-id="3580" width="21" height="21" v-if="isPwdShow">
              <path
                  d="M512 204.8C269.06624 204.8 40.96 358.68672 40.96 512s228.10624 307.2 471.04 307.2 471.04-153.88672 471.04-307.2-228.10624-307.2-471.04-307.2z"
                  p-id="3581"></path>
              <path
                  d="M512 337.92a174.08 174.08 0 1 0 0 348.16 174.08 174.08 0 0 0 0-348.16z m0 61.44a112.64 112.64 0 1 1 0 225.28 112.64 112.64 0 0 1 0-225.28z"
                  fill="#FFFFFF" p-id="3582"></path>
            </svg>

            <svg t="1672201563313" class="input_tips_icon public_tips_iconFill" viewBox="0 0 1024 1024" version="1.1"
                 xmlns="http://www.w3.org/2000/svg"
                 p-id="3728" width="21" height="21" v-if="!isPwdShow">
              <path
                  d="M983.04 512c0 153.31328-228.10624 307.2-471.04 307.2-90.3168 0-178.56512-21.25824-253.80864-55.86944l129.49504-129.47456a174.08 174.08 0 1 0 246.1696-246.1696l128.57344-128.57344C891.392 317.44 983.04 414.8224 983.04 512zM512 204.8c65.00352 0 128.96256 11.01824 187.76064 30.1056l-117.69856 117.69856a174.08 174.08 0 0 0-229.4784 229.4784l-151.18336 151.1424C105.02144 674.67264 40.96 593.26464 40.96 512c0-153.31328 228.10624-307.2 471.04-307.2z m112.64 307.2a112.64 112.64 0 0 1-193.49504 78.41792l159.27296-159.27296c21.0944 20.48 34.22208 49.13152 34.22208 80.85504zM512 399.36c7.2704 0 14.37696 0.69632 21.27872 2.00704l-131.91168 131.8912A112.64 112.64 0 0 1 512 399.36z"
                  p-id="3729"></path>
              <path
                  d="M872.796352 148.719008m21.72232 21.72232l0 0q21.72232 21.72232 0 43.444641l-680.632703 680.632703q-21.72232 21.72232-43.444641 0l0 0q-21.72232-21.72232 0-43.444641l680.632703-680.632703q21.72232-21.72232 43.444641 0Z"
                  p-id="3730"></path>
            </svg>
          </div>
          <div class="password_strength">
            <div class="password_strength_text f-l">
              <div id="strength_text" class="pst_body"></div>
            </div>
            <div class="password_strength_color">
              <div class="password_strength_color_border" id="strength1" v-show="this.pwdStrength1"></div>
              <div class="password_strength_color_border" id="strength2" v-show="this.pwdStrength2"></div>
              <div class="password_strength_color_border" id="strength3" v-show="this.pwdStrength3"></div>
            </div>
          </div>
        </div>

        <div class="regc_each public_gray_border public_input_bgc" id="ani_each_4">
          <div id="ani_ConfirmPwd" class="regc_item">确认密码&nbsp;<span style="color: #D94D22">*</span></div>
          <br>
          <input class="public_black" :type="pwdInputType" v-model="this.confirmPassword"
                 @click="initInputBorder('ani_each_4','ani_ConfirmPwd')"
                 @blur="checkUserInput('ani_each_4',this.confirmPassword,'ani_ConfirmPwd',5)" maxlength="20">
          <div class="noValueAlert public_warning_color" id="ani_ConfirmPwd_alert"></div>

        </div>
        <div class="regc_recaptcha">
          <recaptcha :theme="recaptchaStyle" @getReCAPTCHACode='getReCAPTCHACode' v-model="recaptchaCode"></recaptcha>
        </div>
        <div>
          <input type="button" id="register_submit_btn" class="regc_submit public_submit_color" value="注册"
                 @click="register">
          <div class="ta-c mt-4 f-15 f-l">已有帐户？<a :href="this.loginUrl" class="public_themeColor_link t-underline">登录</a></div>
        </div>
      </div>

    </div>


    <transition name="fade">
      <div class="checkEm_body doNotSelect" v-show="this.vCodeWindowShow">
        <div class="checkEm_card public_card_color public_black">
          <div class="public_tips_backgroundColor cb_closeIcon " @click="closeVCodeClearStorage" title="关闭页面">
            <svg t="1672314848842" class="icon public_tips_iconFill " viewBox="0 0 1024 1024" version="1.1"
                 xmlns="http://www.w3.org/2000/svg"
                 p-id="2734" width="20" height="20">
              <path
                  d="M557.3 512l329.3-329.4a32 32 0 1 0-45.2-45.2L512 466.7 182.6 137.4a32 32 0 1 0-45.2 45.2L466.7 512 137.4 841.4a31.9 31.9 0 0 0 0 45.2 31.9 31.9 0 0 0 45.2 0L512 557.3l329.4 329.3a31.9 31.9 0 0 0 45.2 0 31.9 31.9 0 0 0 0-45.2z"
                  p-id="2735"></path>
            </svg>
          </div>
          <div class="cc_title">
            <div class="cct_tips f-20 f-l">即将完成注册...</div>
            <div class="cct_title cct_title_phone public_gray">我们发送了一封携带验证码的邮件到您的邮箱上</div>
            <div class="cct_title cct_maintitle_phone f-40 f-b p-r t-20">请输入4位验证码</div>
          </div>
          <div class="cc_validateCode">
            <div class="ccv_input_border">
              <div class="ccvi_each public_gray_border public_input_bgc">
                <input class="public_gray" v-model="input0" ref="input0" @click="vCodeBorderAlert(false)"
                       @keydown="deleteValue($event,'input0','input0')"
                       @keyup="changeValue($event,'input0','input1')" type="text" maxlength="1">
              </div>
              <div class="ccvi_each public_gray_border public_input_bgc">
                <input class="public_gray" v-model="input1" ref="input1" @click="vCodeBorderAlert(false)"
                       @keydown="deleteValue($event,'input1','input0')"
                       @keyup="changeValue($event,'input1','input2')" type="text" maxlength="1">
              </div>
              <div class="ccvi_each public_gray_border public_input_bgc">
                <input class="public_gray" v-model="input2" ref="input2" @click="vCodeBorderAlert(false)"
                       @keydown="deleteValue($event,'input2','input1')"
                       @keyup="changeValue($event,'input2','input3')" type="text" maxlength="1">
              </div>
              <div class="ccvi_each public_gray_border public_input_bgc">
                <input class="public_gray" v-model="input3" ref="input3" @click="vCodeBorderAlert(false)"
                       @keydown="deleteValue($event,'input3','input2')"
                       @keyup="changeValue($event,'input3','input3')" type="text" maxlength="1">
              </div>
            </div>
            <div>
            </div>
          </div>
          <div class="checkEmail_btn">
            <input id="checkEmail_submit_button" type="button" value="完成注册" class="public_submit_color"
                   @click="completeRegister">
          </div>
          <div class="cc_checkHelp">
            <div class="cch_line f-l f-10 public_gray">——————— 帮助 ———————</div>
            <div class="ccc_fsize_phone public_gray ta-c f-15 mt-2">没有收到验证码？
              <a id="sendEmailAgain" @click="sendEmailAgain"
                 style="color: #D94D22">{{ this.sendEmailAgainTips }}</a>
            </div>
            <div class="ccc_fsize_phone public_gray ta-c f-15 mt-2">{{ this.email }}不是您的邮箱？
              <a @click="closeVCodeClearStorage"
                 style="color: #D94D22">点此重新输入邮箱</a>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <div class="checkEm_body doNotSelect" v-show="this.regSuccessTab">
      <div class="checkEm_card public_card_color public_black">
        <div class="cc_success_title">
          <div class="ccs_title f-40 f-b p-r ta-c">注册成功</div>
          <div class="ccs_title f-25 f-l p-r ta-c mt-5">正在自动跳转</div>
        </div>
        <div class="ccs_circle">
          <el-progress class="ccs_circle_body" type="circle" :percentage="this.regSuccessPercent" status="success"
                       :width="150">
            <div class="f-30">{{ this.regSuccessTimes }}</div>
          </el-progress>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import * as $ from "jquery";
import request from "@/utils/request";
import router from "@/router";
import recaptcha from "@/components/recaptcha";
import {ref} from "vue";

export default {
  name: 'userregister',
  components: {
    recaptcha
  },
  data() {
    return {
      //网页风格
      theme: false,
      //网页跳转链接
      loginUrl :"/#/user/login?redirectUrl=" + encodeURIComponent(window.location.origin) + encodeURIComponent(window.location.pathname),
      forgotPasswordUrl :"/#/user/forgot-password?redirectUrl=" + encodeURIComponent(window.location.origin) + encodeURIComponent(window.location.pathname),
      //用户输入数据
      lastname: "",
      firstname: "",
      nickname: "",
      email: "",
      password: "",
      confirmPassword: "",

      //用户邮箱验证,当验证失败时记录
      failEmailLog: "",

      //用户输入提示是否显示
      nicknameTips: false,
      passwordTips: false,

      //用户输入提示动画CSS类
      tips_anim: "",

      //用户输入是否合法Flag,点击注册时验证
      userInputFlag: [
        {
          Flag: false,
        }, {
          Flag: false,
        }, {
          Flag: false,
        }, {
          Flag: false,
        }, {
          Flag: false,
        }, {
          Flag: false,
        }
      ],
      //密码显示与隐藏
      pwdInputType: "password",
      //密码显示与隐藏图标Flag
      isPwdShow: false,
      //密码强度判定
      pwdStrength1: true,
      pwdStrength2: true,
      pwdStrength3: true,

      //二次警告
      registerBtnSecAlert:false,

      //reCAPTCHA
      recaptchaStyle: localStorage.getItem("WebTheme"),
      recaptchaCode: "",

      //验证码
      vCodeWindowShow: false,
      validateCode: '',
      input0: '',
      input1: '',
      input2: '',
      input3: '',
      sendEmailAgainTips: "点击此处再次发送",
      sendEmailAgainTimes: 0,
      sendEmailDisabled: false,

      //注册成功
      regSuccessTab: false,
      regSuccessTimes: 3,
      regSuccessPercent: 0,

      //redirct
      redirect: decodeURIComponent(this.$route.query.redirectUrl),
    }
  },
  mounted() {
    // console.log(this.redirect)
    this.checkWebTheme()

  },
  methods: {
    checkWebTheme() {
      $("#body").removeClass("changeDarkThemeAnim")
      $("#body").removeClass("changeLightThemeAnim")
      if (localStorage.getItem("WebTheme") === 'dark') {
        $("#body").addClass("dark")
        this.theme = true
      } else {
        $("#body").removeClass("dark")
        this.theme = false
      }
    },

    checkUserInput(inputName, inputValue, titleID, UserInputFlagNum) {
      if (inputValue === "") {
        this.inputBorderChangeAlert(inputName, titleID, '必填', UserInputFlagNum)
        $('#' + titleID).stop(true, false).animate({
          left: '20px',
          top: '15px',
          fontSize: '15px',
        }, 100)

      } else {
        switch (titleID) {
          case 'ani_firstname':
            const firstnameCheckReg = /^[\u4E00-\u9FA5A-Za-z]+$/;
            if (inputValue.length > 85) {
              this.inputBorderChangeAlert(inputName, titleID, '过长', UserInputFlagNum)
            } else if (!firstnameCheckReg.test(inputValue)) {
              this.inputBorderChangeAlert(inputName, titleID, '格式错误', UserInputFlagNum)
            } else {
              this.userInputFlag[UserInputFlagNum].Flag = true
            }
            break;
          case 'ani_lastname':
            const lastnameCheckReg = /^[\u4E00-\u9FA5A-Za-z]+$/;
            if (inputValue.length > 85) {
              this.inputBorderChangeAlert(inputName, titleID, '过长', UserInputFlagNum)
            } else if (!lastnameCheckReg.test(inputValue)) {
              this.inputBorderChangeAlert(inputName, titleID, '格式错误', UserInputFlagNum)
            } else {
              this.userInputFlag[UserInputFlagNum].Flag = true
            }
            break;
          case 'ani_nickname':
            const nicknameCheckReg = /^[\u4E00-\u9FA5-A-Za-z0-9-_]+(?:[\s][A-Za-z0-9]+)*$/;
            if (inputValue.length < 3) {
              this.inputBorderChangeAlert(inputName, titleID, '过短', UserInputFlagNum)
            } else if (inputValue.length > 16) {
              this.inputBorderChangeAlert(inputName, titleID, '过长', UserInputFlagNum)
            } else if (!nicknameCheckReg.test(inputValue)) {
              this.inputBorderChangeAlert(inputName, titleID, '格式错误', UserInputFlagNum)
            } else {
              request.post("user/checkNickname", {'nickname': inputValue})
                  .then(res => {
                    if (res) {
                      this.userInputFlag[UserInputFlagNum].Flag = true
                    } else {
                      this.failEmailLog = inputValue
                      this.inputBorderChangeAlert(inputName, titleID, '名称已被占用', UserInputFlagNum)
                    }
                  })
            }
            break;
          case 'ani_email' :
            const emailCheckReg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;
            if (emailCheckReg.test(inputValue)) {
              if (inputValue === this.failEmailLog) {
                this.inputBorderChangeAlert(inputName, titleID, '电子邮箱已被占用', UserInputFlagNum)
              } else {
                request.post("user/checkEmail", {'email': inputValue})
                    .then(res => {
                      if (res) {
                        this.userInputFlag[UserInputFlagNum].Flag = true
                      } else {
                        this.failEmailLog = inputValue
                        this.inputBorderChangeAlert(inputName, titleID, '电子邮箱已被占用', UserInputFlagNum)
                      }
                    })
              }

            } else {
              this.inputBorderChangeAlert(inputName, titleID, '电子邮箱地址无效', UserInputFlagNum)
            }
            break;
          case 'ani_pwd':
            // console.log(inputValue + ": " + this.confirmPassword)
            const passwordCheckReg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z_-]{6,18}$/
            if (inputValue.length < 6) {
              this.inputBorderChangeAlert(inputName, titleID, '过短', UserInputFlagNum)
            } else if (inputValue.length > 18) {
              this.inputBorderChangeAlert(inputName, titleID, '过长', UserInputFlagNum)
            } else if (!passwordCheckReg.test(inputValue)) {
              this.inputBorderChangeAlert(inputName, titleID, '格式错误', UserInputFlagNum)
            } else if ((inputValue === this.confirmPassword) && (this.confirmPassword !== "")) {
              this.userInputFlag[UserInputFlagNum].Flag = true
              this.userInputFlag[UserInputFlagNum + 1].Flag = true
              // console.log(this.userInputFlag[5].Flag)
            } else {
              this.userInputFlag[UserInputFlagNum].Flag = true
            }
            break;
          case 'ani_ConfirmPwd':
            if (inputValue !== this.password) {
              this.inputBorderChangeAlert(inputName, titleID, '确认密码请与密码一致', UserInputFlagNum)
            } else {
              this.userInputFlag[UserInputFlagNum].Flag = true
            }
            break;
        }


      }
    },

    initInputBorder(inputName, titleID) {
      this.registerButtonAlert(true)
      $('#' + titleID + '_alert').text('')
      $('#' + titleID).stop(true, false).animate({
        left: '15px',
        top: '5px',
        fontSize: '10px',
      }, 100)
      // $('#' + inputName).addClass('public_gray_border')
      $('#' + inputName).removeClass('border_Warning')
    },

    inputBorderChangeAlert(inputName, titleID, AlertMsg, UserInputFlagNum) {
      this.registerBtnSecAlert = false
      this.userInputFlag[UserInputFlagNum].Flag = false
      $('#' + titleID + '_alert').text(AlertMsg)
      $('#' + inputName).addClass('border_Warning')
      // $('#' + inputName).removeClass('public_gray_border')
      // $('#' + inputName).addClass('public_warning_border')
    },

    registerButtonAlert(Flag, tips) {
      if (Flag) {
        this.registerBtnSecAlert = true
        $('#register_submit_btn').removeClass('public_submit_Alert').addClass('public_submit_color')
        $('#register_submit_btn').val('注册')
      } else {
        this.registerBtnSecAlert = false
        $('#register_submit_btn').removeClass('public_submit_color').addClass('public_submit_Alert')
        $('#register_submit_btn').val(tips)
      }
    },

    pwdShoworHide() {
      if (this.isPwdShow) {
        this.pwdInputType = "password"
      } else {
        this.pwdInputType = "text"
      }
      this.isPwdShow = !this.isPwdShow
    },
    checkPassword() {
      let reg = 0;
      if (this.password === "") {
        reg = 0
      } else if (this.password.length < 6) {
        reg = 1
      } else {
        if (/\d/.test(this.password)) {
          reg++
        }
        if (/[a-z]/.test(this.password)) {
          reg++
        }
        if (/[A-Z]/.test(this.password)) {
          reg++
        }
        if (/\W/.test(this.password)) {
          reg++
        }
        if (!/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z_-]{6,18}$/.test(this.password)) {
          reg = 5
        }
      }
      switch (reg) {
        case 0:
          document.getElementById("strength_text").innerText = ""
          this.pwdStrength1 = false
          this.pwdStrength2 = false
          this.pwdStrength3 = false
          break
        case 1:
          $('#strength_text').text("低");
          $('#strength1').css("backgroundColor", "#9a2521");
          $('#strength2').css("backgroundColor", "none");
          $('#strength3').css("backgroundColor", "none");
          this.pwdStrength1 = true
          this.pwdStrength2 = false
          this.pwdStrength3 = false
          break
        case 2:
          $('#strength_text').text("中");
          $('#strength1').css("backgroundColor", "#ffc547");
          $('#strength2').css("backgroundColor", "#ffc547");
          $('#strength3').css("backgroundColor", "none");
          this.pwdStrength1 = true
          this.pwdStrength2 = true
          this.pwdStrength3 = false
          break
        case 3:
          $('#strength_text').text("高");
          $('#strength1').css("backgroundColor", "#498f4b");
          $('#strength2').css("backgroundColor", "#498f4b");
          $('#strength3').css("backgroundColor", "#498f4b");
          this.pwdStrength1 = true
          this.pwdStrength2 = true
          this.pwdStrength3 = true
          break
        case 4:
          $('#strength_text').text("非常高");
          $('#strength1').css("backgroundColor", "#1fa4c4");
          $('#strength2').css("backgroundColor", "#1fa4c4");
          $('#strength3').css("backgroundColor", "#1fa4c4");
          this.pwdStrength1 = true
          this.pwdStrength2 = true
          this.pwdStrength3 = true
          break
        case 5:
          $('#strength_text').text("格式错误");
          $('#strength1').css("backgroundColor", "#380d0c");
          $('#strength2').css("backgroundColor", "#380d0c");
          $('#strength3').css("backgroundColor", "#380d0c");
          this.pwdStrength1 = true
          this.pwdStrength2 = true
          this.pwdStrength3 = true
          break
      }
    },

    register() {
      let canRegister = true;

      if (!this.registerBtnSecAlert) {
        // console.log('执行了动画')
        this.buttonSecondAlert('register_submit_btn')
      }

      if (!this.recaptchaCode) {
        this.registerButtonAlert(false, '请进行人机验证')
        this.registerBtnSecAlert = false
      } else {
        for (let i = 0; i < this.userInputFlag.length; i++) {
          if (!this.userInputFlag[i].Flag) {
            canRegister = false
            break
          }
        }
        // console.log(this.userInputFlag)
        if (canRegister) {
          // console.log(this.password)
          if (this.password === this.confirmPassword) {
            this.registerBtnSecAlert = true
            this.submitButtonAlert(true)
            this.vCodeWindowShow = true
            this.sendValidateCode()
            // console.log('success')
          } else {
            $('#ani_ConfirmPwd_alert').text('确认密码请与密码一致')
            // $('#ani_each_4').removeClass('public_gray_border')
            $('#ani_each_4').addClass('border_Warning')
            this.registerButtonAlert(false, '请检查确认密码')
            // console.log('false')
          }
        } else {
          this.registerButtonAlert(false, '请检查输入内容')
          // console.log('false')
        }
      }
    },

    getReCAPTCHACode(value) {
      this.recaptchaCode = value
    },


    //验证码
    deleteValue(e, inputValue, previousItem) { // 键盘按下时$event，当前input，上一个input
      // console.log(inputValue, previousItem)
      if (e.keyCode === 8) {
        if (this[inputValue].length > 0) { // 当前有值，清空
          this[inputValue] = ''
        } else { // 当前没有值，光标跳转到上一个input，并清空该input值
          this.$nextTick(() => {
            this.$refs[previousItem].focus()
            this[previousItem] = ''
            this[inputValue] = ''
          })
        }
      }

    },
    submitButtonAlert(Flag, tips) {
      if (Flag) {
        $('#checkEmail_submit_button').removeClass('public_submit_Alert').addClass('public_submit_color')
        $('#checkEmail_submit_button').val('完成注册')
      } else {
        $('#checkEmail_submit_button').removeClass('public_submit_color').addClass('public_submit_Alert')
        $('#checkEmail_submit_button').val(tips)
      }
    },
    changeValue(e, inputValue, nextItem) { // 键盘抬起时$event，当前input，下一个input
      // console.log(e.keyCode, this[inputValue], this[nextItem])
      if (e.keyCode !== 8) {
        this.$nextTick(() => {
          this.$refs[nextItem].focus() // 截取当前值最后一位，跳到下一个input
          // this[inputValue] = (this[inputValue]).toString().slice(-1)
        })
      }
    },
    vCodeBorderAlert(FLag) {
      if (FLag) {
        $('.ccvi_each').removeClass('public_gray_border')
        $('.ccvi_each').addClass('border_Warning')
      } else {
        $('.ccvi_each').addClass('public_gray_border')
        $('.ccvi_each').removeClass('border_Warning')
        this.submitButtonAlert(true)
      }
    },
    completeRegister() {
      this.submitButtonAlert(true)
      if (!this.registerBtnSecAlert) {
        // console.log('执行了动画')
        this.buttonSecondAlert('checkEmail_submit_button')
      }
      if (this.input0 === '' || this.input1 === '' || this.input2 === '' || this.input3 === '') {
        this.registerBtnSecAlert = false
        this.submitButtonAlert(false, '请输入完整验证码')
        this.vCodeBorderAlert(true)
      } else {
        request.get("user/checkRegisterValidateCode", {
          params: {
            'nickname': this.nickname,
            'realname': this.lastname + this.firstname,
            'email': this.email,
            'pwd': this.password,
            'inputCode': this.input0 + this.input1 + this.input2 + this.input3
          }
        }).then(res => {
          if (res) {
            //注册成功
            this.registerBtnSecAlert = true
            this.registerSuccessTable()

          } else {
            this.registerBtnSecAlert = false
            this.submitButtonAlert(false, '验证码错误')
            this.vCodeBorderAlert(true)
          }
          // console.log(res)
        })
      }
      // console.log(this.input0 + this.input1 + this.input2 + this.input3);
    },

    sendValidateCode() {
      request.post("user/sendValidateCodeEmail", {'email': this.email})
    },

    closeVCodeClearStorage() {
      this.vCodeWindowShow = false
      request.get("user/clearDisabledEmail", {
        params: {
          'email': this.email,
        }
      })
    },

    sendEmailAgain() {
      this.sendValidateCode()
      this.sendEmailAgainTimes = 30;
      this.sendTimer();
    },

    sendTimer() {
      if (this.sendEmailAgainTimes > 0) {
        $('#sendEmailAgain').addClass('doNotPointer')
        this.sendEmailDisabled = true;
        this.sendEmailAgainTimes--;
        this.sendEmailAgainTips = this.sendEmailAgainTimes + "秒后再次获取";
        setTimeout(this.sendTimer, 1000);
      } else {
        $('#sendEmailAgain').removeClass('doNotPointer')
        this.sendEmailAgainTimes = 0;
        this.sendEmailAgainTips = "点击此处再次发送";
        this.sendEmailDisabled = false;
      }
    },

    registerSuccessTable() {
      this.regSuccessTab = true;
      this.registerSuccessCircle()
      this.registerSuccessCirclePercent()
    },

    registerSuccessCircle() {
      if (this.regSuccessTimes > 0) {
        this.regSuccessTimes--;
        setTimeout(this.registerSuccessCircle, 1000);
      } else {
        window.open(this.redirect + "?redirectUrl=" + encodeURIComponent(window.location.origin) + encodeURIComponent(window.location.pathname),'_self');
      }
    },

    registerSuccessCirclePercent() {
      if (this.regSuccessPercent < 100) {
        this.regSuccessPercent++;
        setTimeout(this.registerSuccessCirclePercent, 15);
      } else {
        this.regSuccessPercent = 100;
      }
    },

    buttonSecondAlert(BtnID) {
      $('#' + BtnID).addClass('public_submit_secAlert')
      setTimeout(() => {
        $("#" + BtnID).removeClass("public_submit_secAlert")
      }, 10);
    }


  },
}


</script>

<style src="@/style/FlameTheme.css" scoped/>
<style src="@/style/FlameDarkTheme.css" scoped/>
<style src="@/style/PublicStyle.css" scoped/>
<style scoped>
.reg_body {
  overflow-y: scroll;
  width: inherit;
  height: inherit;
}

.reg_card {
  position: relative;
  margin: 0 auto;
}

.regc_title {
  text-align: center;
  float: left;
}

.regc_realname {
  font-weight: 100;
  border-radius: 5px;
  position: relative;
  float: left;
}

.regc_realname input {
  background-color: rgba(255, 255, 255, 0);
  border: none;
  font-size: 15px;
  padding-left: 10px;
  outline: none;
}

.regc_each {
  font-weight: 100;
  border-radius: 5px;
  position: relative;
  float: left;
}

.regc_each input {
  background-color: rgba(255, 255, 255, 0);
  border: none;
  font-size: 15px;
  font-weight: 100;
  padding-left: 10px;
  outline: none;
}


.input_tips {
  position: absolute;
  border-radius: 5px;
  float: right;
}

.ani_each_1_tips {
  position: relative;
  float: right;
  border-radius: 5px;
  z-index: 1;
}

.ani_each_1_tips div {
  font-size: 15px;
  width: 90%;
  position: relative;
  left: 5%;
  top: 13%;
}

.noValueAlert {
  font-size: 10px;
}

/*验证码*/
.checkEm_body {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(30px);
  z-index: 2;
}

.checkEm_card {
  position: relative;
  margin: 0 auto;
}

.cc_title {
  position: absolute;
  text-align: center;
}

.cc_validateCode {
  position: relative;
}

.ccv_input_border {
  position: relative;
  margin: 0 auto;
}

.ccvi_each {
  position: relative;
  float: left;
}

.ccvi_each input {
  border: none;
  outline: none;
  background-color: rgba(255, 255, 255, 0);
}

.checkEmail_btn {
  position: relative;
  float: left;
}


/*横屏 css*/
@media screen and (orientation: landscape) {
  .reg_card {
    position: relative;
    top: 5%;
    width: 450px;
    height: 700px;
  }

  .regc_title {
    width: 100%;
    margin-top: 20px;
  }

  .regc_realname {
    margin-left: 6%;
    margin-top: 5%;
    width: 40%;
    height: 55px;
  }

  .regc_realname input {
    width: 80%;
    height: 25px;
    margin-top: 5px;
    margin-left: 5px;
  }

  .regc_realname .regc_item {
    position: absolute;
    font-size: 15px;
    left: 20px;
    top: 15px;
  }

  .regc_each {
    margin-left: 6%;
    margin-top: 5%;
    width: 87%;
    height: 55px;
  }

  .regc_each input {
    width: 80%;
    height: 25px;
    margin-top: 5px;
    margin-left: 5px;
  }

  .regc_each .regc_item {
    position: absolute;
    font-size: 15px;
    left: 20px;
    top: 15px;
  }

  .regc_recaptcha {
    position: relative;
    float: left;
    top: 25px;
    left: 25px;
    transform: scale(0.85);
    transform-origin: 0 0;
  }

  .regc_submit {
    border: none;
    border-radius: 5px;
    margin-left: 6%;
    margin-top: 35px;
    width: 87%;
    height: 55px;
  }

  .password_strength {
    position: absolute;
    right: 80px;
    top: 0px;
    width: 120px;
    height: 50px;
  }

  .password_strength_text {
    position: absolute;
    width: 100px;
    height: 30px;
    top: 10px;
    right: 10px;
  }

  .pst_body {
    position: absolute;
    font-size: 10px;
    float: right;
    top: 12px;
    right: 2px;
  }

  .password_strength_color_border {
    border: none;
    position: relative;
    float: right;
    width: 29px;
    height: 4px;
    right: 10px;
    margin-left: 3px;
    top: 40px;
  }

  .input_tips {
    top: 15px;
    right: 10px;
    width: 30px;
    height: 30px;
  }

  .input_tips_icon {
    position: relative;
    left: 5px;
    top: 5px;
  }

  .ani_each_1_tips {
    top: -5px;
    right: 10px;
    width: 300px;
    height: 85px;
  }

  .tips_into_anim {
    animation: tipsAnim 0.5s;
  }

  @keyframes tipsAnim {
    from {
      transform: translateX(-2%);
    }
    to {
      transform: translateX(0px);
    }
  }

  .noValueAlert {
    position: relative;
    left: 0px;
    top: 5px;
  }


  /*  验证码*/
  .checkEm_card {
    width: 600px;
    height: 600px;
    top: 80px;
  }

  .cc_title {
    width: 100%;
    height: 120px;
    top: 20px;
  }

  .cct_tips, .cct_title {
    margin-top: 20px;
  }

  .cc_validateCode {
    width: 100%;
    height: 200px;
    top: 150px;
  }

  .ccv_input_border {
    width: 405px;
    height: 150px;
    top: 90px;
  }

  .ccvi_each {
    width: 60px;
    border-radius: 5px;
    letter-spacing: 5px;
    margin-left: 30px;
    margin-top: 20px;
  }

  .ccvi_each input {
    width: 50px;
    height: 100px;
    padding-left: 10px;
    margin-left: 0px;
    margin-top: -2px;
    letter-spacing: 0px;
    font-size: 70px;
  }

  .checkEmail_btn {
    left: 100px;
    top: 220px;
  }

  .checkEmail_btn input {
    width: 400px;
    height: 50px;
    border: none;
  }

  .cb_closeIcon {
    position: absolute;
    width: 26px;
    height: 26px;
    right: 20px;
    top: 20px;
    z-index: 3;
  }

  .cb_closeIcon .icon {
    position: absolute;
    left: 3px;
    top: 3px;
  }

  .cc_checkHelp {
    position: absolute;
    bottom: 0;
    height: 120px;
    width: inherit;
  }

  .cch_line {
    text-align: center;
  }

  .cc_success_title {
    width: 100%;
    height: 40%;
  }

  .ccs_title {
    top: 50%;
  }

  .ccs_circle {
    width: 100%;
  }

  .ccs_circle_body {
    display: flex;
    top: 80px;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
}

/*竖屏 css*/
@media screen and (orientation: portrait) {
  .reg_card {
    position: relative;
    top: 5%;
    width: 90%;
    height: 700px;
  }

  .regc_title {
    width: 100%;
    margin-top: 20px;
  }

  .regc_realname {
    margin-left: 6%;
    margin-top: 5%;
    width: 40%;
    height: 55px;
  }

  .regc_realname input {
    width: 80%;
    height: 25px;
    margin-top: 5px;
    margin-left: 5px;
  }

  .regc_realname .regc_item {
    position: absolute;
    font-size: 15px;
    left: 20px;
    top: 15px;
  }

  .regc_each {
    margin-left: 6%;
    margin-top: 7%;
    width: 87%;
    height: 55px;
  }

  .regc_each input {
    width: 80%;
    height: 25px;
    margin-top: 5px;
    margin-left: 5px;
  }

  .regc_each .regc_item {
    position: absolute;
    font-size: 15px;
    left: 20px;
    top: 15px;
  }

  .regc_recaptcha {
    position: relative;
    float: left;
    top: 25px;
    left: 6%;
    transform: scale(0.85);
    transform-origin: 0 0;
  }

  .regc_submit {
    border: none;
    border-radius: 5px;
    margin-left: 6%;
    margin-top: 30px;
    width: 87%;
    height: 55px;
  }

  .password_strength {
    position: absolute;
    right: 80px;
    top: 0px;
    width: 120px;
    height: 50px;
  }

  .password_strength_text {
    position: absolute;
    width: 100px;
    height: 30px;
    top: 10px;
    right: 10px;
  }

  .pst_body {
    position: absolute;
    font-size: 10px;
    float: right;
    top: 12px;
    right: 2px;
  }

  .password_strength_color_border {
    border: none;
    position: relative;
    float: right;
    width: 29px;
    height: 4px;
    right: 10px;
    margin-left: 3px;
    top: 40px;
  }

  .input_tips {
    top: 15px;
    right: 10px;
    width: 30px;
    height: 30px;
  }

  .input_tips_icon {
    position: relative;
    left: 5px;
    top: 5px;
  }

  .ani_each_1_tips {
    top: -5px;
    right: 10px;
    width: 300px;
    height: 85px;
  }

  .tips_into_anim {
    animation: tipsAnim 0.5s;
  }

  @keyframes tipsAnim {
    from {
      transform: translateX(-2%);
    }
    to {
      transform: translateX(0px);
    }
  }

  .noValueAlert {
    position: relative;
    left: 0px;
    top: 5px;
  }


  /*  验证码*/
  .checkEm_card {
    width: 80%;
    height: 70%;
    top: 10%;
  }

  .cc_title {
    width: 100%;
    height: 20%;
    top: 20px;
  }

  .cct_title_phone {
    font-size: 10px;
  }

  .cct_maintitle_phone {
    font-size: 25px;
  }

  .cct_tips, .cct_title {
    margin-top: 20px;
  }

  .cc_validateCode {
    width: 100%;
    height: 20%;
    top: 30%;
  }

  .ccv_input_border {
    width: 80%;
    height: 30%;
    top: 20%;
  }

  .ccvi_each {
    width: 20%;
    height: 80px;
    border-radius: 5px;
    letter-spacing: 5px;
    margin-left: 3%;
    margin-top: 20%;
  }

  .ccvi_each input {
    width: 70%;
    padding-left: 25%;
    font-size: 50px;
  }

  .checkEmail_btn {
    left: 10%;
    top: 40%;
    width: 100%;
  }

  .checkEmail_btn input {
    width: 80%;
    height: 40px;
    border: none;
  }

  .cb_closeIcon {
    position: absolute;
    width: 26px;
    height: 26px;
    right: 20px;
    top: 20px;
    z-index: 3;
  }

  .cb_closeIcon .icon {
    position: absolute;
    left: 3px;
    top: 3px;
  }

  .cc_checkHelp {
    position: absolute;
    bottom: 0;
    height: 20%;
    width: 100%;
  }

  .cch_line {
    text-align: center;
  }

  .ccc_fsize_phone {
    font-size: 10px;
  }

  .cc_success_title {
    width: 100%;
    height: 40%;
  }

  .ccs_title {
    top: 50%;
  }

  .ccs_circle {
    width: 100%;
  }

  .ccs_circle_body {
    display: flex;
    top: 80px;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }

}
</style>