<template>
  <FlameNavBar/>
  <div class="checkorder_body public_bgc section">
    <div class="cob_main">
      <div class="cobm_img">
        <img class="cobm_img_body" :src="this.staticFileURL+'artworkImage/' + this.urlArtworkImg" alt="">
      </div>
      <div class="cobm_data public_card_color public_black">
        <div class="cobmd_title f-b doNotSelect">确认订单 | Flame Store</div>
        <div class="cob_line"/>
        <div class="cobmd_each f-l public_gray">艺术品名：
          <span class="f-b public_black">{{ this.orderArtworkMsg.namecn }}</span>
        </div>
        <div class="cobmd_each f-l public_gray">艺术品价格：
          <span class="f-b public_black">{{ this.orderArtworkMsg.price }}&nbsp;</span>
          <span class="public_Flame_themeColor">CNY</span>
        </div>
        <div class="cobmd_each f-l public_gray">
          <span v-show="!this.orderArtworkMsg.virtualentity">配送费：<span
              class="f-b public_black">{{ this.orderArtworkMsg.pricetrans }}&nbsp;</span><span
              class="public_Flame_themeColor">CNY</span></span>
          <span v-show="this.orderArtworkMsg.virtualentity">虚拟艺术品，无需配送。</span>
        </div>
        <div class="cobmd_last f-b public_gray">
          <span>需支付：<span class="public_black">{{ this.totalPriceShow }}&nbsp;</span><span
              class="public_Flame_themeColor">CNY</span></span>
        </div>
        <div class="cobmd_confirm_btn">
          <input id="pay_submit_btn" @click="payOrder" class="cobmd_confirm_btn_pay public_submit_color pointer"
                 type="button" value="购买">
          <input @click="cancelOrderForm" class="cobmd_confirm_btn_cancel public_black pointer" type="button"
                 value="取消订单">
        </div>

      </div>
    </div>
    <successRedirect :sucRedirectURL="this.redirect+'?artworkID='+this.urlArtworkID" ref="successRedirect"/>
  </div>
</template>

<script>
import FlameNavBar from '@/components/Flame-NavBar'
import successRedirect from '@/components/successredirect'
import router from "@/router";
import request from "@/utils/request";
import * as $ from "jquery";

export default {
  name: "checkorder",
  components: {
    FlameNavBar,
    successRedirect,
  },
  data() {
    return {
      //ArtworkImgURL
      staticFileURL: this.$globalVar + '/files/',

      orderArtworkMsg: {
        uuid: '',
        namecn: '',
        price: '',
        pricetrans: '',
        virtualentity: ''
      },
      totalPrice: '',
      totalPriceShow: '',
      //二次警告
      payButtonSecAlert: true,
      //urlID
      urlArtworkID: decodeURIComponent(router.currentRoute.value.query.artworkID),
      //orderID
      orderID: decodeURIComponent(router.currentRoute.value.query.orderID),
      //imgurl
      urlArtworkImg: decodeURIComponent(router.currentRoute.value.query.artworkImage),
      //redirect
      redirect: decodeURIComponent(router.currentRoute.value.query.redirectUrl),
    }
  },
  mounted() {
    this.getOrderMsg(this.urlArtworkID)
  },
  methods: {
    getOrderMsg(targetUUID) {
      const numRenderReg = /(\d)(?=(?:\d{3})+$)/g;
      request.get('artwork/store/getCheckOrderArtworkMsg', {params: {targetUUID: targetUUID}}).then(res => {
        this.orderArtworkMsg = res
        this.totalPrice = parseFloat(this.orderArtworkMsg.price) + parseFloat(this.orderArtworkMsg.pricetrans)
        this.totalPriceShow = this.totalPrice.toString().replace(numRenderReg, '$1,')
        this.orderArtworkMsg.price = this.orderArtworkMsg.price.toString().replace(numRenderReg, '$1,');
      })
    },
    cancelOrderForm() {
      if (this.payButtonSecAlert) {
        this.paySecondAlert('pay_submit_btn')
      }
      request.get('artwork/store/payOrderEvent', {
        params: {
          userUUID: localStorage.getItem('flame_user_uuid'),
          orderUUID:this.orderID,
          artworkUUID: this.orderArtworkMsg.uuid,
          payFlag: false
        }
      }).then(res => {
        if (res.data === "205") {
          this.payButtonAlert(false, '已售罄')
        } else if (res.data === "201") {
          this.$refs.successRedirect.successRedirectTable('取消成功')
        }

      })
    },
    payOrder() {
      if (this.payButtonSecAlert) {
        this.paySecondAlert('pay_submit_btn')
      }
      // console.log(this.orderID)
      request.get('artwork/store/payOrderEvent', {
        params: {
          userUUID: localStorage.getItem('flame_user_uuid'),
          orderUUID:this.orderID,
          artworkUUID: this.orderArtworkMsg.uuid,
          payFlag: true
        }
      }).then(res => {
        if (res.data === "200") {
          this.$refs.successRedirect.successRedirectTable('付款成功')
        } else if (res.data === "203") {
          this.payButtonAlert(false, '您不能购买自己的艺术品')
        } else if (res.data === "204") {
          this.payButtonAlert(false, '余额不足')
        } else if (res.data === "205") {
          this.payButtonAlert(false, '已售罄')
        } else {
          this.payButtonAlert(false, '请求错误')
        }
      })
    },


    payButtonAlert(Flag, tips) {
      if (Flag) {
        this.payButtonSecAlert = false
        $('#pay_submit_btn').removeClass('public_submit_Alert').addClass('public_submit_color')
        $('#pay_submit_btn').val('购买')
      } else {
        this.payButtonSecAlert = true
        $('#pay_submit_btn').removeClass('public_submit_color').addClass('public_submit_Alert')
        $('#pay_submit_btn').val(tips)
      }
    },
    paySecondAlert(BtnID) {
      $('#' + BtnID).addClass('public_submit_secAlert')
      setTimeout(() => {
        $("#" + BtnID).removeClass("public_submit_secAlert")
      }, 10);
    },

  }
}
</script>

<style src="@/style/FlameTheme.css" scoped/>
<style src="@/style/FlameDarkTheme.css" scoped/>
<style src="@/style/PublicStyle.css" scoped/>
<style scoped>


/*横屏 css*/
@media screen and (orientation: landscape) {
  .checkorder_body {
    width: 100%;
    height: 100%;
  }

  .cob_main {
    width: 1370px;
    height: 100%;
    margin: 0 auto;
    /*background-color: grey;*/
  }

  .cobm_img {
    position: absolute;
    margin: 200px 150px;
    width: 300px;
    height: 300px;
  }

  .cob_line {
    margin: 20px auto;
    width: 90%;
    border-bottom: 1px solid #939393;
  }

  .cobm_img_body {
    object-fit: cover;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 0 50px #f0f0f0, 0 0 0 60px #050505, 0 0 10px 65px #939393;
  }

  .dark .cobm_img_body {
    box-shadow: 0 0 0 50px #3a3a3a, 0 0 0 60px #939393, 0 0 10px 65px #050505;
  }

  .cobm_data {
    position: relative;
    float: right;
    right: 100px;
    top: 135px;
    width: 600px;
    height: 430px;
    border-radius: 5px;
  }

  .cobmd_title {
    font-size: 30px;
    margin-top: 30px;
    margin-left: 40px;
  }

  .cobmd_each {
    font-size: 15px;
    margin-top: 30px;
    margin-left: 40px;
  }

  .cobmd_last {
    font-size: 30px;
    margin-top: 25px;
    margin-left: 40px;
  }

  .cobmd_confirm_btn {
    /*background-color: red;*/
    position: absolute;
    width: 100%;
    height: 100px;
    bottom: 0;
  }

  .cobmd_confirm_btn_pay {
    border: none;
    width: 400px;
    height: 50px;
    margin-left: 40px;
    margin-top: 20px;
  }

  .cobmd_confirm_btn_cancel {
    border: none;
    width: 100px;
    height: 50px;
    margin-left: 40px;
    margin-top: 20px;
    background-color: #d3d3d3;
  }

  .dark .cobmd_confirm_btn_cancel {
    background-color: #545454;
  }
}

/*竖屏 css*/
@media screen and (orientation: portrait) {
  .checkorder_body {
    position: absolute;
    overflow-y: scroll;
    width: 100%;
    height: 100%;
  }

  .cob_main {
    width: 90%;
    height: 100%;
    margin: 0 auto;
    /*background-color: grey;*/
  }

  .cobm_img {
    position: relative;
    margin: 100px auto;
    width: 150px;
    height: 150px;
  }

  .cob_line {
    margin: 20px auto;
    width: 90%;
    border-bottom: 1px solid #939393;
  }

  .cobm_img_body {
    object-fit: cover;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 0 20px #f0f0f0, 0 0 0 25px #050505, 0 0 10px 30px #939393;
  }

  .dark .cobm_img_body {
    box-shadow: 0 0 0 20px #3a3a3a, 0 0 0 25px #939393, 0 0 10px 30px #050505;
  }

  .cobm_data {
    position: relative;
    margin: -20px auto;
    width: 90%;
    height: 380px;
    border-radius: 5px;
  }

  .cobmd_title {
    position: relative;
    font-size: 20px;
    padding-top: 20px;
    margin-left: 20px;
  }

  .cobmd_each {
    font-size: 15px;
    margin-top: 30px;
    margin-left: 20px;
  }

  .cobmd_last {
    font-size: 25px;
    margin-top: 25px;
    margin-left: 20px;
  }

  .cobmd_confirm_btn {
    /*background-color: red;*/
    position: absolute;
    width: 100%;
    height: 100px;
    bottom: 0;
  }

  .cobmd_confirm_btn_pay {
    border: none;
    width: 65%;
    height: 40px;
    margin-left: 5%;
    margin-top: 20px;
  }

  .cobmd_confirm_btn_cancel {
    border: none;
    width: 20%;
    height: 40px;
    margin-left: 5%;
    margin-top: 20px;
    background-color: #d3d3d3;
  }

  .dark .cobmd_confirm_btn_cancel {
    background-color: #545454;
  }
}

</style>