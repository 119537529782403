<template>
  <FlameNavBar/>
  <div class="follow_body public_bgc public_black">
    <div class="fb_main public_card_color">
      <div v-if="this.pageFlag === 'follow'" class="fbm_title">我的关注</div>
      <div v-if="this.pageFlag === 'fans'" class="fbm_title">我的粉丝</div>
      <div class="fb_line"/>
      <div class="fbm_list_body">
        <div @click="toArtistPage(item.nickname)" class="fbm_list_each pointer" v-for="item in this.artistMsg" :key="item.uuid" title="前往主页">
          <div class="fbm_list_img_border">
            <img class="fbm_list_img" :src="this.staticFileURL+'userAvatar/'+item.avatar" alt="">
          </div>
          <div class="fbm_list_name f-l">
            {{ item.nickname }}
          </div>
        </div>

        <div class="fbm_null_tip" v-if="this.pageIsNull">
          无
        </div>
      <div class="fbm_page doNotSelect">
        <div class="public_noColor_link pointer" @click="changePage(true)">上一页</div>
        <div>{{this.followPage}}</div>
        <div class="public_noColor_link pointer" @click="changePage(false)">下一页</div>
      </div>

      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import * as $ from "jquery";
import FlameNavBar from '@/components/Flame-NavBar'
import request from "@/utils/request";

export default {
  name: "followlist",
  components: {
    FlameNavBar,
  },
  data() {
    return {
      //staticUrl
      staticFileURL: this.$globalVar + '/files/',
      //跳转艺术家主页链接
      artworkDetailUrl: "/#/store/Artist?redirectUrl=" + encodeURIComponent(window.location.origin) + encodeURIComponent(window.location.pathname) + "&artistName=",
      //true查询关注列表 false查询粉丝列表
      fansOrFollow:true,

      //翻页
      followPage:1,
      pageIsNull:false,
      listTooShort:false,

      artistMsg: [{
        uuid: '',
        avatar: 'DefaultAvatar.png',
        nickname: '',
      }],

      pageFlag: decodeURIComponent(router.currentRoute.value.query.page),
      redirect: decodeURIComponent(router.currentRoute.value.query.redirectUrl),
    }
  },
  mounted() {
    if (localStorage.getItem('flame_user_uuid') === null || localStorage.getItem('flame_user_uuid') === "") {
      window.open('/#/user/login?redirectUrl=' + encodeURIComponent(window.location.origin) + encodeURIComponent(window.location.pathname), '_self');
    } else {
      if(this.pageFlag === 'follow'){
        this.fansOrFollow = true
      }else{
        this.fansOrFollow = false
      }
      this.getUserFollow()
    }

  },
  methods: {
    getUserFollow() {
      request.get('user/followList',
          {
            params: {
              useruuid: localStorage.getItem('flame_user_uuid'),
              fansfollow:this.fansOrFollow,
              page:this.followPage,
            }
          }).then(res => {
        this.artistMsg = res
        if(this.artistMsg.length === 0){
          this.pageIsNull = true
        }else{
          this.pageIsNull = false
          if(this.artistMsg.length < 6){
            this.listTooShort = true
          }else{
            this.listTooShort = false
          }
        }


      })
    },

    toArtistPage(name){
      window.open(this.artworkDetailUrl + name)
    },

    changePage(changeFlag){
      //true上一页
      //false下一页
      if(!this.pageIsNull){
        //非空
        if(this.listTooShort){
          //过短
          if(changeFlag){
            this.followPage = this.followPage - 1
            this.getUserFollow()
          }
        }else{
          // 不短
            if(changeFlag){
              if(this.followPage>1){
                this.followPage = this.followPage - 1
                this.getUserFollow()
              }
            }else{
              this.followPage = this.followPage + 1
              this.getUserFollow()
            }
        }
      }

    },

  }

}
</script>
<style src="@/style/FlameTheme.css" scoped/>
<style src="@/style/FlameDarkTheme.css" scoped/>
<style src="@/style/PublicStyle.css" scoped/>
<style scoped>
@media screen and (orientation: landscape) {
  .follow_body {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }

  .fb_main {
    overflow: hidden;
    position: relative;
    margin: 52px auto;
    width: 1100px;
    height: 100%;

  }

  .fbm_title {
    position: relative;
    font-size: 30px;
    margin-top: 30px;
    margin-left: 80px;
  }

  .fb_line {
    height: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    border-bottom: 1px solid #b6b6b6;
  }

  .fbm_list_body {
    position: relative;
    width: 90%;
    height: 580px;
    margin: 0 auto;
  }

  .fbm_list_each {
    overflow: hidden;
    position: relative;
    border-bottom: 1px solid #b6b6b6;
    height: 70px;
    width: 100%;
  }

  .fbm_list_each:hover {
    animation: obmtb_eachAnimL 0.5s;
    background-color: #e3e3e3;
  }

  @keyframes obmtb_eachAnimL {
    from {
      background-color: #FFFFFF00;
    }
    to {
      background-color: #e3e3e3;
    }
  }

  .dark .fbm_list_each:hover {
    animation: obmtb_eachAnimD 0.5s;
    background-color: #292929;
  }

  @keyframes obmtb_eachAnimD {
    from {
      background-color: #FFFFFF00;
    }
    to {
      background-color: #292929;
    }
  }

  .fbm_list_img_border {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 20px;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    float: left;
  }

  .fbm_list_img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .fbm_list_name {
    display: flex;
    align-self: center;
    align-items: center;
    position: relative;
    width: auto;
    font-size: 25px;
    left: 30px;
    height: 100%;
  }

  .fbm_page{
    position: absolute;
    bottom: 0px;
  }
  .fbm_page div{
    position: relative;
    margin-left: 20px;
    float: left;
  }
  .fbm_null_tip{
    position: absolute;
    width: 20px;
    height: 20px;
    margin-top: 200px;
    margin-left: 500px;
  }
}
@media screen and (orientation: portrait) {
  .follow_body {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }

  .fb_main {
    overflow: hidden;
    position: relative;
    margin: 52px auto;
    width: 90%;
    height: 100%;

  }

  .fbm_title {
    position: relative;
    font-size: 20px;
    margin-top: 30px;
    margin-left: 5%;
  }

  .fb_line {
    height: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    border-bottom: 1px solid #b6b6b6;
  }

  .fbm_list_body {
    position: relative;
    width: 90%;
    height: 580px;
    margin: 0 auto;
  }

  .fbm_list_each {
    overflow: hidden;
    position: relative;
    border-bottom: 1px solid #b6b6b6;
    height: 60px;
    width: 100%;
  }

  .fbm_list_each:hover {
    animation: obmtb_eachAnimL 0.5s;
    background-color: #e3e3e3;
  }

  @keyframes obmtb_eachAnimL {
    from {
      background-color: #FFFFFF00;
    }
    to {
      background-color: #e3e3e3;
    }
  }

  .dark .fbm_list_each:hover {
    animation: obmtb_eachAnimD 0.5s;
    background-color: #292929;
  }

  @keyframes obmtb_eachAnimD {
    from {
      background-color: #FFFFFF00;
    }
    to {
      background-color: #292929;
    }
  }

  .fbm_list_img_border {
    overflow: hidden;
    position: relative;
    margin-top: 15px;
    margin-left: 20px;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    float: left;
  }

  .fbm_list_img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .fbm_list_name {
    display: flex;
    align-self: center;
    align-items: center;
    position: relative;
    width: auto;
    font-size: 15px;
    left: 10px;
    height: 100%;
  }

  .fbm_page{
    position: absolute;
    bottom: 0px;
    margin-left: -20px;
  }
  .fbm_page div{
    position: relative;
    margin-left: 20px;
    float: left;
  }
  .fbm_null_tip{
    position: absolute;
    width: 20px;
    height: 20px;
    margin-top: 200px;
    margin-left: 500px;
  }
}
</style>