<template>
  <FlameNavBar/>
  <div class="classify_body public_bgc">
    <div class="clb_main public_black">
      <div class="clbm_head">
        <div class="clb_title doNotSelect">
          <div class="clb_title_border"></div>
          <div class="clb_title_text f-b">{{this.artworkTypeName}}</div>
        </div>
      </div>

      <div class="clbm_main">
<!--        艺术卡片-->
        <div class="art_each-card store_art_card_color" v-for="item in this.artworkMsg" :key="item.uuid">
          <a class="art_each-card_a public_black" :href="this.artworkDetailUrl + item.uuid">
            <div class="art_img_border">
              <img class="art_img" :src="this.staticFileURL + 'artworkImage/' + item.imgurl.split('%2C')[0]" alt="">
            </div>
            <div class="art_title f-b">{{item.namecn}}</div>
            <div class="art_nickname public_black f-l f-10">@{{item.nickname}}</div>
            <div class="art_price f-25 f-b public_Flame_themeColor">{{item.price}}&nbsp;<span class="public_gray f-l f-15">CNY</span></div>
          </a>
        </div>

      </div>

    </div>
    <FlameFooter/>
  </div>
</template>

<script>
import * as $ from "jquery";
import FlameNavBar from '@/components/Flame-NavBar'
import router from "@/router";
import request from "@/utils/request";
import FlameFooter from "@/components/Flame-Footer";

export default {
  name: "classify",
  components: {
    FlameFooter,
    FlameNavBar,
  },
  data() {
    return {
      //ArtworkImgURL
      staticFileURL: this.$globalVar + '/files/',
      //TypeName:
      artworkTypeName:decodeURIComponent(router.currentRoute.value.query.TypeName),
      //跳转链接
      artworkDetailUrl:"/#/store/commodity?redirectUrl=" + encodeURIComponent(window.location.origin) + encodeURIComponent(window.location.pathname) + "&artworkID=",
      artworkMsg:[{
        id: '',
        uuid: '',
        namecn: '',
        nameen: '',
        price: '',
        pricetrans: '',
        imgurl: '',
        like: '',
        state: '',
        virtualentity: '',
        nickname: ''
      }],
    }
  },
  mounted() {
    this.getArtworkByClassify(decodeURIComponent(router.currentRoute.value.query.TypeID))
  },
  methods: {
    getArtworkByClassify(typeID) {
      request.get('artwork/store/getMainArtworkByClassify', {params: {TypeID: typeID}}).then(res => {
        // console.log(res)
        this.artworkMsg = res
      })
    }
  }
}
</script>
<style src="@/style/FlameTheme.css" scoped/>
<style src="@/style/FlameDarkTheme.css" scoped/>
<style src="@/style/PublicStyle.css" scoped/>
<style scoped>
.classify_body {
  width: 100%;
  height: auto;
  overflow: hidden;
}
@media screen and (orientation: landscape) {
  .clb_main {
    position: relative;
    margin: 52px auto;
    width: 1170px;
    height: auto;
  }

  .clbm_head {
    position: relative;
    width: 100%;
    height: 150px;
  }

  .clb_title_border {
    float: left;
    margin-left: 50px;
    margin-top: 50px;
    width: 20px;
    height: 60px;
    background-color: #FF8000;
  }

  .clb_title_text {
    font-size: 40px;
    float: left;
    margin-left: 20px;
    margin-top: 50px
  }

  .clbm_main {
    overflow: hidden;
    width: 100%;
    height: auto;
    margin: 0 auto;
  }
  /*艺术品卡片*/
  .art_each-card {
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 42px;
    float: left;
    width: 240px;
    height: 300px;
  }
  .art_each-card_a{
    display: block;
    width: 100%;
    height: 100%;
  }
  .art_img_border{
    position: relative;
    width: 100%;
    height: 180px;
  }

  .art_img{
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .art_title{
    position: relative;
    font-size: 20px;
    top: 10px;
    left: 10px;
  }
  .art_nickname{
    position: relative;
    top: 15px;
    left: 10px;
  }
  .art_price{
    position: relative;
    top: 40px;
    left: 10px;
  }
  /*结束*/
}
/*竖屏 css*/
@media screen and (orientation: portrait) {

  .clb_main {
    position: relative;
    margin: 52px auto;
    width: 90%;
    height: auto;
  }

  .clbm_head {
    position: relative;
    width: 100%;
    height: 100px;
  }

  .clb_title_border {
    float: left;
    margin-left: 10%;
    margin-top: 5%;
    width: 20px;
    height: 60px;
    background-color: #FF8000;
  }

  .clb_title_text {
    font-size: 40px;
    float: left;
    margin-left: 20px;
    margin-top: 5%;
  }

  .clbm_main {
    overflow: hidden;
    width: 100%;
    height: auto;
    margin: 0 auto;
  }
  /*艺术品卡片*/
  .art_each-card {
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 7%;
    float: left;
    width: 40%;
    height: 200px;
  }
  .art_each-card_a{
    display: block;
    width: 100%;
    height: 100%;
  }
  .art_img_border{
    position: relative;
    width: 100%;
    height: 100px;
  }

  .art_img{
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .art_title{
    position: relative;
    font-size: 15px;
    top: 10px;
    left: 10px;
  }
  .art_nickname{
    position: relative;
    top: 15px;
    left: 10px;
  }
  .art_price{
    position: relative;
    top: 10%;
    left: 10px;
    font-size: 15px;
  }
  /*结束*/
}
</style>