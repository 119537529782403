<template>
  <div id="canvas-body" class="canvas-body">
    <div id="canvas-container" class="canvas-container"></div>
  </div>
</template>

<script>
import * as THREE from "three";
import * as $ from "jquery";
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import {FBXLoader} from "three/examples/jsm/loaders/FBXLoader";
import {OBJLoader} from "three/examples/jsm/loaders/OBJLoader";
import {RGBELoader} from 'three/examples/jsm/loaders/RGBELoader.js';
import {EffectComposer} from 'three/examples/jsm/postprocessing/EffectComposer.js';
import {RenderPass} from 'three/examples/jsm/postprocessing/RenderPass.js';
import {SMAAPass} from 'three/examples/jsm/postprocessing/SMAAPass.js';
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls.js';

let model;

export default {
  props: ['width','height','modeltype', 'modelurl', 'background','blur','exposure','filter'],
  data() {
    return {
      // model:'',

    }
  },
  mounted() {
    this.initCanvas(this.width,this.height)
    this.init()
    // this.loadModel(this.modelurl)
    this.loadEnvironment(this.background)
    this.addControls()
    this.scenesEffect()
    this.animate()
  },
  methods: {
    initCanvas(width,height){
      $('#canvas-body').css({"width": width,"height": height})
    },
    init() {
      this.scene = new THREE.Scene();
      this.container = document.getElementById("canvas-container");
      this.camera = new THREE.PerspectiveCamera(70, window.innerWidth / window.innerHeight, 0.1, 1000);
      this.renderer = new THREE.WebGLRenderer({antialias: true, alpha: true});
      this.renderer.setPixelRatio(window.devicePixelRatio);
      //设置摄像机位置
      this.camera.position.z = 15;
      //设置画布自适应大小
      this.renderer.setSize($("#canvas-container").width(), $("#canvas-container").height());
      //生成画布
      this.container.appendChild(this.renderer.domElement);
      //按照canvas的大小来调整camera的aspect和投影矩阵
      const canvas = this.renderer.domElement;
      this.camera.aspect = canvas.clientWidth / canvas.clientHeight;
      this.camera.updateProjectionMatrix();
      const ambientLight = new THREE.AmbientLight(0xFFFFFF, 2);
      this.scene.add(ambientLight)
      //设置点光源
      const point = new THREE.PointLight(0xFFFFFF, 3);
      point.position.set(40, 10, 10);
      const point2 = new THREE.PointLight(0xFFFFFF, 3);
      point2.position.set(-40, -10, 10);
      this.scene.add(point);
      this.scene.add(point2);
      this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
      this.renderer.toneMappingExposure = 1;
      // this.scene.backgroundBlurriness =1;
      this.scene.backgroundBlurriness = 0;
    },
    loadModel(ModelLink) {
      let that = this
      this.loader = new GLTFLoader();
      this.loader.load(ModelLink, function (gltf) {
       model = gltf.scene
        model.position.set(0, 0, 0);
        model.scale.set(1, 1, 1);
        model.rotation.set(0, 2, 0)
        that.scene.add(model);
      }, undefined, function (error) {
        console.error(error);
      });
    },
    loadEnvironment(EnvLink) {
      //设置环境
      // const pmremGenerator = new THREE.PMREMGenerator(renderer);
      // scene.background = new THREE.Color( 0xbfe3dd );
      // scene.background = new THREE.Color(0xC7C7C7);
      // scene.environment = pmremGenerator.fromScene(new RoomEnvironment(), 0.04).texture;
      // scene.fog = new THREE.Fog(0xFFFFFF, 1, 1000)
      if (EnvLink === ""){
        EnvLink = 'HDRI_STUDIO_vol2_030.hdr'
      }
      this.rgbeLoader = new RGBELoader();
      let that = this
      this.rgbeLoader.loadAsync('/web/canvas/background/'+EnvLink).then((texture) => {
        texture.mapping = THREE.EquirectangularReflectionMapping;
        that.scene.environment = texture;
        that.scene.background = texture;
      });
    },
    removeModel(){
      let that = this
      that.scene.remove(model)
    },

    addControls() {
      //控制手柄
      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
      this.controls.enableDamping = true;
      // controls.enablePan = false;
      this.controls.minDistance = 8;
      this.controls.maxDistance = 50;
      this.controls.target.set(0, 0.35, 0);
      this.controls.update();
    },
    scenesEffect() {
      this.composer = new EffectComposer(this.renderer);
      this.renderScene = new RenderPass(this.scene, this.camera);
      //SMAA
      const pass = new SMAAPass(window.innerWidth * this.renderer.getPixelRatio(), window.innerHeight * this.renderer.getPixelRatio());
      this.composer.addPass(pass);
      this.composer.addPass(this.renderScene);
    },
    animate() {
      requestAnimationFrame(this.animate);
      this.controls.update(); // required if damping enabled
      this.render();
    },
    render() {
      this.scene.backgroundBlurriness = this.blur/100;
      this.renderer.toneMappingExposure = this.exposure;
      this.renderer.toneMapping = THREE[this.filter];
      this.renderer.render(this.scene, this.camera);
      this.renderer.shadowMap.enabled = true;
      //添加特效
      this.composer.render()
    }
  }
}
</script>

<style scoped>
.canvas-body {
  overflow: hidden;
}

.canvas-container {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

</style>