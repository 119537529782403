<template>
  <div class="adminlogin_body doNotSelect">
    <div class="adml_head ta-c f-b">管理员登录 | Flame Administrator</div>

    <div class="adml_card">
      <div class="adml_card_body">
        <br>
        <br>
        <div class="adml_card_title">账户</div>
        <input v-model="this.account" type="text">
        <div class="adml_card_title">密码</div>
        <input v-model="this.password" type="password">
        <input @click="adminLogin" class="admbtn pointer" style="width: 72%" type="button" value="登录">
      </div>
    </div>


  </div>
</template>

<script>
import request from "@/utils/request";
import {ElMessage} from "element-plus";
import router from "@/router";

export default {
  name: "adminlogin",
  data(){
    return{
      account:'',
      password:'',
    }
  },
  mounted() {
    localStorage.setItem('flame-admin-name',"")
  },
  methods:{
    adminLogin(){
      request.post('private/admin/login',{account:this.account,password:this.password}).then(res=>{
        if(res){
          localStorage.setItem('flame-admin-name',this.account)
          ElMessage.success('登录成功')
          router.push('/private/admin-dashboard')
        }else{
          ElMessage.error('账号或密码错误')
        }
      })
    }
  }
}
</script>

<style src="@/style/PublicStyle.css" scoped/>
<style scoped>
.adminlogin_body{
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.adml_head{
  position: relative;
  width: 100%;
  margin-top: 100px;
  font-size: 30px;
}
.adml_card{
  overflow: hidden;
  position: relative;
  margin: 50px auto;
  width: 400px;
  height: 400px;
  border-radius: 10px;
  background-color: #ececec;
}
.adml_card_body{
  position: relative;

}
.adml_card_title{
  position: relative;
  margin-top: 20px;
  margin-left: 15%;
}
.adml_card_body input{
  outline: none;
  position: relative;
  font-size: 18px;
  margin-left: 15%;
  width: 70%;
  height: 40px;
}
.admbtn{
  border: none;
  position: relative;
  color: #F0F0F0;
  background-color: #1c1c1c;
  margin-top: 50px;
}









</style>