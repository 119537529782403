<template>
  <FlameNavBar/>
  <div class="personl_body public_bgc public_black">
    <div class="pb_main section">
      <div class="personl_bg doNotSelect ">
        <div class="personl_bg_darkfilter"/>
        <img class="personl_bg_img" :src="this.staticFileURL + 'userBackground/' + this.userPersonalMsg.bgimg" alt="">
      </div>
      <div class="pbm_detail">
        <div class="pbmd_avatar_area">
          <div class="pbmd_avatar doNotSelect">
            <img class="pbmd_avatar_img" :src="this.staticFileURL + 'userAvatar/' + this.userPersonalMsg.avatar" alt="">
          </div>
          <el-upload
            class="avatar-uploader p-a"
            :action="this.$globalVar + '/files/userAvatar/upload'"
            :multiple=false
            :on-success="uploadAvatars"
            :limit="1"
            title="更换头像"
        >
          <div class="pbmd_avatar_editIcon">
            <svg t="1665554593881" viewBox="0 0 1024 1024" version="1.1"
                 xmlns="http://www.w3.org/2000/svg"
                 p-id="1536" width="20" height="20">
              <path
                  d="M696.448 111.168l207.552 207.552 45.248-45.248L741.76 65.92l-45.248 45.248zM150.528 887.232l231.232-46.208 467.072-467.072L641.216 166.4 174.208 633.408l-46.272 231.232a19.2 19.2 0 0 0 22.592 22.592z m490.688-630.4l117.12 117.12-408.128 408.064-146.304 29.312 29.248-146.368 408.064-408.064z"
                  fill="#000000" fill-opacity=".85" p-id="1537"></path>
            </svg>
          </div>

        </el-upload>
        </div>




        <div class="pbmd_umsg">
          <div class="pbmd_umsg_name f-b">{{ this.userPersonalMsg.nickname }}</div>
          <div class="pbmd_umsg_flag_N f-b doNotSelect" v-if="this.userPersonalMsg.type === 0">普通账户</div>
          <div class="pbmd_umsg_flag_A f-b doNotSelect" v-if="this.userPersonalMsg.type === 1">艺术家</div>
          <div class="pbmd_umsg_email f-l"><span class="doNotSelect">#&nbsp;</span>{{ this.userPersonalMsg.email }}

            <el-upload
                class="pbmd_umsg_cbgi p-a"
                :action="this.$globalVar +'/files/userBackground/upload'"
                :multiple=false
                :on-success="uploadBackground"
                :limit="1"
                title="更换背景"
            >
              <div class="change_homepage_bgimg f-l">
                变更背景
              </div>
            </el-upload>

          </div>
        </div>
        <div class="pbmd_btnZu">
          <input @click="umsgOpen('/#/account/manage?')" class="public_submit_color f-b pointer" type="button"
                 value="编辑资料">
          <input @click="umsgOpen('/#/store/Publish?')" class="public_submit_color f-b pointer" type="button"
                 value="发布艺术品"
                 v-if="this.userPersonalMsg.type === 1">
        </div>
        <div class="pbmd_f_card public_card_color">
          <div class="pbmd_folFans public_black f-l">
            <a class="public_black" :href="this.followUrl + this.userPersonalMsg.uuid + '&page=follow'">
              <div class="pbmdf_each store_art_card_color pointer" title="我的关注">
                <div class="">关注</div>
                <div class="pbmdfe_title public_Flame_themeColor">{{ this.userPersonalMsg.followers }}</div>
              </div>
            </a>
            <a class="public_black" :href="this.followUrl + this.userPersonalMsg.uuid+'&page=fans'">
            <div class="pbmdf_each store_art_card_color pointer">
              <div class="">粉丝</div>
              <div class="pbmdfe_title public_Flame_themeColor">{{ this.userPersonalMsg.fans }}</div>
            </div>
            </a>
            <div @click="this.showEditIntroduce = true"
                 class="pbmdf_each pbmdf_each_Intro store_art_card_color pointer" title="点我编辑">
              <div class="">简介</div>
              <div class="pbmdfe_title">{{ this.userPersonalMsg.introduce }}</div>
            </div>
          </div>
        </div>

        <div class="pbmd_main public_card_color">
          <div class="pbmdm_head public_card_color">
            <div class="f-l">
              <input id="selMyLikeArt" @click="changeFilterFlag('MyLike')"
                     class="pbmdm_changeBtn pbmdm_changeBtn_like pointer" type="button"
                     value="我喜欢的">
              <input id="selMyPublishArt" @click="changeFilterFlag('MyPublish')"
                     class="pbmdm_changeBtn pbmdm_changeBtn_publish pointer"
                     type="button"
                     value="我发布的">
            </div>
          </div>

          <!--          我喜欢的-->
          <div v-show="this.filtrationBtnFlag">
            <div class="become_artist_body public_black doNotSelect" v-show="this.noneDataAlert">
              <div class="becomeab_title ta-c f-b">您还没有喜欢的艺术品，快去探索吧</div>
            </div>

            <!--        艺术卡片-->
            <div class="art_each-card store_art_card_color" v-for="item in this.userLikedArtwork" :key="item.uuid"
                 v-show="!this.noneDataAlert">
              <a class="art_each-card_a public_black" :href="this.artworkDetailUrl + item.uuid">
                <div class="art_img_border">
                  <img class="art_img" :src="this.staticFileURL + 'artworkImage/' + item.imgurl.split('%2C')[0]" alt="">
                </div>
                <div class="art_title f-b f-20">{{ item.namecn }}</div>
                <div class="art_nickname public_black f-l f-10">@{{ item.nickname }}</div>
                <div class="art_price f-25 f-b public_Flame_themeColor">{{ item.price }}&nbsp;<span
                    class="public_gray f-l f-15">CNY</span></div>
              </a>
            </div>
          </div>

          <!--          我发布的-->
          <div v-show="!this.filtrationBtnFlag">
            <!--             是艺术家-->

            <div v-if="this.userPersonalMsg.type === 1">
              <div class="become_artist_body public_black doNotSelect" v-show="this.noneDataAlert">
                <div class="becomeab_title ta-c f-b">您还未发布艺术品</div>
                <input @click="umsgOpen('/#/store/Publish?')" class="becomeab_btn store_art_card_color public_black f-b pointer " type="button"
                       value="发布第一个艺术品">
              </div>

              <div class="art_each-card public_card_color doNotSelect" v-show="!this.noneDataAlert"
                   v-for="item in this.userPublishArtwork" :key="item.uuid">
                <a class="art_each-card_a public_black" :href="this.artworkDetailUrl + item.uuid">
                  <div class="art_img_border">
                    <img class="art_img" :src="this.staticFileURL + 'artworkImage/' + item.imgurl.split('%2C')[0]"
                         alt="">
                  </div>
                  <div class="art_title f-b f-20">{{ item.namecn }}</div>
                  <div class="art_nickname public_black f-l f-10">@{{ item.nickname }}</div>
                  <div class="art_price f-25 f-b public_Flame_themeColor">{{ item.price }}&nbsp;<span
                      class="public_gray f-l f-15">CNY</span></div>
                </a>

                <div @mouseover="controlDeleteBox(item.id,true)" @mouseleave="controlDeleteBox(item.id,false)"
                     :id="'art_edit_'+item.id" class="art_delete_manage">
                  <div @click="umsgOpen(this.artworkDetailUrl + item.uuid+'&')" :id="'openIcon_'+item.id"
                       class="art_delete_icon_border pointer" title="查看">

                    <svg t="1673431681500" class="art_delete_icon" viewBox="0 0 1024 1024" version="1.1"
                         xmlns="http://www.w3.org/2000/svg" p-id="5718" width="30" height="30">
                      <path
                          d="M949.9 899l-124-124.1c28.2-33.3 51.1-70.4 68.2-110.8 22.5-53.3 34-109.9 34-168.2s-11.4-114.9-34-168.2c-21.8-51.5-52.9-97.6-92.6-137.3-39.7-39.7-85.8-70.8-137.3-92.6C610.9 75.4 554.3 64 496 64S381.1 75.4 327.8 98c-51.5 21.8-97.6 52.9-137.3 92.6-39.7 39.7-70.8 85.8-92.6 137.3C75.4 381.1 64 437.7 64 496s11.4 114.9 34 168.2c21.8 51.5 52.9 97.6 92.6 137.3 39.7 39.7 85.8 70.8 137.3 92.6 53.3 22.5 109.9 34 168.2 34s114.9-11.4 168.2-34c40.4-17.1 77.5-40 110.8-68.2l123.9 124c14 14 36.9 14 50.9 0s14-36.9 0-50.9zM496 856c-198.8 0-360-161.2-360-360s161.2-360 360-360 360 161.2 360 360-161.2 360-360 360z"
                          fill="#F0F0F0" p-id="5719"></path>
                      <path
                          d="M644 460H532V348c0-19.8-16.2-36-36-36s-36 16.2-36 36v112H348c-19.8 0-36 16.2-36 36s16.2 36 36 36h112v112c0 19.8 16.2 36 36 36s36-16.2 36-36V532h112c19.8 0 36-16.2 36-36s-16.2-36-36-36z"
                          fill="#F0F0F0" p-id="5720"></path>
                    </svg>
                  </div>

                  <div @click="deleteArtwork(item.uuid,item.id)" :id="'deleteIcon_'+item.id"
                       class="art_delete_icon_border pointer" title="删除">

                    <svg t="1673431400009" class="art_delete_icon" viewBox="0 0 1024 1024" version="1.1"
                         xmlns="http://www.w3.org/2000/svg" p-id="2897" width="30" height="30">
                      <path
                          d="M847.743 223.953H640.639c-3.132-68.921-60.177-124.029-129.858-124.029s-126.726 55.108-129.858 124.029H173.256c-17.673 0-32 14.327-32 32s14.327 32 32 32h674.487c17.673 0 32-14.327 32-32s-14.327-32-32-32z m-336.962-60.03c34.379 0 62.689 26.426 65.718 60.029H445.064c3.029-33.603 31.338-60.029 65.717-60.029zM767.743 351.79c-17.673 0-32 14.327-32 32v478.173H288.256V383.79c0-17.673-14.327-32-32-32s-32 14.327-32 32v510.173c0 17.673 14.327 32 32 32h511.487c17.673 0 32-14.327 32-32V383.79c0-17.673-14.327-32-32-32z"
                          fill="#F0F0F0" p-id="2898"></path>
                      <path
                          d="M449.306 732.802V448.208c0-17.673-14.327-32-32-32s-32 14.327-32 32v284.593c0 17.673 14.327 32 32 32s32-14.326 32-31.999zM640.84 732.802V448.208c0-17.673-14.327-32-32-32s-32 14.327-32 32v284.593c0 17.673 14.327 32 32 32s32-14.326 32-31.999z"
                          fill="#F0F0F0" p-id="2899"></path>
                    </svg>
                  </div>

                  <div @click="cancelDeleteArtwork(item.uuid,item.id)" :id="'cancelDeleteIcon_'+item.id"
                       class="art_delete_icon_border art_cancelDelete_icon_border pointer" title="取消">
                    <svg t="1673440940083" class="art_delete_icon" viewBox="0 0 1024 1024" version="1.1"
                         xmlns="http://www.w3.org/2000/svg" p-id="7462" width="25" height="25">
                      <path
                          d="M952.311261 921.328619 542.892591 510.919389 950.154131 102.671381c8.53028-8.55177 8.53028-22.416546 0-30.967292-8.532327-8.55177-22.360264-8.55177-30.892591 0l-407.262564 408.248008L104.737436 71.704089c-8.53028-8.55177-22.36231-8.55177-30.892591 0-8.529257 8.55177-8.529257 22.416546 0 30.967292l407.262564 408.248008L71.687716 921.328619c-8.529257 8.55177-8.529257 22.416546 0 30.967292 4.26514 4.27435 9.856485 6.41306 15.446807 6.41306 5.590322 0 11.181667-2.13871 15.446807-6.41306l409.41867-410.409231 409.41867 410.409231c4.266164 4.27435 9.855462 6.41306 15.446807 6.41306 5.591345 0 11.17962-2.13871 15.446807-6.41306C960.841541 943.745165 960.841541 929.879366 952.311261 921.328619z"
                          fill="#F0F0F0" p-id="7463"></path>
                    </svg>
                  </div>

                </div>
              </div>
            </div>

            <!--            非艺术家-->
            <div v-if="this.userPersonalMsg.type === 0">
              <div class="become_artist_body public_black doNotSelect">
                <div class="becomeab_title ta-c f-b">您还不是艺术家</div>
                <input @click="sendValidateCode" class="becomeab_btn store_art_card_color public_black f-b pointer" type="button"
                       value="升级为艺术家账户">
              </div>
            </div>


          </div>


        </div>
      </div>
    </div>
    <!--    点击简介编辑简介-->
    <transition name="fade">
      <div class="public_modal_background" v-show="this.showEditIntroduce">
        <div class="edit_intro_modal public_card_color doNotSelect">
          <div class="editim_body">
            <div class="editim_body_title public_black f-l">修改简介</div>

            <div class="input_border public_gray_border public_input_bgc public_black" id="introduce_border"
                 >
              <div class="input_border_title f-l doNotSelect" id="introduce"
                   style="left: 15px;top: 5px;font-size: 10px">
                简介
              </div>
              <input class="input_border_main public_black"
                     @blur="checkUserInput('introduce',this.userPersonalMsg.introduce)"
                     @click="selectBorder('introduce')"
                     v-model="this.userPersonalMsg.introduce"
                     type="text"
              >
            </div>
            <div class="input_alert_msg public_warning_color f-10" id="introduce_alert"></div>
          </div>
          <div class="modal_submitOrCancel">
            <input @click="this.showEditIntroduce = false" class="modal_submit_cancel public_black pointer"
                   type="button" value="取消">
            <input @click="editUserMsg('introduce',this.userPersonalMsg.introduce)" class="public_submit_color pointer"
                   type="button" value="确认">
          </div>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div class="checkEm_body doNotSelect" v-show="this.vCodeWindowShow">
        <div class="checkEm_card public_card_color public_black">
          <div class="public_tips_backgroundColor cb_closeIcon " @click="closeVCodeClearStorage" title="关闭页面">
            <svg t="1672314848842" class="icon public_tips_iconFill " viewBox="0 0 1024 1024" version="1.1"
                 xmlns="http://www.w3.org/2000/svg"
                 p-id="2734" width="20" height="20">
              <path
                  d="M557.3 512l329.3-329.4a32 32 0 1 0-45.2-45.2L512 466.7 182.6 137.4a32 32 0 1 0-45.2 45.2L466.7 512 137.4 841.4a31.9 31.9 0 0 0 0 45.2 31.9 31.9 0 0 0 45.2 0L512 557.3l329.4 329.3a31.9 31.9 0 0 0 45.2 0 31.9 31.9 0 0 0 0-45.2z"
                  p-id="2735"></path>
            </svg>
          </div>
          <div class="cc_title">
            <div class="cct_tips f-20 f-l">正在升级账户...</div>
            <div class="cct_title cct_title_phone public_gray">我们发送了一封携带验证码的邮件到您的邮箱上</div>
            <div class="cct_title cct_maintitle_phone f-40 f-b p-r t-20">请输入4位验证码</div>
          </div>
          <div class="cc_validateCode">
            <div class="ccv_input_border">
              <div class="ccvi_each public_gray_border public_input_bgc">
                <input class="public_gray" v-model="input0" ref="input0" @click="vCodeBorderAlert(false)"
                       @keydown="deleteValue($event,'input0','input0')"
                       @keyup="changeValue($event,'input0','input1')" type="text" maxlength="1">
              </div>
              <div class="ccvi_each public_gray_border public_input_bgc">
                <input class="public_gray" v-model="input1" ref="input1" @click="vCodeBorderAlert(false)"
                       @keydown="deleteValue($event,'input1','input0')"
                       @keyup="changeValue($event,'input1','input2')" type="text" maxlength="1">
              </div>
              <div class="ccvi_each public_gray_border public_input_bgc">
                <input class="public_gray" v-model="input2" ref="input2" @click="vCodeBorderAlert(false)"
                       @keydown="deleteValue($event,'input2','input1')"
                       @keyup="changeValue($event,'input2','input3')" type="text" maxlength="1">
              </div>
              <div class="ccvi_each public_gray_border public_input_bgc">
                <input class="public_gray" v-model="input3" ref="input3" @click="vCodeBorderAlert(false)"
                       @keydown="deleteValue($event,'input3','input2')"
                       @keyup="changeValue($event,'input3','input3')" type="text" maxlength="1">
              </div>
            </div>
            <div>
            </div>
          </div>
          <div class="checkEmail_btn">
            <input id="checkEmail_submit_button" type="button" value="升级账户" class="public_submit_color"
                   @click="completeUpdater">
          </div>
          <div class="cc_checkHelp">
            <div class="cch_line f-l f-10 public_gray">——————— 帮助 ———————</div>
            <div class="ccc_fsize_phone public_gray ta-c f-15 mt-2">没有收到验证码？
              <a id="sendEmailAgain" @click="sendEmailAgain"
                 style="color: #D94D22">{{ this.sendEmailAgainTips }}</a>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <successRedirect :sucRedirectURL="'personal?requestID='+this.userPersonalMsg.uuid" ref="successRedirect"/>


  </div>
</template>

<script>
import * as $ from "jquery";
import FlameNavBar from '@/components/Flame-NavBar'
import router from "@/router";
import request from "@/utils/request";
import FlameInput from "@/components/Flame-Input";
import {ref} from "vue";
import successRedirect from "@/components/successredirect";
import FlameFooter from "@/components/Flame-Footer";

export default {
  name: "homepage",
  components: {
    FlameFooter,
    FlameInput,
    FlameNavBar,
    successRedirect,
  },
  data() {
    return {
      //staticUrl
      staticFileURL: this.$globalVar + '/files/',
      //跳转链接
      artworkDetailUrl: "/#/store/commodity?redirectUrl=" + encodeURIComponent(window.location.origin) + encodeURIComponent(window.location.pathname) + "&artworkID=",
      followUrl: "/#/account/Follow?redirectUrl=" + encodeURIComponent(window.location.origin) + encodeURIComponent(window.location.pathname) + "&requestID=",
      //浏览艺术品的FLAG
      filtrationBtnFlag: true,
      //没有数据的FLAG
      noneDataAlert: false,
      //修改个人简介
      showEditIntroduce: false,
      userPersonalMsg: {},

      userLikedArtwork: [],

      userPublishArtwork: [],

      //修改用户资料
      //个人简介
      editIntroValue: '',

      //对发布艺术品的控制
      showEditArtworkTab: false,
      showCancelDeleteBtn:false,
      deleteStep: 1,

      //验证码
      vCodeWindowShow: false,
      validateCode: '',
      input0: '',
      input1: '',
      input2: '',
      input3: '',
      sendEmailAgainTips: "点击此处再次发送",
      sendEmailAgainTimes: 0,
      sendEmailDisabled: false,
      // 按钮二次警告
      btnSecAlert:false,
    }
  },
  mounted() {
    if (localStorage.getItem('flame_user_uuid') === null || localStorage.getItem('flame_user_uuid') === "") {
      window.open('/#/user/login?redirectUrl=' + encodeURIComponent(window.location.origin) + encodeURIComponent(window.location.pathname), '_self');
    } else {
      this.getUserPersonalMsg()
    }
  },
  methods: {
    getUserPersonalMsg() {
      $('#selMyLikeArt').addClass('ps_filter_btn')
      request.post('user/userPersonalMsg', {'uuid': localStorage.getItem('flame_user_uuid')}).then(res => {
        // console.log(res)
        this.userPersonalMsg = res
        request.get('artwork/store/getUserLikedArtwork', {params: {userUUID: localStorage.getItem('flame_user_uuid')}}).then(res => {
          // console.log(res)
          this.userLikedArtwork = res
          if (this.userLikedArtwork.length === 0) {
            this.noneDataAlert = true
          }
        })


      })
    },
    changeFilterFlag(Flag) {
      this.noneDataAlert = false
      if (Flag === 'MyLike') {
        this.filtrationBtnFlag = true
        $('#selMyPublishArt').removeClass('ps_filter_btn')
        $('#selMyLikeArt').addClass('ps_filter_btn')
        request.get('artwork/store/getUserLikedArtwork', {params: {userUUID: localStorage.getItem('flame_user_uuid')}}).then(res => {
          // console.log(res)
          this.userLikedArtwork = res
          if (this.userLikedArtwork.length === 0) {
            this.noneDataAlert = true
          }
        })
      } else {
        this.filtrationBtnFlag = false
        $('#selMyLikeArt').removeClass('ps_filter_btn')
        $('#selMyPublishArt').addClass('ps_filter_btn')
        request.get('artwork/store/getUserPublishArtwork', {params: {userUUID: localStorage.getItem('flame_user_uuid')}}).then(res => {
          // console.log(res)
          this.userPublishArtwork = res
          if (this.userPublishArtwork.length === 0) {
            this.noneDataAlert = true
          }

        })

      }
      // this.filtrationBtnFlag = !this.filtrationBtnFlag
    },


    //修改用户资料
    editUserMsg(editFlag, editValue) {
      request.post('user/updateUserMsg', {
        'useruuid': localStorage.getItem('flame_user_uuid'),
        'editflag': editFlag,
        'editvalue': editValue
      }).then(res => {
        window.location.reload()
      })
    },

    umsgOpen(url) {
      window.open(url + 'requestID=' + this.userPersonalMsg.uuid,'_self')
    },
    uploadAvatars(response) {
      if(this.userPersonalMsg.avatar !== 'DefaultAvatar.png'){
        request.post('files/userAvatar/' + this.userPersonalMsg.avatar + '/delete')
      }
      this.editUserMsg('avatar', response.data)
    },
    uploadBackground(response) {
      if(this.userPersonalMsg.bgimg !== 'DefaultBackground.jpg'){
        request.post('files/userBackground/' + this.userPersonalMsg.bgimg + '/delete')
      }
      this.editUserMsg('background', response.data)
    },

    controlDeleteBox(targetID, boxFlag) {
      if (boxFlag) {
        $('#art_edit_' + targetID).addClass('art_delete_manage_show')
      } else {
        this.deleteStep = 1
        $('#deleteIcon_' + targetID).removeClass('art_delete_move')
        $('#openIcon_' + targetID).removeClass('art_publishIcon_hide')
        $('#art_edit_' + targetID).removeClass('art_delete_manage_show')
        $('#cancelDeleteIcon_' + targetID).removeClass('art_publishIcon_show')
      }

    },
    deleteArtwork(artworkUUID, boxFlag) {
      if (this.deleteStep === 1) {
        this.deleteStep = 2
        $('#deleteIcon_' + boxFlag).addClass('art_delete_move').removeClass('art_delete_moveDown')
        $('#openIcon_' + boxFlag).addClass('art_publishIcon_hide').removeClass('art_publishIcon_show')
        $('#cancelDeleteIcon_' + boxFlag).addClass('art_publishIcon_show').removeClass('art_publishIcon_hide')
      } else if (this.deleteStep === 2) {
        request.post('artwork/manage/deleteArtwork',{'uuid':artworkUUID})
        window.location.reload()
      }
    },
    cancelDeleteArtwork(artworkUUID, boxFlag){
      this.deleteStep = 1
      $('#deleteIcon_' + boxFlag).removeClass('art_delete_move').addClass('art_delete_moveDown')
      $('#openIcon_' + boxFlag).removeClass('art_publishIcon_hide').addClass('art_publishIcon_show')
      $('#cancelDeleteIcon_' + boxFlag).removeClass('art_publishIcon_show').addClass('art_publishIcon_hide')

    },



    completeUpdater(){
      if (!this.btnSecAlert) {
        // console.log('执行了动画')
        this.buttonSecondAlert('checkEmail_submit_button')
      }
      if (this.input0 === '' || this.input1 === '' || this.input2 === '' || this.input3 === '') {
        this.btnSecAlert = false
        this.submitButtonAlert(false, '请输入完整验证码')
        this.vCodeBorderAlert(true)
      }else {
        request.get("user/updateToArtist", {
          params: {
            'uuid': this.userPersonalMsg.uuid,
            'email': this.userPersonalMsg.email,
            'inputCode':this.input0 + this.input1 + this.input2 + this.input3,
          }
        }).then(res => {
          if (res) {
            //注册成功
            this.btnSecAlert = true
            this.$refs.successRedirect.successRedirectTable('升级成功')
          } else {
            this.btnSecAlert = false
            this.submitButtonAlert(false, '验证码错误')
            this.vCodeBorderAlert(true)
          }
        })
      }
    },

    //验证码
    deleteValue(e, inputValue, previousItem) { // 键盘按下时$event，当前input，上一个input
      // console.log(inputValue, previousItem)
      if (e.keyCode === 8) {
        if (this[inputValue].length > 0) { // 当前有值，清空
          this[inputValue] = ''
        } else { // 当前没有值，光标跳转到上一个input，并清空该input值
          this.$nextTick(() => {
            this.$refs[previousItem].focus()
            this[previousItem] = ''
            this[inputValue] = ''
          })
        }
      }
    },
    changeValue(e, inputValue, nextItem) { // 键盘抬起时$event，当前input，下一个input
      // console.log(e.keyCode, this[inputValue], this[nextItem])
      if (e.keyCode !== 8) {
        this.$nextTick(() => {
          this.$refs[nextItem].focus() // 截取当前值最后一位，跳到下一个input
          // this[inputValue] = (this[inputValue]).toString().slice(-1)
        })
      }
    },
    submitButtonAlert(Flag, tips) {
      if (Flag) {
        $('#checkEmail_submit_button').removeClass('public_submit_Alert').addClass('public_submit_color')
        $('#checkEmail_submit_button').val('升级账户')
      } else {
        $('#checkEmail_submit_button').removeClass('public_submit_color').addClass('public_submit_Alert')
        $('#checkEmail_submit_button').val(tips)
      }
    },
    vCodeBorderAlert(FLag) {
      if (FLag) {
        $('.ccvi_each').removeClass('public_gray_border')
        $('.ccvi_each').addClass('border_Warning')
      } else {
        $('.ccvi_each').addClass('public_gray_border')
        $('.ccvi_each').removeClass('border_Warning')
        this.submitButtonAlert(true)
      }
    },
    sendEmailAgain() {
      this.sendValidateCode()
      this.sendEmailAgainTimes = 30;
      this.sendTimer();
    },
    sendValidateCode() {
      this.vCodeWindowShow = true
      request.post("user/sendValidateCodeEmail", {'email': this.userPersonalMsg.email})
    },
    closeVCodeClearStorage() {
      this.vCodeWindowShow = false
      request.get("user/clearDisabledEmail", {
        params: {
          'email': this.userPersonalMsg.email,
        }
      })
    },
    buttonSecondAlert(BtnID) {
      $('#' + BtnID).addClass('public_submit_secAlert')
      setTimeout(() => {
        $("#" + BtnID).removeClass("public_submit_secAlert")
      }, 10);
    },
    sendTimer() {
      if (this.sendEmailAgainTimes > 0) {
        $('#sendEmailAgain').addClass('doNotPointer')
        this.sendEmailDisabled = true;
        this.sendEmailAgainTimes--;
        this.sendEmailAgainTips = this.sendEmailAgainTimes + "秒后再次获取";
        setTimeout(this.sendTimer, 1000);
      } else {
        $('#sendEmailAgain').removeClass('doNotPointer')
        this.sendEmailAgainTimes = 0;
        this.sendEmailAgainTips = "点击此处再次发送";
        this.sendEmailDisabled = false;
      }
    },





    //blur事件验证
    checkUserInput(idName, inputValue) {
      //输入值为空时
      if (inputValue === "") {

        $('#' + idName).stop(true, false).animate({
          left: '20px',
          top: '20px',
          fontSize: '15px',
        }, 100)
      } else {


      }
    },

    //点击初始化样式
    selectBorder(idName) {
      $('#' + idName + '_alert').text('')
      $('#' + idName + "_border").removeClass("border_Warning")
      $('#' + idName).stop(true, false).animate({
        left: '15px',
        top: '5px',
        fontSize: '10px',
      }, 100)
    },
    //下方展示的验证警告
    showAlertMsg(idName, Msg) {
      $('#' + idName + "_border").addClass("border_Warning")
      $('#' + idName + '_alert').text(Msg)
    },


  }
}
</script>
<style src="@/style/FlameTheme.css" scoped/>
<style src="@/style/FlameDarkTheme.css" scoped/>
<style src="@/style/PublicStyle.css" scoped/>
<style scoped>

@media screen and (orientation: landscape) {
  .personl_body {
    position: absolute;
    width: 100%;
    height: 100%;
}

  .pb_main {
    overflow-x: hidden;
    perspective: 50px;
    margin: 52px auto;
    width: 100%;
    height: 90%;
  }

  .personl_bg {
    transform: translateY(-30vh) translateZ(-50px) scale(2.2);
    position: absolute;
    width: 100%;
    height: 200px;
  }

  .personl_bg_darkfilter {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(90deg, rgba(16, 15, 15, 0.9) 20%, rgba(255, 255, 255, 0) 99%);

  }

  .personl_bg_img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .pbm_detail {
    transform: translateZ(0px);
    width: 1170px;
    height: auto;
    margin: 220px auto;
  }

  .pbmd_avatar {
    overflow: hidden;
    position: absolute;
    margin-top: -175px;
    margin-left: 100px;
    width: 125px;
    height: 125px;
    border-radius: 100%;
    border: 2px solid #FF8000;
  }

  .pbmd_avatar_img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .pbmd_avatar_el {
    position: absolute;
  }

  .pbmd_avatar_editIcon {
    position: absolute;
    margin-top: -185px;
    margin-left: 420px;
    border-radius: 100%;
    width: 35px;
    height: 35px;
    background-color: #F0F0F0;
    box-shadow: 0 0 10px 5px rgba(28, 28, 28, 0.5);
  }

  .pbmd_avatar_editIcon svg {
    position: absolute;
    background-color: #ffffff00;
    margin-top: 8px;
    margin-left: 8px;
  }

  .pbmd_umsg {
    position: absolute;
    margin-top: -200px;
    margin-left: 250px;
    width: 80%;
    height: 170px;
    color: #F0F0F0;
  }

  .pbmd_btnZu {
    position: absolute;
    margin-top: -150px;
    margin-left: 950px;
    width: 200px;
    height: 100px;
    /*background-color: red;*/
  }

  .pbmd_btnZu input {
    width: 100%;
    height: 40px;
    margin-top: 10px;
    border: none;
    border-radius: 5px;
  }

  .pbmd_umsg_name {
    float: left;
    font-size: 35px;
    margin-top: 50px;
  }

  .pbmd_umsg_flag_N {
    display: flex;
    align-items: center;
    float: left;
    font-size: 10px;
    border: 1px solid #F0F0F0;
    border-radius: 10px;
    width: 53px;
    height: 20px;
    color: #F0F0F0;
    margin-top: 60px;
    margin-left: 20px;
    padding-left: 5px;
  }

  .pbmd_umsg_flag_A {
    display: flex;
    align-items: center;
    float: left;
    font-size: 10px;
    border: 1px solid #f380ff;
    border-radius: 10px;
    width: 42px;
    height: 20px;
    background-image: linear-gradient(to right, #efbeff, #99c8ff);
    -webkit-background-clip: text;
    color: transparent;
    margin-top: 60px;
    margin-left: 20px;
    padding-left: 5px;
  }

  .pbmd_umsg_email {
    float: left;
    width: 100%;
    font-size: 15px;
    margin-top: 10px;
  }

  .pbmd_umsg_cbgi {
    float: left;
    width: 60px;
    height: 25px;
    font-size: 15px;
    margin-top: 10px;
    font-size: 15px;
    color: #F0F0F0;
  }

  .pbmd_f_card {
    display: flex;
    position: absolute;
    border-radius: 10px;
    width: 100%;
    height: 110px;
    margin-top: 20px;
  }

  .pbmd_folFans {
    align-self: center;
    float: left;
    margin-left: 0px;
  }

  .pbmdf_each {
    float: left;
    width: 100px;
    height: 60px;
    margin-left: 20px;
    padding-left: 10px;
    padding-top: 10px;
    border-radius: 10px;
    /*background-color: red;*/
  }

  .pbmdf_each_Intro {
    width: 860px;
  }

  .pbmdfe_title {
    margin-top: 10px;
  }

  .pbmd_main {
    position: absolute;
    border-radius: 10px;
    width: 100%;
    height: auto;
    margin-top: 150px;
  }

  .pbmdm_head {
    position: relative;
    border-radius: 10px;
    width: 100%;
    height: 50px;
  }

  .pbmdm_head div {
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }

  .pbmdm_changeBtn {
    border: none;
    color: #1e1e1e;
    background-color: #d2d2d2;
    width: 50%;
    height: 100%;
  }

  .pbmdm_changeBtn_like {
    border-top-left-radius: 10px;
  }

  .pbmdm_changeBtn_publish {
    border-top-right-radius: 10px;
  }

  .dark .pbmdm_changeBtn {
    color: #F0F0F0;
    background-color: #3f3f3f;
  }

  .ps_filter_btn {
    color: #e0e0e0;
    font-weight: 600;
    background-color: #252525;
  }

  .dark .ps_filter_btn {
    color: #1c1c1c;
    font-weight: 600;
    background-color: #FF8000;
  }


  .become_artist_body {

    width: 100%;
    height: 250px;
  }

  .becomeab_title {
    font-size: 30px;
    padding-top: 50px;
  }

  .becomeab_btn {
    display: flex;
    justify-content: center;
    margin: 50px auto;
    width: 300px;
    height: 50px;
    border-radius: 10px;
    border: none;
  }


  .edit_intro_modal {
    position: relative;
    width: 600px;
    height: 250px;
    margin: 15% auto;
  }

  .editim_body {
    position: absolute;
    width: 100%;
    top: 0;
  }

  .editim_body_title {
    margin-top: 20px;
    margin-left: 10%;
    font-size: 20px;
  }

  .modal_submitOrCancel {
    position: absolute;
    width: 100%;
    height: 50px;
    bottom: 20px;
  }

  .modal_submitOrCancel input {
    width: 40%;
    height: 40px;
    margin-left: 6%;
    border: none;
    border-radius: 5px;
  }

  .modal_submit_cancel {
    background-color: #F0F0F0;
  }

  .dark .modal_submit_cancel {
    background-color: #2a2a2a;
  }

  .change_homepage_bgimg {
    /*background-color: red;*/
    width: 100px;
    height: 30px;

  }


  /*INput样式*/
  .input_border {
    position: relative;
    border-radius: 5px;
    width: 480px;
    height: 60px;
    margin: 35px auto;
  }

  .input_border_title {
    position: absolute;
    left: 20px;
    top: 20px;
    font-size: 15px;
  }

  .input_border_main {
    position: absolute;
    border: none;
    background-color: #FFFFFF00;
    outline: none;
    padding-left: 10px;
    font-size: 15px;
    width: 80%;
    height: 50%;
    bottom: 5px;
    left: 5px;
  }

  .input_alert_msg {
    position: relative;
    height: 20px;
  }

  /*验证码*/
  .checkEm_body {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(30px);
    z-index: 2;
  }

  .checkEm_card {
    position: relative;
    margin: 0 auto;
  }

  .cc_title {
    position: absolute;
    text-align: center;
  }

  .cc_validateCode {
    position: relative;
  }

  .ccv_input_border {
    position: relative;
    margin: 0 auto;
  }

  .ccvi_each {
    position: relative;
    float: left;
  }

  .ccvi_each input {
    border: none;
    outline: none;
    background-color: rgba(255, 255, 255, 0);
  }

  .checkEmail_btn {
    position: relative;
    float: left;
  }
  /*艺术品卡片*/
  .art_each-card {
    position: relative;
    display: flex;
    border-radius: 10px;
    overflow: hidden;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 42px;
    float: left;
    width: 240px;
    height: 300px;
  }

  .art_each-card_a {
    display: block;
    width: 100%;
    height: 100%;
  }

  .art_img_border {
    position: relative;
    width: 100%;
    height: 180px;
  }

  .art_img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .art_title {
    position: relative;
    top: 10px;
    left: 10px;
  }

  .art_nickname {
    position: relative;
    top: 15px;
    left: 10px;
  }

  .art_price {
    position: relative;
    top: 40px;
    left: 10px;
  }


  .art_delete_manage {
    position: absolute;
    padding-top: 50px;
    width: 100%;
    height: 100%;
    background-color: rgba(20, 20, 20, 0.8);
    backdrop-filter: blur(20px);
    opacity: 0;
  }

  .art_delete_manage_show {
    animation: artdms_anim 0.2s;
    opacity: 1;
  }

  @keyframes artdms_anim {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .art_delete_icon_border {
    position: relative;
    width: 60px;
    display: flex;
    height: 60px;
    margin: 25px auto;
    border-radius: 100%;
    background-color: rgba(196, 196, 196, 0.3);
    backdrop-filter: blur(10px);
    z-index: 10;
  }
  .art_cancelDelete_icon_border{
    margin: -85px auto;
    opacity: 0;
    z-index: 1;
  }
  .art_publishIcon_show{
    animation: art_publishIcon_showAnim 0.5s;
    opacity: 1;
  }
  @keyframes art_publishIcon_showAnim {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .art_publishIcon_hide{
    animation: art_publishIcon_hideAnim 0.5s;
    opacity: 0;
  }
  @keyframes art_publishIcon_hideAnim {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  .art_delete_move {
    animation: art_delete_moveAnim 0.5s;
    background-color: #850000;
    transform: translateY(-85px);
  }

  @keyframes art_delete_moveAnim {
    from {
      transform: translateY(0%);
      background-color: rgba(196, 196, 196, 0.3);
    }
    to {
      transform: translateY(-85px);
      background-color: #850000;
    }
  }
  .art_delete_moveDown {
    animation: art_delete_moveDownAnim 0.5s;
    background-color: rgba(196, 196, 196, 0.3);
    transform: translateY(0);
  }

  @keyframes art_delete_moveDownAnim {
    from {
      transform: translateY(-85px);
      background-color: #850000;
    }
    to {
      transform: translateY(0);
      background-color: rgba(196, 196, 196, 0.3);
    }
  }
  .art_delete_icon {
    position: relative;
    align-self: center;
    margin: 0 auto;
  }

  /*结束*/







  /*  验证码*/
  .checkEm_card {
    width: 600px;
    height: 600px;
    top: 80px;
  }

  .cc_title {
    width: 100%;
    height: 120px;
    top: 20px;
  }

  .cct_tips, .cct_title {
    margin-top: 20px;
  }

  .cc_validateCode {
    width: 100%;
    height: 200px;
    top: 150px;
  }

  .ccv_input_border {
    width: 405px;
    height: 150px;
    top: 90px;
  }

  .ccvi_each {
    width: 60px;
    border-radius: 5px;
    letter-spacing: 5px;
    margin-left: 30px;
    margin-top: 20px;
  }

  .ccvi_each input {
    width: 50px;
    height: 100px;
    padding-left: 10px;
    margin-left: 0px;
    margin-top: -2px;
    letter-spacing: 0px;
    font-size: 70px;
  }

  .checkEmail_btn {
    left: 100px;
    top: 220px;
  }

  .checkEmail_btn input {
    width: 400px;
    height: 50px;
    border: none;
  }

  .cb_closeIcon {
    position: absolute;
    width: 26px;
    height: 26px;
    right: 20px;
    top: 20px;
    z-index: 3;
  }

  .cb_closeIcon .icon {
    position: absolute;
    left: 3px;
    top: 3px;
  }

  .cc_checkHelp {
    position: absolute;
    bottom: 0;
    height: 120px;
    width: inherit;
  }

  .cch_line {
    text-align: center;
  }

}

/*竖屏 css*/
@media screen and (orientation: portrait) {
  .personl_body {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .pb_main {
    overflow-x: hidden;
    perspective: 50px;
    margin: 52px auto;
    width: 100%;
    height: 90%;
  }

  .personl_bg {
    position: absolute;
    width: 100%;
    height: 300px;
  }

  .personl_bg_darkfilter {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(90deg, rgba(16, 15, 15, 0.9) 20%, rgba(255, 255, 255, 0) 99%);
  }

  .personl_bg_img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .pbm_detail {
    position: relative;
    /*transform: translateZ(0px);*/
    width: 90%;
    height: auto;
    margin: 50px auto;
  }
  .pbmd_avatar_area{
    position: relative;
    margin: 10px auto;
    width: 125px;
    height: 125px;
  }
  .pbmd_avatar {
    overflow: hidden;
    position: relative;
    margin: 10px auto;
    width: 125px;
    height: 125px;
    border-radius: 100%;
    border: 2px solid #FF8000;
  }

  .pbmd_avatar_img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .pbmd_avatar_el {
    position: absolute;
  }

  .pbmd_avatar_editIcon {
    position: absolute;
    margin-top: -115px;
    margin-left: 220px;
    border-radius: 100%;
    width: 35px;
    height: 35px;
    background-color: #F0F0F0;
    box-shadow: 0 0 10px 5px rgba(28, 28, 28, 0.5);
  }

  .pbmd_avatar_editIcon svg {
    position: absolute;
    background-color: #ffffff00;
    margin-top: 8px;
    margin-left: 8px;
  }

  .pbmd_umsg {
    position: relative;
    float: left;
    margin: 0 ;
    width: 60%;
    height: 110px;
    color: #F0F0F0;
  }

  .pbmd_btnZu {
    position: relative;
    z-index: 3;
    float: right;
    width: 100px;
    height: 100px;
  }

  .pbmd_btnZu input {
    width: 100%;
    height: 40px;
    margin-top: 10px;
    border: none;
    border-radius: 5px;
  }

  .pbmd_umsg_name {
    float: left;
    font-size: 25px;
  }

  .pbmd_umsg_flag_N {
    transform: scale(0.8);
    display: flex;
    align-items: center;
    float: left;
    font-size: 10px;
    border: 1px solid #F0F0F0;
    border-radius: 10px;
    width: 53px;
    height: 20px;
    color: #F0F0F0;
    margin-top: 2px;
    margin-left: 0;
    padding-left: 5px;
  }

  .pbmd_umsg_flag_A {
    transform: scale(0.8);
    display: flex;
    align-items: center;
    float: left;
    font-size: 10px;
    border: 1px solid #f380ff;
    border-radius: 10px;
    width: 42px;
    height: 20px;
    background-image: linear-gradient(to right, #efbeff, #99c8ff);
    -webkit-background-clip: text;
    color: transparent;
    margin-top: 2px;
    margin-left: 0;
    padding-left: 5px;
  }

  .pbmd_umsg_email {
    float: left;
    width: 100%;
    font-size: 10px;
    margin-top: 10px;
  }

  .pbmd_umsg_cbgi {
    float: left;
    width: 60px;
    height: 25px;
    font-size: 10px;
    margin-top: 10px;
    color: #F0F0F0;
  }

  .pbmd_f_card {
    position: relative;
    border-radius: 10px;
    width: 100%;
    height: 220px;
    margin-top: 150px;
  }

  .pbmd_folFans {
    position: relative;
    width: 100%;
    float: left;
    margin-top:20px;
  }

  .pbmdf_each {
    position: relative;
    float: left;
    width: 35%;
    height: 60px;
    margin-left: 8%;
    padding-left: 10px;
    padding-top: 10px;
    border-radius: 10px;
    /*background-color: red;*/
  }

  .pbmdf_each_Intro {
    position: relative;
    margin-top: 20px;
    width: 81%;
  }

  .pbmdfe_title {
    margin-top: 10px;
  }

  .pbmd_main {
    position: relative;
    border-radius: 10px;
    width: 100%;
    height: auto;
    margin-top: 20px;
  }

  .pbmdm_head {
    position: relative;
    border-radius: 10px;
    width: 100%;
    height: 50px;
  }

  .pbmdm_head div {
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }

  .pbmdm_changeBtn {
    border: none;
    color: #1e1e1e;
    background-color: #d2d2d2;
    width: 50%;
    height: 100%;
  }

  .pbmdm_changeBtn_like {
    border-top-left-radius: 10px;
  }

  .pbmdm_changeBtn_publish {
    border-top-right-radius: 10px;
  }

  .dark .pbmdm_changeBtn {
    color: #F0F0F0;
    background-color: #3f3f3f;
  }

  .ps_filter_btn {
    color: #e0e0e0;
    font-weight: 600;
    background-color: #252525;
  }

  .dark .ps_filter_btn {
    color: #1c1c1c;
    font-weight: 600;
    background-color: #FF8000;
  }


  .become_artist_body {
    width: 100%;
    height: 250px;
  }

  .becomeab_title {
    font-size: 15px;
    padding-top: 50px;
  }

  .becomeab_btn {
    display: flex;
    justify-content: center;
    margin: 50px auto;
    width: 300px;
    height: 50px;
    border-radius: 10px;
    border: none;
  }


  .edit_intro_modal {
    position: relative;
    width: 90%;
    height: 250px;
    margin: 50% auto;
  }

  .editim_body {
    position: absolute;
    width: 100%;
    top: 0;
  }

  .editim_body_title {
    margin-top: 20px;
    margin-left: 10%;
    font-size: 20px;
  }

  .modal_submitOrCancel {
    position: absolute;
    width: 100%;
    height: 50px;
    bottom: 20px;
  }

  .modal_submitOrCancel input {
    width: 40%;
    height: 40px;
    margin-left: 6%;
    border: none;
    border-radius: 5px;
  }

  .modal_submit_cancel {
    background-color: #F0F0F0;
  }

  .dark .modal_submit_cancel {
    background-color: #2a2a2a;
  }

  .change_homepage_bgimg {
    /*background-color: red;*/
    width: 100px;
    height: 30px;

  }


  /*INput样式*/
  .input_border {
    position: relative;
    border-radius: 5px;
    width: 90%;
    height: 60px;
    margin: 35px auto;
  }

  .input_border_title {
    position: absolute;
    left: 20px;
    top: 20px;
    font-size: 15px;
  }

  .input_border_main {
    position: absolute;
    border: none;
    background-color: #FFFFFF00;
    outline: none;
    padding-left: 10px;
    font-size: 15px;
    width: 70%;
    height: 50%;
    bottom: 5px;
    left: 5px;
  }

  .input_alert_msg {
    position: relative;
    height: 20px;
  }

  /*验证码*/
  .checkEm_body {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(30px);
    z-index: 2;
  }

  .checkEm_card {
    position: relative;
    margin: 0 auto;
  }

  .cc_title {
    position: absolute;
    text-align: center;
  }

  .cc_validateCode {
    position: relative;
  }

  .ccv_input_border {
    position: relative;
    margin: 0 auto;
  }

  .ccvi_each {
    position: relative;
    float: left;
  }

  .ccvi_each input {
    border: none;
    outline: none;
    background-color: rgba(255, 255, 255, 0);
  }

  .checkEmail_btn {
    position: relative;
    float: left;
  }


  /*艺术品卡片*/
  .art_each-card {
    position: relative;
    display: flex;
    border-radius: 10px;
    overflow: hidden;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 3%;
    float: left;
    width: 45%;
    height: 200px;
  }

  .art_each-card_a {
    display: block;
    width: 100%;
    height: 100%;
  }

  .art_img_border {
    position: relative;
    width: 100%;
    height: 100px;
  }

  .art_img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .art_title {
    position: relative;
    top: 10px;
    left: 10px;
    font-size: 15px;
  }

  .art_nickname {
    position: relative;
    top: 15px;
    left: 10px;
    font-size: 10px;
  }

  .art_price {
    position: relative;
    top: 30px;
    left: 10px;
    font-size: 20px;
  }


  .art_delete_manage {
    position: absolute;
    padding-top: 10px;
    width: 100%;
    height: 100%;
    background-color: rgba(20, 20, 20, 0.8);
    backdrop-filter: blur(20px);
    opacity: 0;
  }

  .art_delete_manage_show {
    animation: artdms_anim 0.2s;
    opacity: 1;
  }

  @keyframes artdms_anim {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .art_delete_icon_border {
    position: relative;
    width: 60px;
    display: flex;
    height: 60px;
    margin: 25px auto;
    border-radius: 100%;
    background-color: rgba(196, 196, 196, 0.3);
    backdrop-filter: blur(10px);
    z-index: 10;
  }
  .art_cancelDelete_icon_border{
    margin: -85px auto;
    opacity: 0;
    z-index: 1;
  }
  .art_publishIcon_show{
    animation: art_publishIcon_showAnim 0.5s;
    opacity: 1;
  }
  @keyframes art_publishIcon_showAnim {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .art_publishIcon_hide{
    animation: art_publishIcon_hideAnim 0.5s;
    opacity: 0;
  }
  @keyframes art_publishIcon_hideAnim {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  .art_delete_move {
    animation: art_delete_moveAnim 0.5s;
    background-color: #850000;
    transform: translateY(-85px);
  }

  @keyframes art_delete_moveAnim {
    from {
      transform: translateY(0%);
      background-color: rgba(196, 196, 196, 0.3);
    }
    to {
      transform: translateY(-85px);
      background-color: #850000;
    }
  }
  .art_delete_moveDown {
    animation: art_delete_moveDownAnim 0.5s;
    background-color: rgba(196, 196, 196, 0.3);
    transform: translateY(0);
  }

  @keyframes art_delete_moveDownAnim {
    from {
      transform: translateY(-85px);
      background-color: #850000;
    }
    to {
      transform: translateY(0);
      background-color: rgba(196, 196, 196, 0.3);
    }
  }
  .art_delete_icon {
    position: relative;
    align-self: center;
    margin: 0 auto;
  }

  /*结束*/





  /*  验证码*/
  .checkEm_card {
    width: 80%;
    height: 70%;
    top: 10%;
  }

  .cc_title {
    width: 100%;
    height: 20%;
    top: 20px;
  }

  .cct_title_phone {
    font-size: 10px;
  }

  .cct_maintitle_phone {
    font-size: 25px;
  }

  .cct_tips, .cct_title {
    margin-top: 20px;
  }

  .cc_validateCode {
    width: 100%;
    height: 20%;
    top: 30%;
  }

  .ccv_input_border {
    width: 80%;
    height: 30%;
    top: 20%;
  }

  .ccvi_each {
    width: 20%;
    height: 80px;
    border-radius: 5px;
    letter-spacing: 5px;
    margin-left: 3%;
    margin-top: 20%;
  }

  .ccvi_each input {
    width: 70%;
    padding-left: 25%;
    font-size: 50px;
  }

  .checkEmail_btn {
    left: 10%;
    top: 40%;
    width: 100%;
  }

  .checkEmail_btn input {
    width: 80%;
    height: 40px;
    border: none;
  }

  .cb_closeIcon {
    position: absolute;
    width: 26px;
    height: 26px;
    right: 20px;
    top: 20px;
    z-index: 3;
  }

  .cb_closeIcon .icon {
    position: absolute;
    left: 3px;
    top: 3px;
  }

  .cc_checkHelp {
    position: absolute;
    bottom: 0;
    height: 20%;
    width: 100%;
  }

  .cch_line {
    text-align: center;
  }
}

</style>