<template>
  <div>
    <div class="input_border public_gray_border public_input_bgc public_black" :id="InputID+'_border'">
      <div class="input_border_title f-l" :id="InputID">{{this.title}}</div>
      <input class="input_border_main public_black"
             @blur="checkUserInput(InputID,userInput)"
             @click="selectBorder(InputID)"
             v-model="userInput"
             type="text">
    </div>
    <div class="input_alert_msg public_warning_color f-10" :id="InputID+'_alert'"></div>
  </div>
</template>

<script>
import * as $ from "jquery";

export default {
  name: "Flame-Input",
  props:['InputTitle','InputID','InputValue'],
  data() {
    return{
      userInput:'',
      title:this.InputTitle,

      checkFlag:false,
    }
  },
  mounted() {

  },
  methods: {
    //blur事件验证
    checkUserInput(idName, inputValue) {
      // console.log(idName)
      // console.log(inputValue)
      //输入值为空时
      if(inputValue === ""){
        this.showAlertMsg(idName,'必填')
        $('#' + idName).stop(true, false).animate({
          left: '20px',
          top: '20px',
          fontSize: '15px',
        }, 100)
      }else{
        // console.log('执行')
        this.$emit('inputResult', idName,this.checkFlag,this.userInput)

      }

    },

    //点击初始化样式
    selectBorder(idName) {
      $('#' + idName + '_alert').text('')
      $('#' + idName + "_border").removeClass("border_Warning")
      $('#' + idName).stop(true, false).animate({
        left: '15px',
        top: '5px',
        fontSize: '10px',
      }, 100)
    },
    //下方展示的验证警告
    showAlertMsg(idName, Msg) {
      $('#' + idName + "_border").addClass("border_Warning")
      $('#' + idName + '_alert').text(Msg)
    }

  }
}
</script>
<style src="@/style/FlameTheme.css" scoped/>
<style src="@/style/FlameDarkTheme.css" scoped/>
<style src="@/style/PublicStyle.css" scoped/>
<style scoped>
.input_border {
  position: relative;
  border-radius: 5px;
  width: 365px;
  height: 60px;
}
.input_border_title {
  position: absolute;
  left: 20px;
  top: 20px;
  font-size: 15px;
}
.input_border_main {
  position: absolute;
  border: none;
  background-color: #FFFFFF00;
  outline: none;
  padding-left: 10px;
  font-size: 15px;
  width: 70%;
  height: 50%;
  bottom: 5px;
  left: 5px;
}
.input_alert_msg{
  position: relative;
  height: 20px;
}
</style>