<template>
  <div class="a_searchArt_body">
    <div class="fl-l">
      <el-cascader
          ref="cascader"
          placeholder="类别"
          @change="changeFilter"
          :options="options"
          clearable
          filterable
          :show-all-levels="false"/>
    </div>
    <div class="fl-l ml-2">
      <el-input v-model="searchInput" placeholder="输入艺术品信息" @keydown.enter="searchArtwork">
        <template #prefix>
          <el-icon class="el-input__icon">
            <search/>
          </el-icon>
        </template>
      </el-input>
    </div>

    <el-table :data="this.artworkMessage" stripe height="550" style="width: 100%;">
      <el-table-column type="expand">
        <template #default="scope">
          <el-form label-position="left" inline class="demo-table-expand">
            <el-form-item label="">
              <div style="width: 100%">
                <div class="fl-l"  v-for="item in scope.row.imgurl.split('%2C')">
                  <img class="searchArt_img" :src="this.staticFileURL+ 'artworkImage/' + item">
                </div>
              </div>

              <div>
                <el-descriptions
                    class="margin-top ml-2"
                    title="艺术品信息"
                    :column="3"
                    :size="size"
                    border
                >
                  <el-descriptions-item>
                    <template #label>
                      <div class="cell-item">
                        UUID
                      </div>
                    </template>
                    {{scope.row.uuid}}
                  </el-descriptions-item>
                </el-descriptions>
              </div>

            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column prop="namecn" label="中文名" width="300"/>
      <el-table-column prop="nameen" label="英文名" width="280"/>
      <el-table-column prop="nickname" label="艺术家" width="200"/>
      <el-table-column prop="price" label="价格" width="150"/>
      <el-table-column prop="pricetrans" label="运费" width="90"/>
      <el-table-column label="状态" width="100">
        <template #default="scope">
          <span v-if="scope.row.state === 1" style="color: #67C23A;font-weight: 100">正在出售</span>
          <span v-else-if="scope.row.state === 2" style="color: #E6A23C">未付款</span>
          <span v-else-if="scope.row.state === 3" style="color: #67C23A;font-weight: 600">已付款</span>
          <span v-else-if="scope.row.state === 4" style="color: #E6A23C;font-weight: 600">进行中</span>
          <span v-else-if="scope.row.state === 5" style="color: #67C23A">已成交</span>
          <span v-else-if="scope.row.state === 6">已下架</span>
          <span v-else-if="scope.row.state === 9" style="color: #F56C6C;font-weight: 600">黑名单</span>
          <span v-else style="color: #F56C6C;font-weight: 100">异常</span>
        </template>
      </el-table-column>

      <el-table-column label="操作" width="120">
        <template #default="scope">
          <el-popconfirm
              v-if="scope.row.state === 9"
              @confirm="deleteArtwork(scope.row.uuid,false)"
              title="确定移出黑名单吗">
            <template slot-scope="scope" #reference>
              <el-button size="small"
                         type="success">移出黑名单
              </el-button>
            </template>
          </el-popconfirm>
          <el-popconfirm
              v-if="scope.row.state === 1"
              @confirm="deleteArtwork(scope.row.uuid,true)"
              title="确定加入黑名单吗">
            <template slot-scope="scope" #reference>
              <el-button size="small"
                         type="danger">加入黑名单
              </el-button>
            </template>
          </el-popconfirm>
        </template>


      </el-table-column>
    </el-table>
    <el-pagination
        v-model:current-page="this.pageNum"
        v-model:page-size="pageSize"
        :disabled="disabled"
        :background="background"
        layout="prev, pager, next"
        :total="this.pageLength"
        @current-change="changePage"
    />
  </div>
</template>

<script>
import request from "@/utils/request";
import {ElMessage} from "element-plus";
import {Search} from '@element-plus/icons-vue'

export default {
  name: "adminsearchart",
  components: {
    Search,
  },
  data() {
    return {
      staticFileURL: this.$globalVar + '/files/',

      artworkMessage: [{
        id: 1,
        imgurl: "",
        like: 0,
        namecn: "",
        nameen: "",
        nickname: "",
        price: 0,
        pricetrans: "",
        state: 1,
        uuid: "",
        virtualentity: 0
      }],


      options: [
        {
          value: 'arttype',
          label: '艺术品类型',
          children: [
            {
              value: '',
              label: '',
            }, {
              value: '',
              label: '',
            }, {
              value: '',
              label: '',
            }, {
              value: '',
              label: '',
            }, {
              value: '',
              label: '',
            }, {
              value: '',
              label: '',
            }, {
              value: '',
              label: '',
            },
          ],

        }, {
          value: 'state',
          label: '状态',
          children: [
            {
              value: '1',
              label: '正在出售',
            }, {
              value: '2',
              label: '未付款',
            }, {
              value: '3',
              label: '已付款',
            }, {
              value: '4',
              label: '进行中',
            }, {
              value: '5',
              label: '已成交',
            }, {
              value: '6',
              label: '已下架',
            }, {
              value: '9',
              label: '黑名单',
            },
          ],

        }],

      searchInput: '',

      pageFilter: 'all',
      pageFlag: 0,

      pageLength: 0,
      pageNum: 1,
    }
  },
  mounted() {
    this.getLength()
    this.getArtworkMessage(1, 'all', 0);
  },
  methods: {
    getLength() {
      request.get('/private/admin/getArtLength').then(res => {
        this.pageLength = res
      })
    },
    changePage(page) {
      this.pageNum = page
      this.getArtworkMessage(this.pageNum, this.pageFilter, this.pageFlag)
    },


    getArtworkMessage(page, filter, flag) {
      request.get('/private/admin/getArtwork', {params: {page: page, filter: filter, flag: flag}}).then(res => {
        this.artworkMessage = res

      })
      if (this.options[0].children[0].value === "") {
        this.getArtworkType()
      }
    },

    getArtworkType() {
      // console.log(this.options[0].children[0].value)
      request.get('/private/admin/getArtType').then(res => {
        // console.log(res)
        for (let i = 0; i < res.length; i++) {
          this.options[0].children[i].value = res[i].atypeid
          this.options[0].children[i].label = res[i].typename
        }

      })
    },
    deleteArtwork(id, flag) {
      request.get('/private/admin/addBlackList', {params: {id: id, operationflag: flag}}).then(() => {
        this.getArtworkMessage(this.pageNum, this.pageFilter, this.pageFlag)
        ElMessage({
          message: '操作成功',
          type: 'success',
        })
      })

    },

    changeFilter() {
      let res = this.$refs['cascader'].getCheckedNodes()
      // console.log(res.length)
      this.pageNum = 1
      if (res.length === 0) {
        this.pageFilter = 'all'
        this.getArtworkMessage(this.pageNum, this.pageFilter, 0)
      } else {
        this.pageFilter = res[0].pathValues[0]
        this.pageFlag = res[0].data.value
        this.getArtworkMessage(this.pageNum, this.pageFilter, this.pageFlag)
      }

    },
    searchArtwork() {
      this.pageNum = 1
      if (this.searchInput === "") {
        this.getArtworkMessage(this.pageNum, 'all', 0);
      } else {
        request.get('/private/admin/searchArtwork', {
          params: {
            page: this.pageNum,
            searchbody: this.searchInput
          }
        }).then(res => {
          this.artworkMessage = res
        })
      }

    },
  }
}
</script>
<style src="@/style/PublicStyle.css" scoped/>
<style scoped>
.a_searchArt_body {
  position: relative;
  height: 100%;
  width: 100%;
}

.searchArt_img {
  position: relative;
  margin-left: 20px;
  height: 150px;
}
</style>