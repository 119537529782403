<template>
  <div class="a_searchArt_body">
    <div class="fl-l">
      <el-cascader
          ref="cascader"
          placeholder="过滤器"
          @change="changeFilter"
          :options="options"
          clearable
          filterable
          :show-all-levels="false"/>
    </div>
    <div class="fl-l ml-2">
      <el-input v-model="searchInput" placeholder="输入订单信息" @keydown.enter="searchOrder">
        <template #prefix>
          <el-icon class="el-input__icon">
            <search/>
          </el-icon>
        </template>
      </el-input>
    </div>
    <el-table :data="this.orderMsg" stripe height="550" style="width: 100%;">
      <el-table-column prop="oid" label="订单ID" width="320"/>
      <el-table-column prop="aid" label="艺术品ID" width="320"/>
      <el-table-column prop="uid" label="用户ID" width="320"/>
      <el-table-column prop="orderdate" label="创建日期" width="150"/>
      <el-table-column label="状态" width="180">
        <template #default="scope">
          <span v-if="scope.row.orderstate === 0" style="color: #F56C6C;font-weight: 600">未付款</span>
          <span v-if="scope.row.orderstate === 1" style="color: #409EFF;font-weight: 100">已付款</span>
          <span v-if="scope.row.orderstate === 2" style="color: #409EFF;font-weight: 600">进行中</span>
          <span v-if="scope.row.orderstate === 3" style="color: #67C23A;font-weight: 600">已成交</span>
          <span v-if="scope.row.orderstate === 9" style="color: #F56C6C;font-weight: 100">已取消</span>
        </template>
      </el-table-column>

    </el-table>
    <el-pagination
        v-model:current-page="this.pageNum"
        v-model:page-size="pageSize"
        :disabled="disabled"
        :background="background"
        layout="prev, pager, next"
        :total="this.pageLength"
        @current-change="changePage"
    />
  </div>
</template>

<script>
import request from "@/utils/request";
import {ElMessage} from "element-plus";
import {
  Search,
  Iphone,
  Location,
  OfficeBuilding,
  Tickets,
  User,
} from '@element-plus/icons-vue'

export default {
  name: "adminborder",
  components: {
    Search,
    Iphone,
    Location,
    OfficeBuilding,
    Tickets,
    User,
  },
  data() {
    return {
      staticFileURL: this.$globalVar + '/files/',
      searchInput: '',
      orderMsg: [{
        oid:'',
        uid:'',
        aid:'',
        orderstate:'',
        orderdate:'',
      }],


      options: [
        {
          value: 'state',
          label: '订单状态',
          children: [
            {
              value: '0',
              label: '未付款',
            },{
              value: '1',
              label: '已付款',
            },{
              value: '2',
              label: '进行中',
            },{
              value: '3',
              label: '已成交',
            },{
              value: '9',
              label: '已取消',
            }
          ],
        }],

      pageFilter:'all',
      pageFlag:0,

      pageLength:0,
      pageNum:1,

    }
  },
  mounted() {
    this.getOrderMessage(1, 'all', 0);
    this.getLength();
  },
  methods: {
    getLength(){
      request.get('/private/admin/getOrderLength').then(res=>{
        this.pageLength = res
      })
    },
    getOrderMessage(page, filter, flag) {
      request.get('/private/admin/getOrder', {params: {page: page, filter: filter, flag: flag}}).then(res => {
        console.log(res)
        this.orderMsg = res
      })
    },


    changeFilter() {
      let res = this.$refs['cascader'].getCheckedNodes()
      this.pageNum = 1
      if (res.length===0){
        this.pageFilter = 'all'
        this.getOrderMessage(this.pageNum,this.pageFilter,0)
      }else{
        this.pageFilter = res[0].pathValues[0]
        this.pageFlag =  res[0].data.value

        this.getOrderMessage(this.pageNum,this.pageFilter,this.pageFlag)
      }

    },
    searchOrder() {
      this.pageFlag = 1
      if(this.searchInput === ""){
        this.pageFilter = 'all'
        this.getOrderMessage(1,this.pageFilter,0);
      }else{
        request.get('/private/admin/searchOrder', {params: {page:1,searchbody: this.searchInput}}).then(res => {
          this.orderMsg = res
        })
      }
    },

    changePageSize(size){

    },
    changePage(page){
      this.pageNum = page
      this.getOrderMessage(this.pageNum,this.pageFilter, this.pageFlag)
    },

  }
}
</script>
<style src="@/style/PublicStyle.css" scoped/>
<style scoped>

</style>