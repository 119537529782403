<template>
  <div class="doNotSelect">
    <el-row class="">
      <el-col>
        <el-menu
            default-active=""
            class="el-menu-vertical-demo"
            background-color="#3a3a3a"
            text-color="#F0F0F0"
            :router="true"
        >
          <el-sub-menu index="">
            <template #title>
              <el-icon><location /></el-icon>
              <span>信息管理</span>
            </template>
            <el-menu-item-group title="艺术品管理">
              <el-menu-item index="/private/adminsearchart">查询艺术品</el-menu-item>
              <el-menu-item index="/private/adminartclassify">艺术品类别</el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group title="用户管理">
              <el-menu-item index="/private/adminsearchusers">用户管理</el-menu-item>
              <el-menu-item index="/private/adminorder">订单管理</el-menu-item>
            </el-menu-item-group>
          </el-sub-menu>
          <el-sub-menu index="2">
            <template #title>
              <el-icon><icon-menu /></el-icon>
              <span>页面管理</span>
            </template>
            <el-menu-item-group title="网页内容">
              <el-menu-item index="/private/adminstoremaincard">商城推荐管理</el-menu-item>
              <el-menu-item index="/private/admincarousel">商城轮播图管理</el-menu-item>
            </el-menu-item-group>
          </el-sub-menu>
          <el-menu-item index="/private/admin-login">
            <el-icon><setting /></el-icon>
            <span>退出账户</span>
          </el-menu-item>
        </el-menu>
      </el-col>
    </el-row>
  </div>

</template>
<style src="@/style/PublicStyle.css" scoped/>
<script>
import {
  Document,
  Menu as IconMenu,
  Location,
  Setting,
} from '@element-plus/icons-vue'
import router from "@/router";
export default {
  name: "AdminTab",
  components:{
    Document,
    IconMenu,
    Location,
    Setting,
  },
  methods:{
  }
}
</script>

<style scoped>
.el-row{
  height: 3000px;
}
.el-menu{
  border: none;
}
.el-col{
  background-color: #3a3a3a;
  border-right: 2px solid #dcdfe6;
}
</style>