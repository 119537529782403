<template>
  <FlameNavBar/>
  <div class="public_body public_bgc">

    <div class="s_head">
      <div class="s_head_carousel">
        <el-carousel :interval="1000" height="400px" direction="vertical" :autoplay="false">
          <el-carousel-item v-for="item in this.storeCarousel" :key="item.id" class="shc_border">
            <img class="s_head_image" :src="this.$globalVar + '/files/carousel/'+ item.imgurl" alt="">
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="s_menu">
        <div class="sm_body store_head_menu_line">
          <div class="smb_each store_head_menu_firstBtn ta-c">
            <span class="p-r t-25 f-b doNotSelect">所有分类</span>
          </div>
          <div class="smb_each store_head_menu_othBtn" v-for="item in this.headerMenu" :key="item.atypeid">
            <a class="ta-c public_black" :href="'/#/store/Browse?TypeID='+item.atypeid + '&TypeName='+item.typename"
               target="_blank">
              <span class="p-r t-25 f-l">{{ item.typename }}</span>
            </a>
          </div>
        </div>
      </div>
    </div>


    <div v-for="item in this.artworkCard" :key="item.id" :id="'s_main_card'+item.id" class="s_main public_card_color">
      <div class="s_main_title f-b public_black">
        <span>{{ item.artworkType }}</span>
      </div>
      <div class="s_main_body section">
        <div class="s_mb_MAX_card public_card_color">
          <div class="s_mb_MAX_imgBorder">
            <img class="s_mb_img" :src="this.$globalVar + '/files/storeMainCard/' +item.artworkMaxUrl" alt="">
          </div>
        </div>

        <div v-for="item in item.ezArtwork" :key="item.uuid" class="s_mb_card store_art_card_color">
          <a class="s_mb_card_a" :href="this.artworkDetailUrl + item.uuid" target="_blank">
            <div class="s_mb_imgBorder">
              <img class="s_mb_img" :src="this.$globalVar + '/files/artworkImage/' + item.imgurl.split('%2C')[0]" alt="">
            </div>

            <div class="s_mb_textArea public_black">
              <div class="smbt_title f-b f-20">{{ item.namecn }}</div>
              <div class="smbt_nickname public_black f-l f-10">@{{ item.nickname }}</div>
              <div class="smbt_price f-20 f-b public_Flame_themeColor">{{ item.price }}&nbsp;<span
                  class="public_gray f-l f-15">CNY</span></div>
            </div>

          </a>
        </div>

      </div>
    </div>

  <FlameFooter/>
  </div>
</template>

<script>
import FlameNavBar from '@/components/Flame-NavBar'
import FlameFooter from '@/components/Flame-Footer'
import * as $ from "jquery";
import request from "@/utils/request";

export default {
  name: "store",
  components: {
    FlameNavBar,
    FlameFooter
  },
  data() {
    return {
      //ArtworkImgURL
      // staticFileURL: 'http://localhost:9090/files/',

      //卡片加载flag
      divLoaderFlag: 1,

      //跳转链接
      artworkDetailUrl: "/#/store/commodity?redirectUrl=" + encodeURIComponent(window.location.origin) + encodeURIComponent(window.location.pathname) + "&artworkID=",

      headerMenu: [
        {
          atypeid: '',
          typename: '',
        }
      ],

      artworkCard: [{
        id: '',
        artworkType: '',
        artworkMaxUrl: '',
        ezArtwork: []
      }
      ],
      storeCarousel: [{
        id: 1,
        imgurl: '',
      }],

    }
  },
  mounted() {
    this.getArtworkClassify()
    this.getArtworkCarousel()
    this.getMainArtworkData()
    this.listenCardDisTop()
  },
  methods: {
    listenCardDisTop() {

      window.ontouchstart = () => {
        return (() => {
          try {
            this.distanceFlag = document.querySelector('#s_main_card' + this.divLoaderFlag).getBoundingClientRect().top
            if (this.distanceFlag < 100) {
              request.get("artwork/store/getMainArtworkCardDetail", {params: {id: this.divLoaderFlag + 1}}).then((res) => {
                for (let i = 0; i < res.length; i++) {
                  this.artworkCard[this.divLoaderFlag].ezArtwork[i] = res[i].ezArtwork
                }
                this.divLoaderFlag++
                $('#s_main_card' + this.divLoaderFlag).addClass('store_art_card_show_anim')
                setTimeout(() => {
                  $('#s_main_card' + this.divLoaderFlag).removeClass("store_art_card_show_anim")
                  $('#s_main_card' + this.divLoaderFlag).addClass("store_art_card_show_trans")
                }, 1000);
                // console.log(this.artworkCard)
              })
            }

          } catch (e) {

          }
        })()
      }




      window.onmousewheel = () => {
        return (() => {
          try {
            this.distanceFlag = document.querySelector('#s_main_card' + this.divLoaderFlag).getBoundingClientRect().top
            if (this.distanceFlag < 100) {
              request.get("artwork/store/getMainArtworkCardDetail", {params: {id: this.divLoaderFlag + 1}}).then((res) => {
                for (let i = 0; i < res.length; i++) {
                  this.artworkCard[this.divLoaderFlag].ezArtwork[i] = res[i].ezArtwork
                }
                this.divLoaderFlag++
                $('#s_main_card' + this.divLoaderFlag).addClass('store_art_card_show_anim')
                setTimeout(() => {
                  $('#s_main_card' + this.divLoaderFlag).removeClass("store_art_card_show_anim")
                  $('#s_main_card' + this.divLoaderFlag).addClass("store_art_card_show_trans")
                }, 1000);
                // console.log(this.artworkCard)
              })
            }

          } catch (e) {

          }
        })()
      }
    },
    getArtworkCarousel() {
      request.get('artwork/store/getArtworkCarousel').then(res => {
        this.storeCarousel = res
      })
    },
    getMainArtworkData() {
      request.get("artwork/store/getMainArtworkCard").then((res) => {
        this.artworkCard = res
        for (let i = 0; i < res.length; i++) {
          this.artworkCard[i].ezArtwork = {}
        }
        request.get("artwork/store/getMainArtworkCardDetail", {params: {id: 1}}).then((res) => {
          for (let i = 0; i < res.length; i++) {
            this.artworkCard[0].ezArtwork[i] = res[i].ezArtwork
          }
          $('#s_main_card1').addClass('store_art_card_show_anim')
          setTimeout(() => {
            $("#s_main_card1").removeClass("store_art_card_show_anim")
            $("#s_main_card1").addClass("store_art_card_show_trans")
          }, 1000);
        })
      })
    },

    getArtworkClassify() {
      request.get("artwork/store/getAllArtworkType").then((res) => {
        this.headerMenu = res
      })
    }


  }
}
</script>

<style src="@/style/FlameTheme.css" scoped/>
<style src="@/style/FlameDarkTheme.css" scoped/>
<style src="@/style/PublicStyle.css" scoped/>
<style scoped>

.s_head {
  position: relative;
  top: 20px;
  width: inherit;
  height: 450px;
}

.s_menu {
  position: absolute;
  top: 0;
  height: 400px;
}

.sm_body {
  position: relative;
}

.smb_each {
  width: 100%;
  height: 50px;
  /*background-color: red;*/
}

.smb_each a {
  position: absolute;
  display: block;
  width: inherit;
  height: inherit;
}

.s_head_carousel {
  position: relative;
  margin: 0 auto;
}

.shc_border {
  overflow: hidden;
}

.s_head_image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}


/*横屏 css*/
@media screen and (orientation: landscape) {
  .s_head {
    /*background-color: red;*/
  }

  .s_menu {

  }

  .s_head_carousel {
    width: 100%;
  }

  .shc_border {

  }

  .s_head_image {

  }

  .s_menu {
    width: 40%;
  }

  .sm_body {
    left: 30%;
    width: 30%;
    height: 400px;
    backdrop-filter: blur(20px);
  }

  .s_main {
    position: relative;
    border-radius: 10px;
    margin: 30px auto;
    width: 80%;
    height: 670px;
    transform: translateX(-150%);
  }

  .s_main_title {
    position: relative;
    width: 100%;
    height: 15%;
    /*background-color: red;*/
  }

  .s_main_title span {
    position: relative;
    font-size: 35px;
    top: 30%;
    left: 8%;
  }

  .s_main_body {
    position: relative;
    /*left: 5%;*/
    overflow-x: hidden;
    overflow-y: scroll;
    margin: 0 auto;
    width: 88%;
    height: 550px;
    /*background-color: #2c2c00;*/
  }

  .store_art_card_show_trans {
    transform: translateX(0%);
  }

  .store_art_card_show_anim {
    animation: store_art_card_show_anim 1s;
    transform: translateX(0%);
  }

  @keyframes store_art_card_show_anim {
    0% {
      transform: translateX(-150%);
      opacity: 0;
    }
    5% {
      transform: translateX(-20%);
      opacity: 0;
    }
    100% {
      transform: translateX(0%);
      opacity: 1;
    }
  }

  .dark .s_main_body {
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .s_mb_card {
    float: left;
    width: 230px;
    height: 250px;
    border-radius: 5px;
    margin-left: 28px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .s_mb_card_a {
    display: block;
    width: 100%;
    height: 250px;
  }

  .s_mb_MAX_card {
    float: left;
    width: 488px;
    height: 250px;
    border-radius: 5px;
    margin-left: 28px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .s_mb_MAX_imgBorder {
    overflow: hidden;
    border-radius: 5px;
    width: 100%;
    height: 100%;
  }

  .s_mb_imgBorder {
    overflow: hidden;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    width: 100%;
    height: 150px;
  }

  .s_mb_img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .s_mb_textArea {
    width: 100%;
    height: 95px;
  }

  .smbt_title {
    position: relative;
    top: 10px;
    left: 10px;
  }

  .smbt_nickname {
    position: relative;
    top: 15px;
    left: 10px;
  }

  .smbt_price {
    position: relative;
    top: 30px;
    left: 10px;
  }

}

/*竖屏 css*/
@media screen and (orientation: portrait) {
  .s_head {
    /*background-color: red;*/
  }

  .s_menu {

  }

  .s_head_carousel {
    width: 100%;
  }

  .shc_border {

  }

  .s_head_image {

  }

  .s_menu {
    width: 60%;
  }

  .sm_body {
    left: 0;
    width: 50%;
    height: 400px;
    backdrop-filter: blur(20px);
  }

  .s_main {
    position: relative;
    border-radius: 10px;
    margin: 30px auto;
    width: 90%;
    height: 670px;
    transform: translateX(-150%);
  }

  .s_main_title {
    position: relative;
    width: 100%;
    height: 15%;
    /*background-color: red;*/
  }

  .s_main_title span {
    position: relative;
    font-size: 35px;
    top: 30%;
    left: 12%;
  }

  .s_main_body {
    position: relative;
    /*left: 5%;*/
    overflow-x: hidden;
    overflow-y: scroll;
    margin: 0 auto;
    width: 90%;
    height: 540px;
    /*background-color: #2c2c00;*/
  }

  .store_art_card_show_trans {
    transform: translateX(0%);
  }

  .store_art_card_show_anim {
    animation-timing-function: ease-out;
    animation: store_art_card_show_anim 1s;
    transform: translateX(0%);
  }

  @keyframes store_art_card_show_anim {
    from {
      transform: translateX(-150%);
      opacity: 0;
    }
    to {
      transform: translateX(0%);
      opacity: 1;
    }
  }

  .dark .s_main_body {
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .s_mb_card {
    float: left;
    width: 90%;
    height: 250px;
    border-radius: 5px;
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .s_mb_card_a {
    display: block;
    width: 100%;
    height: 250px;
  }

  .s_mb_MAX_card {
    overflow: hidden;
    float: left;
    width: 90%;
    height: 250px;
    border-radius: 10px;
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .s_mb_MAX_imgBorder {
    overflow: hidden;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    width: 100%;
    height: 100%;
  }

  .s_mb_imgBorder {
    overflow: hidden;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    width: 100%;
    height: 150px;
  }

  .s_mb_img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .s_mb_textArea {
    width: 100%;
    height: 95px;
  }

  .smbt_title {
    position: relative;
    top: 10px;
    left: 10px;
  }

  .smbt_nickname {
    position: relative;
    top: 15px;
    left: 10px;
  }

  .smbt_price {
    position: relative;
    top: 30px;
    left: 10px;
  }


}
</style>