import {createRouter,createWebHashHistory, createWebHistory} from 'vue-router'

import userlogin from '@/views/useraccount/userlogin'
import userregister from "@/views/useraccount/userregister";
import forgotpassword from "@/views/useraccount/forgotpassword";
import store from "@/views/store/store";
import commodity from "@/views/store/commodity";
import checkorder from "@/views/store/checkorder";
import classify from "@/views/store/classify";
import homepage from "@/views/useraccount/homepage";
import publishartwork from "@/views/store/publishartwork";
import manage from "@/views/useraccount/manage";
import userorder from "@/views/useraccount/userorder";
import orderdetail from "@/views/useraccount/orderdetail";
import artcanvas from "@/views/storeplugins/artcanvas";
import artisthomepage from "@/views/store/artisthomepage";
import balance from "@/views/useraccount/balance";
import followlist from "@/views/useraccount/followlist";
import adminlogin from "@/views/administrator/adminlogin";
import adminmain from "@/views/administrator/adminmain";
import adminsearchart from "@/views/administrator/adminsearchart";
import adminartclassify from "@/views/administrator/adminartclassify";
import adminhello from "@/views/administrator/adminhello";
import adminsearchusers from "@/views/administrator/adminsearchusers";
import adminstoremaincard from "@/views/administrator/adminstoremaincard";
import adminorder from "@/views/administrator/adminorder";
import admincarousel from "@/views/administrator/admincarousel";
import ToMain from "@/views/ToMain.vue";

const routes = [
    {
        path: '/user/login',
        name: 'userlogin',
        component: userlogin,
        meta: {
            title: '登录 Flame Art 账户 | Elysova Tech',
        }
    }, {
        path: '/user/register',
        name: 'userregister',
        component: userregister,
        meta: {
            title: '注册 Flame Art 账户 | Elysova Tech',
        }
    }, {
        path: '/user/forgot-password',
        name: 'forgotpassword',
        component: forgotpassword,
        meta: {
            title: '找回密码 | Elysova Tech',
        }
    }, {
        path: '/account/personal',
        name: 'homepage',
        component: homepage,
        meta: {
            title: '个人信息',
        }
    }, {
        path: '/account/manage',
        name: 'manage',
        component: manage,
        meta: {
            title: '管理账户',
        }
    }, {
        path: '/account/My-Order',
        name: 'userorder',
        component: userorder,
        meta: {
            title: '我的订单 | Flame Account',
        }
    }, {
        path: '/account/Order-Message',
        name: 'orderdetail',
        component: orderdetail,
        meta: {
            title: '订单详情 | Flame Account',
        }
    },{
        path: '/account/Follow',
        name: 'followlist',
        component: followlist,
        meta: {
            title: '我的关注 | Flame Account',
        }
    }, {
        path: '/account/Balance',
        name: 'balance',
        component: balance,
        meta: {
            title: '余额管理 | Flame Security',
        }
    }, {
        path: '/',
        name: 'ToMain',
        component: ToMain,
        meta: {
            title: 'Sentry',
        }
    },{
        path: '/store/Flame-store',
        name: 'store',
        component: store,
        meta: {
            title: 'Flame Art 商城 | Elysova Tech',
        }
    },{
        path: '/store/commodity',
        name: 'commodity',
        component: commodity,
        meta: {
            title: 'Flame Art 商城 | Elysova Tech',
        }
    },{
        path: '/store/Art-Scene',
        name: 'artcanvas',
        component: artcanvas,
        meta: {
            title: '预览 | Elysova Tech',
        }
    },{
        path: '/store/Artist',
        name: 'artisthomepage',
        component: artisthomepage,
        meta: {
            title: '艺术家 | Flame Artist',
        }
    },{
        path: '/store/check-order',
        name: 'checkorder',
        component: checkorder,
        meta: {
            title: '确认您的订单 | Elysova Tech',
        }
    },{
        path: '/store/Browse',
        name: 'classify',
        component: classify,
        meta: {
            title: 'Flame Store | Elysova Tech',
        }
    },{
        path: '/store/Publish',
        name: 'publishartwork',
        component: publishartwork,
        meta: {
            title: '发布艺术品 | Elysova Tech',
        }
    },{
        path: '/private/admin-login',
        name: 'adminlogin',
        component: adminlogin,
        meta: {
            title: '管理员登录 | Elysova Administrator'
        }
    },{
        path: '/private/admin-dashboard',
        name: 'adminmain',
        component: adminmain,
        redirect: '/private/adminhello',
        children: [
            {
                path: '/private/adminhello',
                name: 'adminhello',
                component: adminhello
            },
            {
                path: '/private/adminsearchart',
                name: 'adminsearchart',
                component: adminsearchart
            }, {
                path: '/private/adminartclassify',
                name: 'adminartclassify',
                component: adminartclassify
            },{
                path: '/private/adminsearchusers',
                name: 'adminsearchusers',
                component: adminsearchusers
            },{
                path: '/private/adminstoremaincard',
                name: 'adminstoremaincard',
                component: adminstoremaincard
            },{
                path: '/private/adminorder',
                name: 'adminorder',
                component: adminorder
            },{
                path: '/private/admincarousel',
                name: 'admincarousel',
                component: admincarousel
            },

        ],
        meta: {
            title: '控制面板 | Flame Administrator'
        },

    }

]

const router = createRouter({
    // base:'/flameartfront/',
    history: createWebHashHistory(process.env.BASE_URL),
    //history: createWebHistory(),
    routes
})
router.beforeEach(async (to) => {
    if (to.meta.title) { // 判断是否有标题
        document.title = to.meta.title;
    }
});
export default router
