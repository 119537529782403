<template>
  <div class="admin_smcBody section">
    <el-alert title="为保证网页浏览效果，不推荐添加过多的栏目。" type="info" show-icon/>
    <el-alert title="添加栏目后无法删除！请谨慎选择添加栏目，可以通过改变栏目标题和修改内部艺术品达到更换栏目的效果。" type="warning" show-icon/>
    <div class="admin_smc_body" v-for="item in StoreMainCardList">
      <div class="admin_smcb_title" v-if="item.id!==this.nowSelectTab">
        <div class="f-l ">栏目标题</div>
        <br>
        <div class="fl-l" style="width: 200px">
          <el-input v-model="item.artworkType" size="large" placeholder="栏目标题"/>
        </div>
        <div class="fl-l ml-5" style="width: 80px">
          <el-button @click="changeArtworkType(item.id,item.artworkType)" type="primary" size="large">更新标题</el-button>
        </div>
        <div class="fl-l ml-10" style="width: 80px">
          <el-button @click="changeArtworkCard(item.id,item.artworkType,item.artworkMaxUrl)" type="warning"
                     size="large">修改内部艺术品
          </el-button>
        </div>
      </div>

      <div v-if="item.id===this.nowSelectTab">
        <div class="admin_smcb_artcard" style="width: 200px">
          <div class="admin_smcba_imgborder">
            <img :src="this.staticFileURL+'storeMainCard/'+item.artworkMaxUrl" alt="">
          </div>
          <div class="ta-c f-b mt-3">分栏大图</div>
        </div>
        <el-upload
            class="upload-demo fl-l"
            :action="this.$globalVar + '/files/storeMainCard/upload'"
            multiple
            :limit="1"
            :show-file-list="false"
            :on-success="getStoreMainImgUrl"
        >
          <el-button size="large" type="primary" class="p-r" style="margin-top: 55px">修改
          </el-button>
        </el-upload>
        <div class="admin_smcLine"/>

        <div :id="'artcard_'+item.id" class="admin_smcb_artcard" v-for="item in mainCardArtwork">
          <div class="admin_smcbai_delete">
            <div @click="deleteItemArt(item.uuid)" class="admin_smcbaid_icon pointer" title="删除">
              <svg t="1673431400009" class="icon" viewBox="0 0 1024 1024" version="1.1"
                   xmlns="http://www.w3.org/2000/svg" p-id="2897" width="30" height="30">
                <path
                    d="M847.743 223.953H640.639c-3.132-68.921-60.177-124.029-129.858-124.029s-126.726 55.108-129.858 124.029H173.256c-17.673 0-32 14.327-32 32s14.327 32 32 32h674.487c17.673 0 32-14.327 32-32s-14.327-32-32-32z m-336.962-60.03c34.379 0 62.689 26.426 65.718 60.029H445.064c3.029-33.603 31.338-60.029 65.717-60.029zM767.743 351.79c-17.673 0-32 14.327-32 32v478.173H288.256V383.79c0-17.673-14.327-32-32-32s-32 14.327-32 32v510.173c0 17.673 14.327 32 32 32h511.487c17.673 0 32-14.327 32-32V383.79c0-17.673-14.327-32-32-32z"
                    fill="#F0F0F0" p-id="2898"></path>
                <path
                    d="M449.306 732.802V448.208c0-17.673-14.327-32-32-32s-32 14.327-32 32v284.593c0 17.673 14.327 32 32 32s32-14.326 32-31.999zM640.84 732.802V448.208c0-17.673-14.327-32-32-32s-32 14.327-32 32v284.593c0 17.673 14.327 32 32 32s32-14.326 32-31.999z"
                    fill="#F0F0F0" p-id="2899"></path>
              </svg>
            </div>
          </div>
          <div class="admin_smcba_imgborder">
            <img :src="this.staticFileURL+'artworkImage/'+item.imgurl.split('%2C')[0]" alt="">
          </div>
          <div class="ta-c f-b mt-3">{{ item.namecn }}</div>
        </div>
        <div class="admin_smcb_artcard" v-if="this.artnum < 6">
          <div @click="this.addArtworkModal = true" class="admin_smcbaid_icon pointer" title="添加">
            <svg t="1674012136075" class="icon" viewBox="0 0 1024 1024" version="1.1"
                 xmlns="http://www.w3.org/2000/svg" p-id="2696" width="30" height="30">
              <path
                  d="M469.333333 469.333333V170.666667h85.333334v298.666666h298.666666v85.333334h-298.666666v298.666666h-85.333334v-298.666666H170.666667v-85.333334h298.666666z"
                  fill="#F0F0F0" p-id="2697"></path>
            </svg>
          </div>
        </div>

        <el-button @click="this.nowSelectTab = 0;this.selectedTabType = '';this.selectedTabUrl = ''" size="large"
                   type="danger" class="mt-4">关闭
        </el-button>
      </div>


    </div>

    <div v-show="this.addSMCModal" class="addArtwork_modal_bg">
      <div class="addArtwork_modal" style="border-radius: 5px;">
        <el-alert
            title="再次警告！"
            type="warning"
            description="添加栏目后无法删除！确认添加吗"
            :closable="false"
            show-icon
            style="margin-top: 0"
        />
        <div class="ta-c mt-1">请输入推荐栏标题</div>

        <div style="width: 80%;margin: 20px auto">
          <el-input v-model="addSMCTitle" placeholder="推荐栏标题"/>
        </div>
        <div style="width: 30%;margin: 20px auto">
          <el-button @click="this.addSMCModal = false">关闭</el-button>
          <el-button @click="addSMC" type="danger">确认</el-button>
        </div>
      </div>
    </div>
    <div v-show="this.addArtworkModal" class="addArtwork_modal_bg">
      <div class="addArtwork_modal">
        <div class="ta-c mt-10">请输入作品UUID</div>
        <div style="width: 80%;margin: 20px auto">
          <el-input v-model="addArtworkUUID" placeholder="作品UUID"/>
        </div>
        <div style="width: 30%;margin: 20px auto">
          <el-button @click="this.addArtworkModal = false">关闭</el-button>
          <el-button @click="addArtworkInSMC" type="primary">确认</el-button>
        </div>
      </div>
    </div>

  </div>
  <div>
    <el-button @click="this.addSMCModal = true" type="danger" style="margin-top: 10px;">添加推荐栏</el-button>
  </div>

</template>

<script>
import request from "@/utils/request";
import * as $ from "jquery";
import {ElMessage} from "element-plus";

export default {
  name: "adminstoremaincard",
  components: {},
  data() {
    return {
      //ArtworkImgURL
      staticFileURL: this.$globalVar + '/files/',
      StoreMainCardList: [{
        id: '',
        artworkType: '',
        artworkMaxUrl: '',
      }],
      mainCardArtwork: [{
        namecn: '',
        imgurl: '',
      }],
      artnum:0,

      selectedTabUrl: '',
      selectedTabType: '',

      nowSelectTab: 0,
      addArtworkUUID: '',
      addSMCTitle: '',

      addSMCModal: false,
      addArtworkModal: false,
    }
  },
  mounted() {
    this.getStoreMainCardList()
  },
  methods: {
    getStoreMainCardList() {
      request.get('/private/admin/getStoreMainCard').then(res => {
        // console.log(res)
        this.StoreMainCardList = res

      })
    },
    getStoreMainCardArtwork(id) {
      request.get('/private/admin/getStoreMainCardArtwork', {params: {id: id}}).then(res => {
        // console.log(res)
        this.mainCardArtwork = res
        this.artnum = this.mainCardArtwork.length
      })
    },
    // changeArtworkType(item.id,item.artworkType)
    changeArtworkType(selectID, inputType) {
      request.get('/private/admin/updateSMCTitle', {params: {smcid: selectID, smctitle: inputType}}).then(() => {
        this.getStoreMainCardList()
        ElMessage({
          message: '操作成功',
          type: 'success',
        })
      })
    },
    changeArtworkCard(selectID, selectType, selectUrl) {
      this.nowSelectTab = selectID
      this.selectedTabType = selectType
      this.selectedTabUrl = selectUrl
      this.getStoreMainCardArtwork(selectID)
    },

    getStoreMainImgUrl(response) {
      request.post('files/storeMainCard/' + this.selectedTabUrl + '/delete').then(() => {
        request.get('/private/admin/uploadSMCMax', {
          params: {
            id: this.nowSelectTab,
            artworkMaxUrl: response.data,
          }
        }).then(() => {
          this.getStoreMainCardList()
          ElMessage({
            message: '操作成功',
            type: 'success',
          })


        })
      })

    },
    deleteItemArt(targetUUID) {
      request.get('/private/admin/deleteItemArtInSMC', {
        params: {
          artworkuuid: targetUUID,
          maincardid: this.nowSelectTab
        }
      }).then(() => {
        this.getStoreMainCardArtwork(this.nowSelectTab)
        ElMessage({
          message: '操作成功',
          type: 'success',
        })
      })
    },
    addArtworkInSMC() {
      request.get('/private/admin/addItemArtInSMC', {
        params: {
          id: this.nowSelectTab,
          artworkType: this.selectedTabType,
          artworkMaxUrl: this.selectedTabUrl,
          auuid: this.addArtworkUUID
        }
      }).then((res) => {
        console.log(res)
        if (res === 201){
          ElMessage.error('不存在该艺术品，请检查UUID')
        }else {
          if(res === 202){
            ElMessage.error('该艺术品已在该栏目中了')
          }else{
            this.addArtworkUUID = ''
            this.getStoreMainCardArtwork(this.nowSelectTab)
            this.addArtworkModal = false
            ElMessage({
              message: '操作成功',
              type: 'success',
            })
          }

        }


      })
    },
    addSMC() {
      request.get('/private/admin/addSMC', {
        params: {
          id: this.StoreMainCardList.length+1,
          artworkType: this.addSMCTitle,
        }
      }).then(() => {
        this.getStoreMainCardList()
        this.addSMCModal = false
        ElMessage({
          message: '操作成功',
          type: 'success',
        })
      })
    }
  }
}
</script>
<style src="@/style/PublicStyle.css" scoped/>
<style scoped>
.el-alert {
  margin: 5px 0 0;
}
.admin_smcBody{
  position: relative;
  width: 100%;
  height: 600px;
  overflow-y: scroll;
}
.admin_smc_body {
  position: relative;
  border-radius: 10px;
  background-color: #e3e3e3;
  float: left;
  margin-top: 10px;
  height: 150px;
  width: 100%;
}

.admin_smcb_title {
  position: relative;
  margin: 20px 20px;
  width: 500px;
  float: left;
  font-size: 15px;
}

.admin_smcLine {
  position: relative;
  width: 30px;
  margin-top: 18px;
  height: 120px;
  float: left;
  border-right: 1px solid #939393;
}

.admin_smcMAX {
  position: relative;
  width: 320px;
  float: left;
  /*background-color: red;*/
}

.admin_smcbimg {
  overflow: hidden;
  border-radius: 10px;
  margin-top: -20px;
  width: 200px;
  height: 100px;
  float: left;
}

.admin_smcbimg_img {
  object-fit: cover;

  width: 100%;
  height: 100%;
}

.admin_smcb_artcard {
  position: relative;
  overflow: hidden;
  float: left;
  margin: 10px 10px;
  width: 120px;
  height: 130px;
  border-radius: 5px;
  background-color: #eeeeee;
}

.admin_smcbai_delete {
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;
  background-color: rgba(24, 24, 24, 0.5);
  backdrop-filter: blur(20px);
  opacity: 0;
}

.admin_smcbai_delete:hover {
  animation: admin_smcbai_deleteAnim 0.3s;
  opacity: 1;
}

@keyframes admin_smcbai_deleteAnim {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.admin_smcbaid_icon {
  position: relative;
  width: 50px;
  height: 50px;
  background-color: rgba(31, 31, 31, 0.5);
  border-radius: 100%;
  margin: 40px auto;
}

.admin_smcbaid_icon:hover {
  background-color: rgba(52, 52, 52, 0.5);
}

.admin_smcbaid_icon .icon {
  position: relative;
  width: 100%;
  margin: 10px auto;
}

.admin_smcba_imgborder {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100px;
}

.admin_smcba_imgborder img {
  object-fit: cover;
  width: 100%;
}

.addArtwork_modal_bg {
  position: absolute;
  background-color: rgba(28, 28, 28, 0.2);
  backdrop-filter: blur(20px);
  top: 80px;
  margin-left: -20px;
  margin-top: -20px;
  width: 100%;
  height: 100%;
  z-index: 500;
}

.addArtwork_modal {
  position: absolute;
  width: 500px;
  height: 200px;
  background-color: #ffffff;
  border-radius: 10px;
  margin-top: 150px;
  margin-left: 400px;
}


</style>