<template>
  <div class="footer_body doNotSelect">
    <div>
      <div class="public_black footer_text f-l">Powered by Elysova Technology</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Flame-Footer"
}
</script>
<style src="@/style/FlameTheme.css" scoped/>
<style src="@/style/FlameDarkTheme.css" scoped/>
<style src="@/style/PublicStyle.css" scoped/>
<style scoped>
.footer_body{
  position: relative;
  overflow: hidden;
  height: 300px;
  width: 100%;
}
.footer_text{
  position: relative;
  top: 100px;
  text-align: center;
}
</style>